import { useContext, useEffect, useState } from 'react'
import ModalContext from '../../../../context/editor/ModalContext'
import { FormSounds } from './FormSounds'
import { API_MANAGE_SOUNDS } from '../../../../utils/constantsAdmin'
import { SearchCamp } from '../../searchAndPagination/SearchCamp'

import { Pagination } from '../../searchAndPagination/Pagination'
import { ItemSound } from './ItemSound'
import { LoadingIcon } from '../../LoadingIcon'
import { useAuthProvider } from '../../../../context/AuthProvider/AuthProvider'
import { useTranslation } from 'react-i18next'

export const ListSounds = () => {
	const { t } = useTranslation()
	// AUTH PERMISSION
	const { gameUser, axiosSupreme } = useAuthProvider()

	// REFRESH SOUNDS
	const [refreshSounds, setRefreshSounds] = useState(false)

	// DATA
	const [dataSounds, setDataSounds] = useState(undefined)

	// GET ALL SOUNDS
	useEffect(() => {
		getData()
	}, [refreshSounds])

	const getData = async () => {
		try {
			const result_data = await axiosSupreme(
				'get',
				API_MANAGE_SOUNDS
			)
			setDataSounds(result_data)
		} catch (e) {
			console.log('Error get Data: ', e)
		}
	}

	// VALUES SEARCH
	const [search, setSearch] = useState('')

	// MODAL
	const { openModal, setTitleModal, setContentModal } =
		useContext(ModalContext)
	const OpenAddSoundsModal = () => {
		setTitleModal(t("pages.editor.components.list_sound.add_sound"))
		setContentModal(<FormSounds setRefreshSounds={setRefreshSounds} />)
		openModal()
	}

	return (
		<div className='adminDesign__container__mainContainerBank'>
			<div className='adminDesign__container__mainContainerBank__Item'>
				<div className='adminDesign__container__mainContainerBank__Item__title'>
					<h1>{t("pages.editor.components.list_sound.bank_sounds")}</h1>
				</div>
				<div className='adminDesign__container__mainContainerBank__Item__content'>
					<hr />
					<div className='animations__container__btnAdd'>
						<div className='boxBtnAdd'>
							{gameUser.permissions?.hasOwnProperty(
								'add_star'
							) ? (
								<button
									className='editorBtnActionAdd'
									onClick={OpenAddSoundsModal}>
									{t("common.add")}
								</button>
							) : null}
						</div>
					</div>
					{dataSounds !== undefined ? (
						<>
							{dataSounds === 'search' ||
								dataSounds.results.length > 0 ? (
								<>
									<SearchCamp
										setData={setDataSounds}
										setRefreshDataList={setRefreshSounds}
										url={API_MANAGE_SOUNDS}
										search={search}
										setSearch={setSearch}
									/>
									{dataSounds === 'search' ? (
										<p className='noSelect'>
											{t("pages.editor.components.list_sound.none_sound_found")}
										</p>
									) : null}
								</>
							) : (
								<p className='noSelect'>
									{t("pages.editor.components.list_sound.none_sound_registered")}
								</p>
							)}
							{dataSounds !== 'search' ? (
								<div className='starsContent'>
									{dataSounds.results.map(
										(data_item, index) => (
											<ItemSound
												key={data_item.id_sound}
												objSound={data_item}
												setRefreshSounds={
													setRefreshSounds
												}
											/>
										)
									)}
									<Pagination
										data={dataSounds}
										setData={setDataSounds}></Pagination>
								</div>
							) : null}
						</>
					) : (
						<LoadingIcon />
					)}
				</div>
			</div>
		</div>
	)
}
