import { useContext, useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import ModalContext from '../../../../context/editor/ModalContext'
import { FormNpcAppearanceScene } from './FormNpcAppearanceScene'

import {
	API_PASS_MISSIONS_PROFILE,
	API_RESET_MISSIONS_PROFILE,
	GET_NPC_SUB_WORLD_SCENE_NPC_APPEARANCE_BY_SCENE,
} from '../../../../utils/constantsAdmin'
import { NpcCollapse } from './NpcCollapse'
import { FaBahai, FaGamepad, FaUserFriends } from 'react-icons/fa'
import { LoadingIcon } from '../../LoadingIcon'
import { useAuthProvider } from '../../../../context/AuthProvider/AuthProvider'
import { BsFillTrashFill } from 'react-icons/bs'
import DeleteContext from '../../../../context/editor/DeleteContext'
import AlertContext from '../../../../context/editor/AlertContext'
import { useTranslation } from 'react-i18next'

export const NpcAppearanceScene = ({ idScene }) => {
	const { t } = useTranslation()
	// AUTH PERMISSION
	const { gameUser, axiosSupreme } = useAuthProvider()

	// ALERT
	const {
		showAlert,
		setMessage: setMessageAlert,
		setTypeMessage,
	} = useContext(AlertContext)

	// DELETE
	const { openDelete, setMessage, setFunctionDelete } =
		useContext(DeleteContext)

	const navigate = useNavigate()
	// REFRESH NPC SCENE
	const [refreshNpcsScene, setRefreshNpcsScene] = useState(false)

	// GET ALL NPC SCENE
	const [data, setData] = useState(undefined)
	useEffect(() => {
		getData()
	}, [refreshNpcsScene])

	const getData = async () => {
		const result_data = await axiosSupreme(
			'get',
			GET_NPC_SUB_WORLD_SCENE_NPC_APPEARANCE_BY_SCENE + idScene + '/',
			undefined
		)
		let data_dict = {}
		result_data.map((data_item) => {
			if (
				!data_dict[
				data_item.sub_world_scene_npc_appearance
					.id_sub_world_scene_npc_appearance
				]
			) {
				data_dict[
					data_item.sub_world_scene_npc_appearance.id_sub_world_scene_npc_appearance
				] = {
					id_sub_world_scene_npc_appearance:
						data_item.sub_world_scene_npc_appearance
							.id_sub_world_scene_npc_appearance,
					id_npc: data_item.npc_compose.npc.id_npc,
					id_scene_sub_world: idScene,
					name: data_item.npc_compose.npc.name,
					description: data_item.npc_compose.npc.description,
					silence_file: data_item.npc_compose.npc.silence_file,
					coordinates:
						data_item.sub_world_scene_npc_appearance.coordinates,
					counter_composes: 0,
					composes: {},
				}
			}
			data_dict[
				data_item.sub_world_scene_npc_appearance.id_sub_world_scene_npc_appearance
			].composes[data_item.npc_compose.id_npc_compose] = {
				id_npc_compose_sub_world_scene_npc_appearance:
					data_item.id_npc_compose_sub_world_scene_npc_appearance,
				id_npc_compose: data_item.npc_compose.id_npc_compose,
				name: data_item.npc_compose.name,
				frame_size: data_item.npc_compose.frame_size,
				frames_amount: data_item.npc_compose.frames_amount,
				frames_duration: data_item.npc_compose.frames_duration,
				image_file: data_item.npc_compose.image_file,
				npc_state: data_item.npc_compose.npc_state.name,
			}
			data_dict[
				data_item.sub_world_scene_npc_appearance.id_sub_world_scene_npc_appearance
			].counter_composes += 1
		})
		setData(data_dict)
	}

	// ACTIVE COLLAPSE
	const [activeCollapseNpc, setActiveCollapseNpc] = useState(undefined)

	// VALUES SEARCH
	const [search, setSearch] = useState('')

	// MODAL
	const { openModal, setTitleModal, setContentModal } =
		useContext(ModalContext)
	const OpenAddNpcScene = () => {
		setTitleModal(t("pages.editor.components.npc_appearance_scene.add_npc_into_scene"))
		setContentModal(
			<FormNpcAppearanceScene
				idScene={idScene}
				setRefreshNpcsScene={setRefreshNpcsScene}
			/>
		)
		openModal()
	}

	const ReDirectManageNpc = (url) => {
		navigate(url)
	}

	// DELETE SOUND
	const DeleteMissionProfiles = async () => {
		const result_data = await axiosSupreme(
			'delete',
			API_RESET_MISSIONS_PROFILE + idScene + '/',
			undefined
		)
		if (result_data.hasOwnProperty('response')) {
			openDelete()
			setTypeMessage('error')
			setMessageAlert(
				t("common.cannot_delete")
			)
			showAlert()
		} else {
			openDelete()
			setTypeMessage('success')
			setMessageAlert(t("success.correct_deletion"))
			showAlert()
		}
	}

	const CompleteMissionsProfile = async () => {
		const result_data = await axiosSupreme(
			'post',
			API_PASS_MISSIONS_PROFILE + idScene + '/',
			undefined
		)
		if ('code' in result_data && result_data.code === 'success'){
			openDelete()
			setTypeMessage('success')
			setMessageAlert(t("Logs updated successfully"))
			showAlert()
		}
	}

	return (
		<div className='collapseNpcScene'>
			<div className='collapseNpcScene__btnNpcs'>
				{gameUser.permissions?.hasOwnProperty('view_npc_outfit') ||
					gameUser.permissions?.hasOwnProperty('view_npc_pose') ||
					gameUser.permissions?.hasOwnProperty('view_npc_state') ||
					gameUser.permissions?.hasOwnProperty('view_npc') ? (
					<button
						className='editorBtnActionBankActive'
						onClick={() => ReDirectManageNpc('/manage-npcs')}>
						{' '}
						<p className='pIcons'>
							<FaBahai />{' '}
						</p>{' '}
						{t("pages.editor.components.npc_appearance_scene.npcs")}{' '}
					</button>
				) : null}
				{gameUser.permissions?.hasOwnProperty('view_challenge') ||
					gameUser.permissions?.hasOwnProperty('view_game') ? (
					<button
						className='editorBtnActionBankActive'
						onClick={() =>
							ReDirectManageNpc('/manage-challenge-game')
						}>
						{' '}
						<p className='pIcons'>
							<FaBahai />{' '}
						</p>{' '}
						{t("pages.editor.components.npc_appearance_scene.challenges_and_games")}{' '}
					</button>
				) : null}
			</div>
			<div className='collapseNpcScene__btnAdd'>
				<h1>{t("pages.editor.components.npc_appearance_scene.npcs_from_scenes")}</h1>
				<div className='boxBtnAdd'>
					{gameUser.permissions?.hasOwnProperty(
						'add_sub_world_scene_npc_appearance'
					) ? (
						<button
							className='editorBtnActionAdd'
							onClick={OpenAddNpcScene}>
							{t("common.add")}
						</button>
					) : null}
				</div>
			</div>
			{data !== undefined ? (
				<div className='collapseNpcScene__Content'>
					{Object.keys(data).map((key) => (
						<NpcCollapse
							key={key}
							itemNpc={data[key]}
							setRefreshNpcsScene={setRefreshNpcsScene}
							idScene={idScene}
							activeCollapseNpc={activeCollapseNpc}
							setActiveCollapseNpc={setActiveCollapseNpc}
						/>
					))}
				</div>
			) : (
				<LoadingIcon />
			)}

			<div className='collapseNpcScene__DeleteButton'>
				{gameUser.permissions?.hasOwnProperty(
					'add_sub_world_scene_npc_appearance'
				) ? (
					<>
					<button
						className='editorBtnActionDeleteWithoutIcon'
						onClick={() => {
							setFunctionDelete(() => CompleteMissionsProfile)
							setMessage(
								t("pages.editor.components.npc_appearance_scene.confirm_deleting_2")
							)
							openDelete()
						}}>
						{t("pages.editor.components.npc_appearance_scene.pass_missions")}
					</button>
					&nbsp;
					<button
						className='editorBtnActionDeleteWithoutIcon'
						onClick={() => {
							setFunctionDelete(() => DeleteMissionProfiles)
							setMessage(
								t("pages.editor.components.npc_appearance_scene.confirm_deleting_1")
							)
							openDelete()
						}}>
						{t("pages.editor.components.npc_appearance_scene.reset_missions")}
					</button>

					</>
				) : null}
			</div>
		</div>
	)
}
