import { useContext, useEffect, useRef, useState } from 'react'
import AlertContext from '../../../../../context/editor/AlertContext'
import ModalContext from '../../../../../context/editor/ModalContext'

import {
	API_MANAGE_SOUNDS,
	API_MANAGE_STORE_CATEGORY,
	API_MANAGE_STORE_ITEMS,
} from '../../../../../utils/constantsAdmin'
import SaveButton from '../../../SaveButton'
import {
	onInputChangeDefault,
	onInputChangeImage,
	processErrors,
	returnProcessUrl,
} from '../../../../../utils/general_functions_forms'
import { LoadingIcon } from '../../../LoadingIcon'
import { handleKeyPressInteger } from '../../../../../utils/general_functions_forms'
import { useAuthProvider } from '../../../../../context/AuthProvider/AuthProvider'
import { useTranslation } from 'react-i18next'

export const FormItems = ({
	stateObjectItem,
	setStateObjectItem,
	setRefreshItems,
}) => {
	const { t } = useTranslation()
	const { gameUser, axiosSupreme } = useAuthProvider()
	// ALERT
	const { showAlert, setMessage, setTypeMessage } = useContext(AlertContext)

	// MODAL
	const { openModal } = useContext(ModalContext)

	// VALUES FORM
	const [name, setName] = useState('')
	const [price, setPrice] = useState('')
	const imageFile = useRef(null)
	const imagePreviewFile = useRef(null)
	const [itemCategory, setItemCategory] = useState('')
	const [sound, setSound] = useState('')

	// LIST VALUES
	const [listSounds, setListSounds] = useState({})
	useEffect(() => {
		fetchOptionsSounds(axiosSupreme, API_MANAGE_SOUNDS, setListSounds)
	}, [])

	// ERROR FOR FORM
	const [errorImage, setErrorImage] = useState('')
	const [errorImagePrev, setErrorImagePrev] = useState('')

	// GET ALL CATEGORY
	const [listItemsCategory, setListItemsCategory] = useState({})
	useEffect(() => {
		fetchOptionsItemCategory(
			axiosSupreme,
			API_MANAGE_STORE_CATEGORY,
			setListItemsCategory
		)
	}, [])

	// LOADING
	const [stateLoading, setStateLoading] = useState(false)

	// CLEAR VALUES FOR FIELDS
	const clearFields = () => {
		setName('')
		setPrice('')
		imageFile.current.value = null
		imagePreviewFile.current.value = null
	}

	// SET ITEMS WHEN UPDATE
	useEffect(() => {
		if (stateObjectItem !== undefined) {
			setName(stateObjectItem.name)
			setPrice(stateObjectItem.price)
			if (stateObjectItem.item_category) {
				setItemCategory(stateObjectItem.item_category.id_item_category)
			}
			if (stateObjectItem.sound) {
				setSound(stateObjectItem.sound.id_sound)
			}
		} else {
			clearFields()
		}
	}, [stateObjectItem])

	// SUBMIT FORM ITEMS
	const onSubmitItem = async (event) => {
		event.preventDefault()
		setStateLoading(true)
		// MAKE DICT
		let formData = new FormData()
		formData.append('name', event.target.elements.name.value)
		formData.append('price', event.target.elements.price.value)
		formData.append('item_category', itemCategory)
		formData.append('sound', sound ? sound : '')
		if (event.target.elements.image_file.value) {
			formData.append(
				'image_file',
				event.target.elements.image_file.files[0]
			)
		}
		if (event.target.elements.image_preview_file.value) {
			formData.append(
				'image_preview_file',
				event.target.elements.image_preview_file.files[0]
			)
		}
		if (stateObjectItem === undefined) {
			// SAVE
			manageItems(API_MANAGE_STORE_ITEMS, formData, 'add')
		} else {
			// UPDATE
			manageItems(
				API_MANAGE_STORE_ITEMS + stateObjectItem.id_item + '/',
				formData,
				'update'
			)
		}
	}

	// FUNCTION FOR SAVE OR UPDATE ITEMS
	const manageItems = async (url, obj, action) => {
		try {
			// PROCESS DATA
			let result_data =
				action === 'add'
					? await axiosSupreme('post', url, obj)
					: await axiosSupreme('patch', url, obj)
			clearFields()
			setTypeMessage('success')
			setMessage(
				action === 'add'
					? t("common.added")
					: t("common.modified")
			)
			showAlert()
			if (action === 'add') setRefreshItems((prev) => !prev)
			if (action === 'update') getNewObject()
			setStateLoading(false)
			openModal()
		} catch (errorPromise) {
			setTypeMessage('error')
			setMessage(t("errors.request_error"))
			if (errorPromise.response.status === 403) {
				setMessage(
					t("common.insufficient_permissions")
				)
			}
			if (errorPromise.response.status === 400) {
				let error = processErrors(errorPromise.response.data)
				setMessage(error)
			}
			showAlert()
		}
		setStateLoading(false)
	}

	const getNewObject = async () => {
		const result_data = await axiosSupreme(
			'get',
			API_MANAGE_STORE_ITEMS + stateObjectItem.id_item + '/',
			undefined
		)
		setStateObjectItem(result_data)
	}

	return (
		<>
			<form onSubmit={onSubmitItem}>
				<div
					className='admin__container__inputs'
					id='admin__container__inputs'>
					<label
						className='admin__container__inputs__title'
						forhtml='admin__container__inputs__title'>
						{t("pages.editor.components.form_items.name")}
					</label>
					<input
						maxLength='45'
						name='name'
						className='admin__container__inputs__in'
						id='name'
						type='text'
						placeholder={t("pages.editor.components.form_items.name_placeholder")}
						autoComplete='off'
						value={name}
						onChange={(event) =>
							onInputChangeDefault(event, setName)
						}
						required></input>
				</div>

				<div
					className='admin__container__inputs'
					id='admin__container__inputs'>
					<label
						className='admin__container__inputs__title'
						forhtml='admin__container__inputs__title'>
						{t("pages.editor.components.form_items.price")}
					</label>
					<input
						maxLength='45'
						name='price'
						className='admin__container__inputs__in'
						id='price'
						type='number'
						placeholder={t("pages.editor.components.form_items.price_placeholder")}
						autoComplete='off'
						value={price}
						onChange={(event) =>
							onInputChangeDefault(event, setPrice)
						}
						onKeyPress={(event) => handleKeyPressInteger(event)}
						required></input>
				</div>

				<div
					className='admin__container__inputs'
					id='admin__container__inputs'>
					<label
						className='admin__container__inputs__title'
						forhtml='admin__container__inputs__title'>
						{t("pages.editor.components.form_items.list_categories")}
					</label>
					<select
						name='item_category'
						className='admin__container__inputs__in'
						id='item_category'
						placeholder={t("pages.editor.components.form_items.list_categories_placeholder")}
						autoComplete='off'
						value={itemCategory}
						onChange={(event) =>
							onInputChangeDefault(event, setItemCategory)
						}
						required>
						<option value=''>{t("pages.editor.components.form_items.select_category")}</option>
						{Object.keys(listItemsCategory).map((key) => (
							<option key={key} value={key}>
								{listItemsCategory[key].name}
							</option>
						))}
					</select>
				</div>

				<div
					className='admin__container__inputs'
					id='admin__container__inputs'>
					<label
						className='admin__container__inputs__title'
						forhtml='admin__container__inputs__title'>
						{t("pages.editor.components.form_items.image")}
					</label>
					{stateObjectItem !== undefined ? (
						<>
							{stateObjectItem.image_file !== null ? (
								<label htmlFor=''>
									{t("common.current_file")}
									<a
										href={stateObjectItem.image_file}
										target='_blank'
										rel='noopener noreferrer'>
										{t("common.open")}
									</a>
								</label>
							) : null}
						</>
					) : null}
					<input
						name='image_file'
						className='admin__container__inputs__in'
						id='image_file'
						type='file'
						ref={imageFile}
						autoComplete='off'
						onChange={(event) =>
							onInputChangeImage(event, setErrorImage, imageFile)
						}
						accept='image/*'
						required={stateObjectItem === undefined}></input>
					{errorImage && <p>{errorImage}</p>}
				</div>

				<div
					className='admin__container__inputs'
					id='admin__container__inputs'>
					<label
						className='admin__container__inputs__title'
						forhtml='admin__container__inputs__title'>
						{t("pages.editor.components.form_items.preview_image")}
					</label>
					{stateObjectItem !== undefined ? (
						<>
							{stateObjectItem.image_preview_file !== null ? (
								<label htmlFor=''>
									{t("common.current_file")}
									<a
										href={
											stateObjectItem.image_preview_file
										}
										target='_blank'
										rel='noopener noreferrer'>
										{t("common.open")}
									</a>
								</label>
							) : null}
						</>
					) : null}
					<input
						name='image_preview_file'
						className='admin__container__inputs__in'
						id='image_preview_file'
						type='file'
						ref={imagePreviewFile}
						autoComplete='off'
						onChange={(event) =>
							onInputChangeImage(
								event,
								setErrorImagePrev,
								imagePreviewFile
							)
						}
						accept='image/*'
						required={stateObjectItem === undefined}></input>
					{errorImagePrev && <p>{errorImagePrev}</p>}
				</div>

				<div
					className='admin__container__inputs'
					id='admin__container__inputs'>
					<label
						className='admin__container__inputs__title'
						forhtml='admin__container__inputs__title'>
						{t("pages.editor.components.form_items.sound")}
					</label>
					<select
						name='sound'
						className='admin__container__inputs__in'
						id='sound'
						placeholder={t("pages.editor.components.form_items.sound_placeholder")}
						autoComplete='off'
						value={sound}
						onChange={(event) =>
							onInputChangeDefault(event, setSound)
						}>
						<option key='' value=''>
							{t("pages.editor.components.form_items.select_sound")}
						</option>
						{Object.keys(listSounds).map((key) => (
							<option key={key} value={key}>
								{listSounds[key].name}
							</option>
						))}
					</select>
				</div>

				{stateLoading === false ? (
					<div className='admin__container__boxBtn'>
						<SaveButton></SaveButton>
					</div>
				) : (
					<LoadingIcon />
				)}
			</form>
		</>
	)
}

const fetchOptionsItemCategory = async (
	axiosSupreme,
	url,
	setListItemsCategory
) => {
	url = returnProcessUrl(url)
	let result_data = await axiosSupreme('get', url, undefined)
	result_data.results.map((data_item) => {
		setListItemsCategory((prevData) => ({
			...prevData,
			[data_item.id_item_category]: {
				name: data_item.name,
			},
		}))
	})
	if (result_data.next) {
		fetchOptionsItemCategory(
			axiosSupreme,
			result_data.next,
			setListItemsCategory
		)
	}
}

const fetchOptionsSounds = async (axiosSupreme, url, setList) => {
	url = returnProcessUrl(url)
	let result_data = await axiosSupreme('get', url, undefined)
	result_data.results.map((data_item) => {
		setList((prevData) => ({
			...prevData,
			[data_item.id_sound]: {
				name: data_item.name,
			},
		}))
	})
	if (result_data.next) {
		fetchOptionsSounds(axiosSupreme, result_data.next, setList)
	}
}
