/**
 * @type {Array<String>} colors the colors the words are going to take when completed,
 * so the user can differenciate them.
 */
export const colors = [
	'#EF2158',
	'#53DAF9',
	'#D653F7',
	'#F77015',
	'#4EE542',
]

export const alphabet = ["a", "b", "c", "d", "e", "f", "g", "h", "i", "j", "k", "l", "m", "n", "o", "p", "q", "r", "s", "t", "u", "v", "w", "x", "y", "z", "å", "ä", "ö"]
