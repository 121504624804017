import { useRef } from "react"

import missionsIcon from '../assets/icons/missions.png'
import missionsIconAvif from '../assets/icons/missions.avif'
import storeIcon from '../assets/icons/store.png'
import storeIconAvif from '../assets/icons/store.avif'
import { useTranslation } from "react-i18next"

export const useLocalMenuBar = (
	setReloadScene,
	setWindowCategoryKey,
	setWindowIsActive,
	lostItemsThereIsAnUnseenInteraction,
	soundAction
) => {
	const { t } = useTranslation()
	const menuBarRef = useRef([
		{
			name: 'inventory',
			image: storeIcon,
			imageAvif: storeIconAvif,
			label: t("pages.game.components.menu_bar.title_store"),
			onClick: function () {
				localStorage.removeItem('inventoryNotification')
				setWindowCategoryKey('inventory')
				setWindowIsActive(true)
				setReloadScene((rs) => !rs)
				soundAction('effect', 'openWindow', 'play')
			},
			notification: false,
		},
		{
			name: 'lostItems',
			image: missionsIcon,
			imageAvif: missionsIconAvif,
			label: t("pages.game.components.menu_bar.title_missions"),
			onClick: function () {
				setWindowCategoryKey('lostItems')
				setWindowIsActive(true)
				setReloadScene((rs) => !rs)
				soundAction('effect', 'openWindow', 'play')
			},
			notification: false,
		},
	])
	menuBarRef.current[1].notification = lostItemsThereIsAnUnseenInteraction

	return [menuBarRef.current]
}