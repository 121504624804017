import { useEffect, useRef } from 'react'

import ballonYellow from '../../assets/images/balloonYellow.png'
import ballonRed from '../../assets/images/balloonRed.png'
import ballonPink from '../../assets/images/balloonPink.png'
import ballonPurple from '../../assets/images/balloonPurple.png'

import ballonYellowPopped from '../../assets/images/balloonYellowPopped.png'
import ballonRedPopped from '../../assets/images/balloonRedPopped.png'
import ballonPinkPopped from '../../assets/images/balloonPinkPopped.png'
import ballonPurplePopped from '../../assets/images/balloonPurplePopped.png'

import '../../styles/Lives.scss'
import { useSoundAction } from '../../hooks/useSoundAction'

const ballons = [
	[ballonYellow, ballonYellowPopped],
	[ballonRed, ballonRedPopped],
	[ballonPink, ballonPinkPopped],
	[ballonPurple, ballonPurplePopped],
]

const Lives = ({ amount, mistakesCounter, onDeath, columnsAmount }) => {
	const { soundAction } = useSoundAction()
	const amountRef = useRef(amount)
	const rowsAmountRef = useRef(Math.ceil(amountRef.current / columnsAmount))

	const once = useRef(false)
	useEffect(() => {
		if (mistakesCounter >= amountRef.current) onDeath()

		if (!once.current) {
			once.current = true
			return
		}
		soundAction('effect', 'TLIJ5', 'play')
	}, [mistakesCounter])

	const rowsAmountArray = [...Array(rowsAmountRef.current).keys()]

	return (
		<div className='lives'>
			{rowsAmountArray.map((row, rowIndex) => {
				let rowLivesAmount = amountRef.current / rowsAmountRef.current
				if (
					!Number.isInteger(rowLivesAmount) &&
					rowIndex !== rowsAmountArray.length - 1
				) {
					rowLivesAmount = Math.ceil(rowLivesAmount)
				} else if (rowIndex === rowsAmountArray.length - 1) {
					// the last one
					rowLivesAmount = Math.floor(rowLivesAmount)
				}

				let rowMistakesCounter =
					rowLivesAmount * (rowIndex + 1) -
					(amountRef.current - mistakesCounter)
				if (rowIndex === rowsAmountArray.length - 1) {
					// the last one
					rowMistakesCounter = mistakesCounter
				}

				return (
					<Container
						key={rowIndex}
						amount={rowLivesAmount}
						mistakesCounter={rowMistakesCounter}
						index={rowIndex}
						rowsAmountRef={rowsAmountRef}
					/>
				)
			})}
		</div>
	)
}

const Container = ({ amount, mistakesCounter, index, rowsAmountRef }) => {
	return (
		<div
			className='livesContainer'
			style={{
				flexDirection: rowsAmountRef.current === 1 ? 'row-reverse' : '',
			}}>
			{[...Array(amount).keys()].map((live, liveIndex) => {
				const imageIndex = numToNumInRange(
					index + liveIndex,
					ballons.length
				)
				const isPopped = liveIndex > amount - mistakesCounter - 1
				// basically assign it the animation class if it is popped
				return (
					<div key={liveIndex} className='livesContainerLive'>
						<img
							src={ballons[imageIndex][isPopped ? 1 : 0]}
							alt='ballon'
						/>
						{isPopped && (
							<img
								className='livesContainerLiveAnimatePop'
								src={ballons[imageIndex][0]}
								alt='ballon'
							/>
						)}
					</div>
				)
			})}
		</div>
	)
}

const numToNumInRange = (num, range) => {
	return num >= range ? num - Math.floor(num / range) * range : num
}

export default Lives
