import { useEffect, useState } from 'react'
import completedIcon from '../../assets/icons/coin.svg'
// import Icon from '../shared/Icon/Icon'
import IconButton from '../shared/IconButton/IconButton'
import closeIcon from '../../assets/icons/closePlayground.png'
import bubbleCorner from '../../assets/icons/bubbleCorner.svg'
import { useSoundAction } from '../../hooks/useSoundAction'
import { useTranslation, Trans } from 'react-i18next'
import "../../styles/components/missionBubble.scss"

export default function MissionBubble({ data, activityIsActive }) {
	// States: l (locked), u (unlocked), c (completed).
	/**
	 * (
			('l', 'Lesson'),
			('o', 'Object'),
			('g', 'Game'),
			('ee', 'Easter Egg'),
		)
	 */

	/**
	 * Qué es exactamente lo que quiero?
	 * cada vez que se cierre, comparar el ultimo unlocked con el actual.
	 */

	/**
	 * So, everithing is okay, what is the thing now?
	 *
	 * The transition, the transition is the most important thing now.
	 * The thing here is that:
	 *  #1 - The user can hide the letter if he touches it, the message will fade.
	 *  #2 - When a missión is completed, both the npc and the message, disapears.
	 * 		and a new npc and message appears from nothing.
	 *  #3 - Even if the user hides the message, this one will came out after x seconds.
	 *
	 * La idea es que apenas el usuario cierre la ventana se haga la transición de imagen, y luego
	 * de dialogo.
	 *
	 * What about, change a bool, when the activity provider is closed?
	 */

	/**
	 * What are the states of the mission bubble.
	 *
	 * opened, closed.
	 */
	const { soundAction } = useSoundAction()
	const { t } = useTranslation()
	let [profileStyle, setProfileStyle] = useState({ opacity: 1 })
	let [bubbleStyle, setBubbleStyle] = useState({ opacity: 1 })

	let actualUnlockedMission = data?.find((mission) => mission.state === 'u')
	let actualIndex = data?.findIndex((mission) => mission.state === 'u')
	actualIndex = actualIndex + 1

	const [lastUnlockedMission, setLastUnlockedMission] = useState(
		actualUnlockedMission
	)
	const [lastIndex, setLastIndex] = useState(actualIndex)

	useEffect(() => {
		// ----- ENTER ONLY IF IT WAS CLOSED
		if (activityIsActive) return

		// ----- NOW SEE IF THE UNLOCKED MISSION WAS CHANGED, WHICH MEANS PREVIOUS COMPLETITION.
		if (
			actualUnlockedMission?.missionID !== lastUnlockedMission?.missionID
		) {
			// WAIT X SECONDS, AND ANIMATE TO THE NEXT STAGE.

			const timeout = setTimeout(() => {
				clearTimeout(timeout)
				setLastUnlockedMission(actualUnlockedMission)
				setLastIndex(actualIndex)

				hideProfile()
				closeBubble()

				const timeout2 = setTimeout(() => {
					clearTimeout(timeout2)

					showProfile()
					openBubble()
				}, 1000)
			}, 3800)

			/**
			 * Here I just change the classname of both the left, and the right
			 * so they animate. from, invisible to visible.
			 */
		}
	}, [data, activityIsActive])

	const hideProfile = () => {
		setBubbleStyle({ ...bubbleStyle, opacity: 0 })
	}

	const showProfile = () => {
		setBubbleStyle({ ...bubbleStyle, opacity: 1 })
	}

	const closeBubble = () => {
		soundAction('effect', 'TLSG4', 'play')
		setBubbleStyle({ ...bubbleStyle, opacity: 0 })
	}

	const openBubble = () => {
		setBubbleStyle({ ...bubbleStyle, opacity: 1 })
	}

	const getMessageByType = (type, name) => {
		if (type === 'l') {
			return (
				<Trans i18nKey={"pages.game.components.progress_bar.text_bar_complement_1"} values={{ nameNpc: lastUnlockedMission.NPCName }} >
					<p>
						Me llamo {lastUnlockedMission.NPCName}
						<span className='red'> búscame</span> y te
						<span className='blue'> enseñaré</span> nuevas cosas.
					</p>
				</Trans>
			)
		} else if (type === 'g') {
			return (
				<Trans i18nKey={"pages.game.components.progress_bar.text_bar_complement_2"} values={{ nameNpc: lastUnlockedMission.NPCName }} >
					<p>
						Me llamo {lastUnlockedMission.NPCName}
						<span className='red'> búscame</span> nuevas cosas.
						<span className='blue'> practicar</span> lo aprendido.
					</p>
				</Trans>
			)
		} else if (type === 'ee') {
			return (
				<p>
					<span className='red'>Encuéntrame </span> y aprende acerca de la
					<span className='blue'>cultura finlandesa.</span>
				</p>
			)
		}
	}

	return (
		<div className='scene__missionbubble'>
			<div className='scene__missionbubble__left' style={profileStyle}>
				<IconButton
					src={
						lastUnlockedMission
							? lastUnlockedMission.image
							: completedIcon
					}
					parentStyle={{ width: '100%', height: '100%' }}
					size={'100%'}
					onClick={openBubble}
				/>
			</div>
			<div className='scene__missionbubble__right'>
				<div
					className={
						'scene__missionbubble__right__bubble' +
						(bubbleStyle.opacity === 1
							? ' scene__missionbubble__right__bubble__animate__open'
							: '')
					}
					style={bubbleStyle}>
					{lastUnlockedMission ? (
						<>
							<h3>{t("pages.game.components.progress_bar.goal")} {lastIndex} / {data.length}</h3>
							{getMessageByType(
								lastUnlockedMission.missionType,
								lastUnlockedMission.NPCName
							)}
						</>
					) : (
						<>
							<h3
								style={{
									color: 'green',
									fontSize: '1.5rem',
									textWrap: 'balance',
								}}>
								{t("pages.game.components.progress_bar.congratulations")}
							</h3>
							<p style={{ fontSize: '1rem' }}>
								{t("pages.game.components.progress_bar.all_missions_completed")}{' '}
								<span
									style={{
										color: 'blue',
										textWrap: 'balance',
									}}>
									{t("pages.game.components.progress_bar.next_map")}
								</span>
								.
							</p>
						</>
					)}
					<div className='scene__missionbubble__right__bubble__corner'>
						<img src={bubbleCorner} alt='burbúja de la misión de la escena' />
					</div>
					<IconButton
						className='scene__missionbubble__right__bubble__button'
						src={closeIcon}
						onClick={closeBubble}
						size={30}
					/>
				</div>
			</div>
		</div>
	)
}
