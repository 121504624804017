export function Square ({
	rowIndex,
	columnIndex,
	clickedSquare,
	onMouseUp,
	handlerOnMouseOver,
	textOfSquare
}) {
	return (
		<div
			className='wordSearchMatrixButton'
			data-position-x={`${rowIndex}`}
			data-position-y={`${columnIndex}`}
			data-positions={`${rowIndex}-${columnIndex}`}
			onMouseDown={clickedSquare}
			onMouseUp={onMouseUp}
			onMouseOver={handlerOnMouseOver}
		>{textOfSquare}</div>
	)
}
