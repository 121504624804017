import { useState } from "react"

export const useLocalActivity = () => {
	/**
	 * All of the info we need to render the Activity (Class|Game) provided by the current NPC
	 * the player is interacting with.
	 */
	// ----- $ACTIVITY
	// Visibility of the activity component.
	const [activityState, setActivityState] = useState('hide') // show
	/**
	 * All of this properties gets copied or cloned of the actual interacting NPC.
	 * @typedef {Object} activity
	 * @property {Boolean} isActive if the activity is opened or closed (rendering or not)
	 * @property {Boolean} isGame if the NPC type is game or class, then render (playground or class)
	 * @property {String} gameType if it's game, what game it is: 'anagram', 'crossword'...
	 * @property {Array<Object>} items if it is a game then each object represents a word to learn
	 * + its essencial data. If it is a class then a fragment of the class.
	 * [{
	 *      word: wordObj.finish_text.toUpperCase(),
	 *      translation: wordObj.spanish_text.toUpperCase(),
	 *      audio: BASE_API + wordObj.sound_file,
	 *      image: BASE_API + wordObj.support_file,
	 *      order: wordObj.order,
	 * }
	 * @property {Number} counter basically the index of the current class fragment when activity is class.
	 */
	const [activity, setActivity] = useState({
		isActive: false,
		isGame: false,
		gameType: null,
		items: null, // []
	})

	/**
	 * @typedef {Object} activityFunctions these functions call the exact functions on the actual
	 * player interacting NPC.
	 * @property {Function} randomiseContentItems Takes the activity secuence of the NPC when is type game
	 * and literally randomises it all.
	 * @property {Function} onNext called when the user clicks the next button on the
	 * class/culturalInfo, to go the next fragment, or to call the game was completed, now close
	 * the activity when game.
	 * @property {Function} onAnswer called when the user clicks an answer option in the
	 * activity class
	 * @property {Function} onClose called when the user closes the Activity.
	 */
	const [activityFunctions, setActivityFunctions] = useState({
		randomiseContentItems: () => {},
		onContinue: () => {},
		onComplete: () => {},
		onClose: () => {},
	})

	return [
		activityState,
		setActivityState,
		activity,
		setActivity,
		activityFunctions,
		setActivityFunctions,
	]
}
