import { useContext, useEffect, useState } from 'react'
import ModalContext from '../../../../../context/editor/ModalContext'
import { API_MANAGE_STORE_ITEMS } from '../../../../../utils/constantsAdmin'
import { FormItems } from './FormItems'
import { SearchCamp } from '../../../searchAndPagination/SearchCamp'
import { Pagination } from '../../../searchAndPagination/Pagination'
import { LoadingIcon } from '../../../LoadingIcon'
import { ItemCollapse } from './ItemCollapse'
import { useAuthProvider } from '../../../../../context/AuthProvider/AuthProvider'
import { useTranslation } from 'react-i18next'

export const ListItems = () => {
	const { t } = useTranslation()
	// AUTH PERMISSION
	const { gameUser, axiosSupreme } = useAuthProvider()

	// REFRESH ITEMS
	const [refreshItems, setRefreshItems] = useState(false)

	// DATA
	const [dataItems, setDataItems] = useState(undefined)

	// GET ALL ITEMS
	useEffect(() => {
		getData()
	}, [refreshItems])

	const getData = async () => {
		try {
			const result_data = await axiosSupreme(
				'get',
				API_MANAGE_STORE_ITEMS
			)
			setDataItems(result_data)
		} catch (e) {
			console.log('Error get Data: ', e)
		}
	}

	// VALUES SEARCH
	const [search, setSearch] = useState('')

	// MODAL
	const { openModal, setTitleModal, setContentModal } =
		useContext(ModalContext)
	const OpenAddItem = () => {
		setTitleModal(t("pages.editor.components.list_items.add_item"))
		setContentModal(<FormItems setRefreshItems={setRefreshItems} />)
		openModal()
	}

	// ACTIVECOLLAPSE
	const [activeCollapse, setActiveCollapse] = useState(undefined)

	return (
		<div className='storeAdmin__container'>
			<div className='storeAdmin__container__btnAdd'>
				<div className='boxBtnAdd'>
					{gameUser.permissions?.hasOwnProperty('add_item') ? (
						<button
							className='editorBtnActionAdd'
							onClick={OpenAddItem}>
							{t("common.add")}
						</button>
					) : null}
				</div>
			</div>
			{dataItems !== undefined ? (
				<>
					{dataItems === 'search' ||
						dataItems.results.length > 0 ? (
						<>
							<SearchCamp
								setData={setDataItems}
								setRefreshDataList={setRefreshItems}
								url={API_MANAGE_STORE_ITEMS}
								search={search}
								setSearch={setSearch}
							/>
							{dataItems === 'search' ? (
								<p className='noSelect'>
									{t("pages.editor.components.list_items.none_item_found")}
								</p>
							) : null}
						</>
					) : (
						<p className='noSelect'>{t("pages.editor.components.list_items.none_item_loaded")}</p>
					)}
					{dataItems !== 'search' ? (
						<>
							{dataItems.results.map((data_item) => (
								<ItemCollapse
									key={data_item.id_item}
									objectItem={data_item}
									setRefreshItems={setRefreshItems}
									activeCollapse={activeCollapse}
									setActiveCollapse={setActiveCollapse}
								/>
							))}
							<Pagination
								data={dataItems}
								setData={setDataItems}></Pagination>
						</>
					) : null}
				</>
			) : (
				<LoadingIcon />
			)}
		</div>
	)
}
