import { useEffect, useRef } from 'react'
import { useGameStatus } from '../../provider/ActivityProvider'
import '../../styles/PlaygroundResults.scss'

import starFullIcon from '../../assets/icons/starFull.png'
import starEmptyIcon from '../../assets/icons/starEmpty.png'

import gameOverIcon1 from '../../assets/images/GameResults/GameOver/GO1.png'
import gameOverIcon2 from '../../assets/images/GameResults/GameOver/GO2.png'
import gameOverIcon3 from '../../assets/images/GameResults/GameOver/GO3.png'
import gameOverIcon4 from '../../assets/images/GameResults/GameOver/GO4.png'
import gameOverIcon5 from '../../assets/images/GameResults/GameOver/GO5.png'
import gameOverIcon6 from '../../assets/images/GameResults/GameOver/GO6.png'
import gameOverIcon7 from '../../assets/images/GameResults/GameOver/GO7.png'

import regularIcon1 from '../../assets/images/GameResults/Regular/R1.png'
import regularIcon2 from '../../assets/images/GameResults/Regular/R2.png'
import regularIcon3 from '../../assets/images/GameResults/Regular/R3.png'
import regularIcon4 from '../../assets/images/GameResults/Regular/R4.png'
import regularIcon5 from '../../assets/images/GameResults/Regular/R5.png'
import regularIcon6 from '../../assets/images/GameResults/Regular/R6.png'
import regularIcon7 from '../../assets/images/GameResults/Regular/R7.png'

import perfectIcon1 from '../../assets/images/GameResults/Perfect/P1.png'
import perfectIcon2 from '../../assets/images/GameResults/Perfect/P2.png'
import perfectIcon3 from '../../assets/images/GameResults/Perfect/P3.png'
import perfectIcon4 from '../../assets/images/GameResults/Perfect/P4.png'
import perfectIcon5 from '../../assets/images/GameResults/Perfect/P5.png'
import perfectIcon6 from '../../assets/images/GameResults/Perfect/P6.png'
import perfectIcon7 from '../../assets/images/GameResults/Perfect/P7.png'
import ImageOnFlex1 from './ImageOnFlex1'
import { useSoundAction } from '../../hooks/useSoundAction'
import { useTranslation } from 'react-i18next'

const gameOverIcons = [
	gameOverIcon1,
	gameOverIcon2,
	gameOverIcon3,
	gameOverIcon4,
	gameOverIcon5,
	gameOverIcon6,
	gameOverIcon7,
]

const regularIcons = [
	regularIcon1,
	regularIcon2,
	regularIcon3,
	regularIcon4,
	regularIcon5,
	regularIcon6,
	regularIcon7,
]

const perfectIcons = [
	perfectIcon1,
	perfectIcon2,
	perfectIcon3,
	perfectIcon4,
	perfectIcon5,
	perfectIcon6,
	perfectIcon7,
]

const PlaygroundResults = ({ onTryAgain, onClose, onComplete }) => {
	const { t } = useTranslation()
	const [gameStatus, , gameMistakesCounter, starsEarned] = useGameStatus()
	const { soundAction } = useSoundAction()
	const isGameOver = gameStatus === 'gameOver'
	const mistakesCounterClone = useRef(gameMistakesCounter.current)

	let title = useRef(null)
	let message = useRef(null)
	let image = useRef(null)
	let starsAmount = useRef(null)

	const once = useRef(null)
	if (!once.current) {
		once.current = true
		if (mistakesCounterClone.current === 0 && gameStatus === 'completed') {
			starsAmount.current = 3
			title.current = t("pages.game.ratings.perfect.title")
			message.current = t("pages.game.ratings.perfect.message")
			image.current =
				perfectIcons[
				Math.round(Math.random() * (perfectIcons.length - 1))
				]
		} else if (
			mistakesCounterClone.current === 1 &&
			gameStatus === 'completed'
		) {
			starsAmount.current = 2
			title.current = t("pages.game.components.ratings.good.title")
			message.current = t("pages.game.components.ratings.good.message")
			image.current =
				regularIcons[
				Math.round(Math.random() * (regularIcons.length - 1))
				]
		} else if (
			mistakesCounterClone.current > 1 &&
			gameStatus === 'completed'
		) {
			starsAmount.current = 1
			title.current = t("pages.game.components.ratings.regular.title")
			message.current = t("pages.game.components.ratings.regular.message")
			image.current =
				regularIcons[
				Math.round(Math.random() * (regularIcons.length - 1))
				]
		} else if (gameStatus === 'gameOver') {
			starsAmount.current = 0
			title.current = t("pages.game.components.ratings.game_over.title")
			message.current = t("pages.game.components.ratings.game_over.message")
			image.current =
				gameOverIcons[
				Math.round(Math.random() * (gameOverIcons.length - 1))
				]
		}
	}

	starsEarned.current = starsAmount.current // HERE IS THE IMPORTANT ONE

	const starsForMap = [1, 2, 3]

	useEffect(() => {
		soundAction('game', null, 'pausePlayingAudio')
		if (gameStatus === 'gameOver') {
			soundAction('effect', 'TLME4', 'play')
		} else if (gameStatus === 'completed') {
			soundAction('effect', 'TLME3', 'play')
		}

		gameMistakesCounter.current = 0
		onComplete(starsEarned.current)
	}, [])

	return (
		<section className='playground__results'>
			<div className='playground__results__card'>
				<h1>{isGameOver ? 'GAME OVER' : title.current}</h1>
				<ImageOnFlex1
					src={image.current}
					className='playground__results__card__image'
				/>
				<div className='playground__results__card__stars'>
					{starsForMap.map((star, index) => {
						let isFull = index <= starsAmount.current - 1
						if (gameStatus === 'gameOver') isFull = false

						return (
							<div
								key={index + 'star'}
								className='playground__results__card__stars__star'>
								<img
									src={isFull ? starFullIcon : starEmptyIcon}
									alt='star'
									loading='eager'
									decoding='async'
								/>
							</div>
						)
					})}
				</div>
				<p>{message.current}</p>
				<div className='playground__results__card__buttons'>
					{gameStatus !== 'inProgress' && (
						<button
							className='playground__results__card__buttons__button'
							onClick={() => {
								soundAction('effect', 'TLIJ6', 'play')
								soundAction('effect', 'TLME1', 'pause')
								soundAction('effect', 'TLME2', 'pause')
								soundAction('effect', 'TLME3', 'pause')
								soundAction('effect', 'TLME4', 'pause')
								onTryAgain()
							}}>
							{t("common.btn_retry")}
						</button>
					)}
					{gameStatus === 'completed' && (
						<button
							className='playground__results__card__buttons__button'
							onClick={() => {
								soundAction('effect', 'TLSG8', 'play')
								soundAction('effect', 'TLME1', 'pause')
								soundAction('effect', 'TLME2', 'pause')
								soundAction('effect', 'TLME3', 'pause')
								soundAction('effect', 'TLME4', 'pause')
								onClose(gameStatus)
							}}>
							{t("common.btn_finish")}
						</button>
					)}
				</div>
			</div>
		</section>
	)
}

export default PlaygroundResults
