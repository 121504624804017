import { useRef } from 'react'
import { useSceneConfig } from '../../../context/SceneConfigProvider'
import { alphabet, colors } from '../constantsGames/constantWordSearch'
import {
	getDiagonal,
	getHorizontal,
	getVertical,
} from '../../../utils/functionsSearchWord'
import { Square } from './Square'

export const Soup = ({
	matrix,
	wordsFound,
	addedWords,
	audiosOfWords,
	calculateAnswerUser,
	handlerWordFounded,
}) => {
	const { soundPointers } = useSceneConfig()

	const isPressed = useRef(false)
	const positions = useRef([null, null])
	const correctLetters = useRef([])
	const color = useRef(colors[Math.floor(Math.random() * colors.length)])

	const clickedSquare = (e) => {
		const positionX = e.target.getAttribute('data-position-x')
		const positionY = e.target.getAttribute('data-position-y')

		isPressed.current = true
		if (!e.target.getAttribute('is-correct'))
			e.target.style.background = color.current
		positions.current = [positionX, positionY]
	}

	const resetColorsOfMatrix = (leaveOfMatrix) => {
		if (leaveOfMatrix) {
			isPressed.current = false
			positions.current = []
		}

		Array.from(
			document.querySelectorAll('.wordSearchMatrixButton')
		).forEach((square) => {
			if (!square.getAttribute('is-correct')) {
				square.style.background = ''
			}
		})
	}

	const onMouseUp = (e) => {
		isPressed.current = false
		positions.current = []

		resetColorsOfMatrix()

		const textOfAudio = correctLetters.current
			.map((dataSquare) => dataSquare.text)
			.join('')
		const word = textOfAudio.toLowerCase()
		const reverseWord = word.split('').reverse().join('')
		let foundWord = 0

		if (
			(addedWords.includes(word) || addedWords.includes(reverseWord)) &&
			!wordsFound.includes(word) &&
			!wordsFound.includes(reverseWord)
		) {
			handlerWordFounded(word)
			foundWord++

			let srcAudioOfCorrectWord = audiosOfWords.get(textOfAudio)

			if (!srcAudioOfCorrectWord) {
				const reverseWordOfAudio = textOfAudio
					.split('')
					.reverse()
					.join('')
				srcAudioOfCorrectWord = audiosOfWords.get(reverseWordOfAudio)
			}

			const { volume } = soundPointers.voices
			if (volume !== '0') {
				const audio = new Audio(srcAudioOfCorrectWord)
				audio.onloadeddata = () => {
					audio.volume = Number.isNaN(Number(volume))
						? 1
						: Number(volume)
					audio.play()
				}
			}

			correctLetters.current.forEach((dataSquare, dataSquareIndex) => {
				const el = document.querySelector(
					`.wordSearchMatrixButton[data-positions="${dataSquare.x}-${dataSquare.y}"]`
				)

				el.setAttribute('is-correct', 'true')
				el.style.background = color.current
				el.style.animation = `scaleSquare 200ms linear ${
					200 * dataSquareIndex
				}ms`
			})

			const lastColor = color.current
			color.current = colors[Math.floor(Math.random() * colors.length)]

			while (lastColor === color.current) {
				color.current =
					colors[Math.floor(Math.random() * colors.length)]
			}
		}

		if (addedWords.length === wordsFound.length + foundWord)
			calculateAnswerUser(foundWord)
	}

	const handlerOnMouseOver = (e) => {
		if (!isPressed.current) return

		const positionX = e.target.getAttribute('data-position-x')
		const positionY = e.target.getAttribute('data-position-y')

		const x1 = positions.current[0]
		const y1 = positions.current[1]
		correctLetters.current = []

		// Eje X
		if (x1 === positionX) {
			resetColorsOfMatrix()
			const arrayOfHorizontal = getHorizontal(+y1, +positionY)

			Array.from(
				document.querySelectorAll('.wordSearchMatrix')[positionX]
					.children
			).forEach((square) => {
				const squareY = square.getAttribute('data-position-y')
				const squareX = square.getAttribute('data-position-x')

				if (arrayOfHorizontal.includes(+squareY)) {
					if (!square.getAttribute('is-correct'))
						square.style.background = color.current
					correctLetters.current.push({
						text: square.textContent,
						x: squareX,
						y: squareY,
					})
				} else {
					if (!square.getAttribute('is-correct'))
						square.style.background = '#ffe0c8'
				}
			})
			// Eje Y
		} else if (y1 === positionY) {
			resetColorsOfMatrix()
			const arrayOfHorizontal = getVertical(+x1, +positionX)

			Array.from(
				document.querySelectorAll(
					`.wordSearchMatrixButton[data-position-y="${positionY}"]`
				)
			).forEach((square) => {
				const squareY = square.getAttribute('data-position-y')
				const squareX = square.getAttribute('data-position-x')

				if (arrayOfHorizontal.includes(+squareX)) {
					if (!square.getAttribute('is-correct'))
						square.style.background = color.current
					correctLetters.current.push({
						text: square.textContent,
						x: squareX,
						y: squareY,
					})
				} else {
					if (!square.getAttribute('is-correct'))
						square.style.background = '#ffe0c8'
				}
			})
			// Eje Diagonal
		} else if (
			Math.abs(positionX - Number(x1)) === Math.abs(positionY - y1)
		) {
			resetColorsOfMatrix()
			const arrayOfHorizontal = getDiagonal(
				+x1,
				+y1,
				+positionX,
				+positionY
			)

			Array.from(
				document.querySelectorAll(`.wordSearchMatrixButton`)
			).forEach((square) => {
				const squareX = square.getAttribute('data-position-x')
				const squareY = square.getAttribute('data-position-y')

				const isSelectable = arrayOfHorizontal.some(
					(dataArray) =>
						dataArray[0] === +squareX && dataArray[1] === +squareY
				)

				if (isSelectable) {
					if (!square.getAttribute('is-correct'))
						square.style.background = color.current
					correctLetters.current.push({
						text: square.textContent,
						x: squareX,
						y: squareY,
					})
				} else {
					if (!square.getAttribute('is-correct'))
						square.style.background = '#ffe0c8'
				}
			})
		}
	}

	return (
		<div onMouseLeave={() => resetColorsOfMatrix(true)}>
			{matrix.map((row, rowIndex) => (
				<div key={rowIndex} className='wordSearchMatrix'>
					{row.map((column, columnIndex) => {
						let textOfSquare = column
						if (column === '') {
							textOfSquare =
								alphabet[
									Math.floor(Math.random() * alphabet.length)
								]

							matrix[rowIndex][columnIndex] = textOfSquare
						}

						return (
							<Square
								key={columnIndex}
								rowIndex={rowIndex}
								columnIndex={columnIndex}
								clickedSquare={clickedSquare}
								onMouseUp={onMouseUp}
								handlerOnMouseOver={handlerOnMouseOver}
								textOfSquare={textOfSquare}
								matrix={matrix}
							/>
						)
					})}
				</div>
			))}
		</div>
	)
}
