import React, { useContext, useEffect, useState } from 'react'
import SaveButton from '../../SaveButton'
import { LoadingIcon } from '../../LoadingIcon'
import {
	API_CHANGE_NPC_SCENE,
	API_MANAGE_NPC,
	GET_NPC_COMPOSE_BY_NPC,
} from '../../../../utils/constantsAdmin'
import { useAuthProvider } from '../../../../context/AuthProvider/AuthProvider'
import { returnProcessUrl } from '../../../../utils/general_functions_forms'
import AlertContext from '../../../../context/editor/AlertContext'
import ModalContext from '../../../../context/editor/ModalContext'
import { useTranslation } from 'react-i18next'

export const FormChangeNPC = ({
	idScene,
	stateObjectNpc,
	setRefreshNpcsScene,
}) => {
	const { t } = useTranslation()
	// AXIOS
	const { gameUser, axiosSupreme } = useAuthProvider()

	// LOADING
	const [stateLoading, setStateLoading] = useState(false)

	// ALERT
	const { showAlert, setMessage, setTypeMessage } = useContext(AlertContext)

	// MODAL
	const { openModal } = useContext(ModalContext)

	// VALUES FORM
	const [npc, setNpc] = useState(undefined)
	const [stateNpc, setStateNpc] = useState(undefined)
	const [imageCompose, setImageCompose] = useState(undefined)
	const [errorComposeScene, setErrorComposeScene] = useState(false)

	const onSubmitFormChange = async (event) => {
		event.preventDefault()
		setStateLoading(true)
		let values_composes_scene = Object.values(stateObjectNpc.composes).map(
			(item) => item.id_npc_compose_sub_world_scene_npc_appearance
		)
		let formDataNpcChange = new FormData()
		formDataNpcChange.append('compose_selected', stateNpc)
		formDataNpcChange.append('list_composes', values_composes_scene)

		try {
			let process = await axiosSupreme(
				'patch',
				API_CHANGE_NPC_SCENE + idScene + '/',
				formDataNpcChange
			)
			setRefreshNpcsScene((prev) => !prev)
		} catch (errorPromise) {
			setTypeMessage('error')
			setMessage(errorPromise.response.data.error)
			showAlert()
		}
		setStateLoading(false)
		openModal()
	}

	// VALUES LIST FOR SELECTS
	const [listNpc, setListNpc] = useState({})
	const [listStateNpc, setListStateNpc] = useState({})

	// GET ALL NPC
	useEffect(() => {
		fetchOptionsNpcs(axiosSupreme, API_MANAGE_NPC, setListNpc)
	}, [])

	const onChangeNpc = async (value, setFunction) => {
		setStateNpc(undefined)
		setListStateNpc({})
		setImageCompose(undefined)
		setErrorComposeScene(false)
		if (value === '') {
			setFunction(undefined)
		} else {
			setFunction(value)
			await fetchOptionsStatesNpcs(
				axiosSupreme,
				GET_NPC_COMPOSE_BY_NPC + value + '/',
				setListStateNpc
			)
		}
	}

	const onInputChangeState = async (event, setFunction) => {
		setFunction(event.target.value)
	}

	return (
		<form onSubmit={onSubmitFormChange}>
			<div className='admin__container__inputs'>
				{' '}
				<label
					className='admin__container__inputs__title'
					forhtml='admin__container__inputs__title'>
					{t("pages.editor.components.form_change_npc.current_npc")} {stateObjectNpc.name}
				</label>
			</div>

			<div
				className='admin__container__inputs'
				id='admin__container__inputs'>
				<label
					className='admin__container__inputs__title'
					forhtml='admin__container__inputs__title'>
					{t("pages.editor.components.form_change_npc.available_npc")}
				</label>
				<select
					name='npc'
					className='admin__container__inputs__in'
					id='npc'
					placeholder={t("pages.editor.components.form_change_npc.placeholder_stars")}
					autoComplete='off'
					required
					value={npc}
					onChange={(event) =>
						onChangeNpc(event.target.value, setNpc)
					}>
					<option key='' value=''>
						{t("pages.editor.components.form_change_npc.select_npc")}
					</option>
					{Object.keys(listNpc).map((key) => (
						<option key={key} value={key}>
							{listNpc[key].name}
						</option>
					))}
				</select>
			</div>

			<div
				className='admin__container__inputs'
				id='admin__container__inputs'>
				<label
					className='admin__container__inputs__title'
					forhtml='admin__container__inputs__title'>
					{t("pages.editor.components.form_change_npc.configuration_npc")}
				</label>
				<select
					name='stateNpc'
					className='admin__container__inputs__in'
					id='stateNpc'
					placeholder={t("pages.editor.components.form_change_npc.placeholder_stars")}
					autoComplete='off'
					required
					value={stateNpc}
					onChange={(event) =>
						onInputChangeState(event, setStateNpc)
					}>
					<option key='' value=''>
						{t("pages.editor.components.form_change_npc.select_configuration_npc")}
					</option>
					{Object.keys(listStateNpc).map((key) => (
						<option key={key} value={key}>
							{listStateNpc[key].name}
						</option>
					))}
				</select>
				{imageCompose !== undefined ? (
					<div className='admin__container__img'>
						<img src={imageCompose} alt={t("pages.editor.components.form_change_npc.alt_admin")} loading='lazy' />
					</div>
				) : null}
			</div>

			{stateLoading === false ? (
				<div className='admin__container__boxBtn'>
					<SaveButton></SaveButton>
				</div>
			) : (
				<LoadingIcon />
			)}
		</form>
	)
}

const fetchOptionsNpcs = async (axiosSupreme, url, setList) => {
	url = returnProcessUrl(url)
	let result_data = await axiosSupreme('get', url, undefined)
	result_data.results.map((data_item) => {
		setList((prevData) => ({
			...prevData,
			[data_item.id_npc]: {
				name: data_item.name,
				silence_file: data_item.silence_file,
			},
		}))
	})
	if (result_data.next) {
		fetchOptionsNpcs(axiosSupreme, result_data.next, setList)
	}
}

const fetchOptionsStatesNpcs = async (axiosSupreme, url, setList) => {
	url = returnProcessUrl(url)
	let result_data = await axiosSupreme('get', url, undefined)
	result_data.results.map((data_item) => {
		setList((prevData) => ({
			...prevData,
			[data_item.id_npc_compose]: {
				name: data_item.name,
				image_file: data_item.image_file,
			},
		}))
	})
	if (result_data.next) {
		fetchOptionsNpcs(axiosSupreme, result_data.next, setList)
	}
}
