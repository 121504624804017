import { useContext, useEffect, useState } from 'react'
import ModalContext from '../../../../context/editor/ModalContext'
import {
	API_MANAGE_DOORS,
	API_MANAGE_SUBWORLD_DOORS,
} from '../../../../utils/constantsAdmin'
import { BsFillPencilFill, BsFillTrashFill } from 'react-icons/bs'
import { BodyCollapseDoorSceneWorld } from './BodyCollapseDoorSceneWorld'
import FormDoorSceneWorld from './FormDoorSceneWorld'
import { ManageStorageAdminDesigner } from '../../../../utils/local_storage'
import AlertContext from '../../../../context/editor/AlertContext'
import DeleteContext from '../../../../context/editor/DeleteContext'
import { toggleCollapse } from '../../../../utils/general_functions'
import {
	FaChevronDown,
	FaChevronUp,
	FaExclamationTriangle,
} from 'react-icons/fa'
import { useAuthProvider } from '../../../../context/AuthProvider/AuthProvider'
import { useTranslation } from 'react-i18next'

export default function CollapseDoorSceneWorld({
	idScene,
	objectDoor,
	setRefreshDoors,
	activeCollapseDoors,
	setActiveCollapseDoors,
}) {
	const { t } = useTranslation()
	// AUTH PERMISSION
	const { gameUser, axiosSupreme } = useAuthProvider()

	// SET OBJECT ITEM
	const [idDoor, setIdDoor] = useState(undefined)
	const [stateObjectDoor, setStateObjectDoor] = useState(undefined)
	useEffect(() => {
		setStateObjectDoor(objectDoor)
		setIdDoor(
			gettypeContextSpace() === 'World'
				? objectDoor.id_world_door
				: objectDoor.id_sub_world_door
		)
	}, [objectDoor])

	// LOCAL STORAGE
	const { gettypeContextSpace } = ManageStorageAdminDesigner()

	// ALERT
	const {
		showAlert,
		setMessage: setMessageAlert,
		setTypeMessage,
	} = useContext(AlertContext)

	// MODAL
	const { openModal, setTitleModal, setContentModal } =
		useContext(ModalContext)

	// DELETE
	const { openDelete, setMessage, setFunctionDelete } =
		useContext(DeleteContext)

	// UPDATE DOOR
	const UpdateDoor = async () => {
		setTitleModal(t("pages.editor.components.collapse_door_scene_world.title"))
		setContentModal(
			<FormDoorSceneWorld
				idScene={idScene}
				stateObjectDoor={stateObjectDoor}
				setStateObjectDoor={setStateObjectDoor}
				setRefreshDoors={setRefreshDoors}
			/>
		)
		openModal()
	}

	// DELETE DOOR
	const DeleteDoor = async () => {
		const urlRequest =
			gettypeContextSpace() === 'World'
				? API_MANAGE_DOORS + stateObjectDoor.id_world_door + '/'
				: API_MANAGE_SUBWORLD_DOORS +
				stateObjectDoor.id_sub_world_door +
				'/'
		const result_data = await axiosSupreme('delete', urlRequest, undefined)
		if (result_data.hasOwnProperty('response')) {
			openDelete()
			setTypeMessage('error')
			setMessageAlert(
				t("common.cannot_delete")
			)
			showAlert()
		} else {
			setRefreshDoors((prev) => !prev)
			openDelete()
		}
	}

	return (
		<div className='adminComponents'>
			{stateObjectDoor !== undefined ? (
				<>
					<div className='adminComponents__button collapseStylesBtn'>
						{activeCollapseDoors === idDoor ? (
							<div className='arrowOpenCollapse'>
								<FaChevronUp />{' '}
							</div>
						) : (
							<div className='arrowOpenCollapse'>
								{' '}
								<FaChevronDown />
							</div>
						)}
						<div
							className='adminComponents__button__title title'
							onClick={() =>
								toggleCollapse(
									activeCollapseDoors,
									setActiveCollapseDoors,
									gettypeContextSpace() === 'World'
										? stateObjectDoor.id_world_door
										: stateObjectDoor.id_sub_world_door
								)
							}>
							<h2
								className={
									gettypeContextSpace() === 'World'
										? stateObjectDoor.world_door_path
											? null
											: 'error'
										: stateObjectDoor.sub_world_door_path
											? null
											: 'error'
								}>
								{stateObjectDoor.name}{' '}
								{gettypeContextSpace() === 'World' ? (
									stateObjectDoor.world_door_path ? null : (
										<p>
											<FaExclamationTriangle title={t("pages.editor.components.door_no_destination_assigned")} />
										</p>
									)
								) : stateObjectDoor.sub_world_door_path ? null : (
									<p>
										<FaExclamationTriangle title={t("pages.editor.components.door_no_destination_assigned")} />
									</p>
								)}
							</h2>
						</div>
						<div className='adminComponents__button__options options'>
							{(gettypeContextSpace() === 'World' &&
								gameUser.permissions?.hasOwnProperty(
									'delete_world_door'
								)) ||
								(gettypeContextSpace() === 'Subworld' &&
									gameUser.permissions?.hasOwnProperty(
										'delete_sub_world_door'
									)) ? (
								<button
									className='editorBtnActionDelete'
									onClick={() => {
										setFunctionDelete(() => DeleteDoor)
										setMessage(
											t("pages.editor.components.confirm_deleting_1") +
											stateObjectDoor.name +
											t("pages.editor.components.confirm_deleting_2")
										)
										openDelete()
									}}>
									<p>
										<BsFillTrashFill />
									</p>
								</button>
							) : null}
							{(gettypeContextSpace() === 'World' &&
								gameUser.permissions?.hasOwnProperty(
									'change_world_door'
								)) ||
								(gettypeContextSpace() === 'Subworld' &&
									gameUser.permissions?.hasOwnProperty(
										'change_sub_world_door'
									)) ? (
								<button
									className='editorBtnActionEdit'
									onClick={UpdateDoor}>
									<p>
										<BsFillPencilFill />
									</p>
								</button>
							) : null}
						</div>
					</div>
					{activeCollapseDoors === idDoor && (
						<BodyCollapseDoorSceneWorld
							stateObjectDoor={stateObjectDoor}
							setStateObjectDoor={
								setStateObjectDoor
							}></BodyCollapseDoorSceneWorld>
					)}
				</>
			) : null}
		</div>
	)
}
