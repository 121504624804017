import { FaSpinner } from 'react-icons/fa';
import '../../styles/LoadingIcon.scss'
import { useTranslation } from 'react-i18next';

export const LoadingIcon = () => {
    const {t} = useTranslation()
    return (
        <div className='boxLoadingIcon'>
            <FaSpinner className="loading-icon" />
            <p>{t("pages.editor.components.loading_icon.loading")}</p>
        </div>
    )
}

