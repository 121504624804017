import React, { useContext, useEffect, useState } from 'react'
import { GET_STORY_TELLER_CONTENT_BY_STORY_TELLER } from '../../../../utils/constantsAdmin'
import ModalContext from '../../../../context/editor/ModalContext'
import { FormStoryTellerContent } from './FormStoryTellerContent'
import { SearchCamp } from '../../searchAndPagination/SearchCamp'
import { StoryTellerContentCollapse } from './StoryTellerContentCollapse'
import { Pagination } from '../../searchAndPagination/Pagination'
import { LoadingIcon } from '../../LoadingIcon'
import { useAuthProvider } from '../../../../context/AuthProvider/AuthProvider'
import { useTranslation } from 'react-i18next'

export const ListStoryTellerContent = ({ stateObjectStoryTeller }) => {
	const { t } = useTranslation()
	// AUTH PERMISSION
	const { gameUser, axiosSupreme } = useAuthProvider()

	//REFRESH STORYTELLER CONTENT
	const [refreshStoryTellerContents, setRefreshStoryTellerContents] =
		useState(false)

	// DATA
	const [data, setData] = useState(undefined)

	// GET ALL STORYTELLER CONTENT
	useEffect(() => {
		getData()
	}, [refreshStoryTellerContents])

	const getData = async () => {
		try {
			const result_data = await axiosSupreme(
				'get',
				GET_STORY_TELLER_CONTENT_BY_STORY_TELLER +
				stateObjectStoryTeller.id_storyteller +
				'/'
			)
			setData(result_data)
		} catch (e) {
			console.log('Error get Data: ', e)
		}
	}

	// VALUES SEARCH
	const [search, setSearch] = useState('')

	// MODAL
	const { openModal, setTitleModal, setContentModal } =
		useContext(ModalContext)
	const OpenAddStoryTellerContent = () => {
		setTitleModal(t("pages.editor.components.list_story_teller_content.add_paragraphs"))
		setContentModal(
			<FormStoryTellerContent
				id_storyteller={stateObjectStoryTeller.id_storyteller}
				setRefreshStoryTellerContents={setRefreshStoryTellerContents}
			/>
		)
		openModal()
	}

	// ACTIVECOLLAPSE
	const [
		activeCollapseStoryTellerContent,
		setActiveCollapseStoryTellerContent,
	] = useState(undefined)

	return (
		<>
			<hr />
			<div className='storyTellerContentData'>
				<div className='storyTellerContentData__btnAdd'>
					<h1>{t("pages.editor.components.list_story_teller_content.paragraphs")}</h1>
					<div className='boxBtnAdd'>
						<button
							className='editorBtnActionAdd'
							onClick={OpenAddStoryTellerContent}>
							{t("common.add")}
						</button>
					</div>
				</div>
			</div>

			{data !== undefined ? (
				<>
					{data === 'search' || data.results.length > 0 ? (
						<>
							<SearchCamp
								setData={setData}
								setRefreshDataList={
									setRefreshStoryTellerContents
								}
								url={
									GET_STORY_TELLER_CONTENT_BY_STORY_TELLER +
									stateObjectStoryTeller.id_storyteller +
									'/'
								}
								search={search}
								setSearch={setSearch}
							/>
							{data === 'search' ? (
								<p className='noSelect'>
									{t("pages.editor.components.list_story_teller_content.none_npc_found")}
								</p>
							) : null}
						</>
					) : (
						<p className='noSelect'>
							{t("pages.editor.components.list_story_teller_content.none_content_found")}
						</p>
					)}
					{data !== 'search' ? (
						<div className='storyTellerContentData__Content'>
							{data.results.map((data_item, index) => (
								<StoryTellerContentCollapse
									key={data_item.id_storyteller_content}
									id_storyteller={
										stateObjectStoryTeller.id_storyteller
									}
									objectStoryTellerContent={data_item}
									setRefreshStoryTellerContents={
										setRefreshStoryTellerContents
									}
									activeCollapseStoryTellerContent={
										activeCollapseStoryTellerContent
									}
									setActiveCollapseStoryTellerContent={
										setActiveCollapseStoryTellerContent
									}
								/>
							))}
							<Pagination
								data={data}
								setData={setData}></Pagination>
						</div>
					) : null}
				</>
			) : (
				<LoadingIcon />
			)}
		</>
	)
}
