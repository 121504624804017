import { useTranslation } from "react-i18next"

export const DialogueBodyCollapse = ({ stateObjectDialogue }) => {
	const { t } = useTranslation()
	return (
		<div className='dialoguesContent collapseStylesBody'>
			<div className='dialoguesContent__Left'>
				<div className='info'>
					<h1>{t("pages.editor.components.dialogue_body_collapse.audio")}</h1>
					<audio
						src={stateObjectDialogue.audio_file}
						controls></audio>
				</div>
			</div>
			<div className='dialoguesContent__Right'>
				<div className='info'>
					<h1>{t("pages.editor.components.dialogue_body_collapse.spanish_text")}</h1>
					<p>{stateObjectDialogue.text}</p>
				</div>
				<div className='info'>
					<h1>{t("pages.editor.components.dialogue_body_collapse.text_trasnlation")}</h1>
					<p>{stateObjectDialogue.text_traduction}</p>
				</div>
			</div>
		</div>
	)
}
