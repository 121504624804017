export const IconConfig = () => (
	<svg
		id='Capa_2'
		data-name='Capa 2'
		xmlns='http://www.w3.org/2000/svg'
		viewBox='0 0 31.52 31.52'
		fill='none'
		stroke='#fff'
		strokeWidth='2px'>
		<g id='Capa_1-2' data-name='Capa 1'>
			<circle cx='15.76' cy='15.76' r='15.76' fill='#1653ae' />
			<path
				className='cls-1'
				d='m19.79 23.3-1.2-.54c-.89-.4-1.93-.18-2.58.54l-.88.98c-1.3 1.44-3.69.66-3.88-1.27l-.13-1.31c-.1-.97-.81-1.76-1.76-1.96l-1.29-.27c-1.9-.4-2.42-2.86-.84-4l1.07-.77a2.24 2.24 0 0 0 .82-2.51l-.41-1.25c-.6-1.84 1.27-3.53 3.04-2.73l1.2.54c.89.4 1.93.18 2.58-.54l.88-.98c1.3-1.44 3.69-.66 3.88 1.27l.13 1.31c.1.97.81 1.76 1.76 1.96l1.29.27c1.9.4 2.42 2.86.84 4l-1.07.77a2.24 2.24 0 0 0-.82 2.51l.41 1.25c.6 1.84-1.27 3.53-3.04 2.73Z'
			/>
			<circle className='cls-1' cx='15.76' cy='15.76' r='2.61' />
		</g>
	</svg>
)

export const IconPlay = () => (
	<svg
		xmlns='http://www.w3.org/2000/svg'
		className='icon icon-tabler icon-tabler-player-play-filled'
		width='24'
		height='24'
		viewBox='0 0 24 24'
		strokeWidth='1.5'
		stroke='currentColor'
		fill='none'
		strokeLinecap='round'
		strokeLinejoin='round'>
		<path stroke='none' d='M0 0h24v24H0z' fill='none' />
		<path
			d='M6 4v16a1 1 0 0 0 1.524 .852l13 -8a1 1 0 0 0 0 -1.704l-13 -8a1 1 0 0 0 -1.524 .852z'
			strokeWidth='0'
			fill='currentColor'
		/>
	</svg>
)

export const IconEquis = () => (
	<svg
		data-name='Capa 2'
		xmlns='http://www.w3.org/2000/svg'
		width='20'
		height='20'
		viewBox='0 0 13.18 16.52'
		fill='currentColor'
		stroke='#fff'
		strokeWidth='0'>
		<path
			d='M12.54 13.52c.24.35.16.84-.2 1.09l-1.83 1.27c-.36.24-.85.15-1.09-.2l-2.83-4.09-2.83 4.09c-.24.35-.73.44-1.09.2L.84 14.61a.778.778 0 0 1-.2-1.09l3.64-5.26L.64 2.99a.778.778 0 0 1 .2-1.09L2.67.63c.36-.24.85-.15 1.09.2l2.83 4.09L9.42.83c.24-.35.73-.44 1.09-.2l1.83 1.27c.36.25.44.74.2 1.09L8.9 8.26l3.64 5.26Z'
			data-name='Capa 1'
		/>
	</svg>
)

export const IconPencil = () => (
	<svg
		id='Capa_2'
		data-name='Capa 2'
		xmlns='http://www.w3.org/2000/svg'
		width='20'
		height='20'
		viewBox='0 0 15.72 20.1'
		fill='currentColor'>
		<g id='Capa_1-2' data-name='Capa 1'>
			<path
				className='cls-1'
				d='M.45 19.88a1.12 1.12 0 0 1-.44-1.09l.79-4.65 9.55-12.93c.97-1.32 2.84-1.6 4.16-.63 1.32.97 1.6 2.84.63 4.16L5.51 17.72l-3.83 2.22c-.38.22-.84.2-1.2-.05-.01 0-.02-.01-.03-.02Zm1.38-5.3-.75 4.39 3.78-2.14L14.27 4.1c.62-.84.44-2.02-.4-2.64s-2.02-.44-2.64.4L1.84 14.58Z'
			/>
			<path
				className='cls-1'
				d='m1.49 14.88-.34-1.04c.07-.02 1.84-.59 3.25.45s1.39 2.89 1.38 2.97l-1.09-.02s0-1.38-.94-2.07-2.25-.29-2.26-.29Z'
			/>
			<path
				className='cls-1'
				transform='rotate(-53.58 11.606 4.456)'
				d='M11.06 2.55h1.09v3.81h-1.09z'
			/>
		</g>
	</svg>
)
