import { useContext, useEffect, useState } from 'react'
import {
	API_MANAGE_NPC,
	API_NPC_SUB_WORLD_SCENE_NPC_APPEARANCE,
	API_SUB_WORLD_SCENE_NPC_APPEARANCE,
	GET_CHECK_NPC_SUB_WORLD_SCENE_NPC_APPEARANCE_BY_SCENE,
	GET_COORDINATE_NPC_SUB_WORLD_SCENE_NPC_APPEARANCE_BY_SCENE,
	GET_NPC_COMPOSE_BY_NPC,
} from '../../../../utils/constantsAdmin'
import AlertContext from '../../../../context/editor/AlertContext'
import ModalContext from '../../../../context/editor/ModalContext'
import SaveButton from '../../SaveButton'
import { LoadingIcon } from '../../LoadingIcon'
import { useAuthProvider } from '../../../../context/AuthProvider/AuthProvider'
import { returnProcessUrl } from '../../../../utils/general_functions_forms'
import { useTranslation } from 'react-i18next'

export const FormNpcAppearanceScene = ({ idScene, setRefreshNpcsScene }) => {
	const { t } = useTranslation()
	const { gameUser, axiosSupreme } = useAuthProvider()
	// ALERT, MODAL
	const { showAlert, setMessage, setTypeMessage } = useContext(AlertContext)
	const { openModal } = useContext(ModalContext)

	// VALUES FORM
	const [npc, setNpc] = useState(undefined)
	const [stateNpc, setStateNpc] = useState(undefined)
	const [coordinatesXPosition, setCoordinatesXPosition] = useState('')
	const [coordinatesYPosition, setCoordinatesYPosition] = useState('')
	const [imageCompose, setImageCompose] = useState(undefined)
	const [errorComposeScene, setErrorComposeScene] = useState(false)
	const [existsCoordinate, setExistsCoordinate] = useState(undefined)

	// VALUES LIST FOR SELECTS
	const [listNpc, setListNpc] = useState({})
	const [listStateNpc, setListStateNpc] = useState({})

	// LOADING
	const [stateLoading, setStateLoading] = useState(false)

	// GET ALL NPC
	useEffect(() => {
		fetchOptionsNpcs(axiosSupreme, API_MANAGE_NPC, setListNpc)
	}, [])

	// SUBMIT FORM
	const onSubmitNpcScene = async (event) => {
		event.preventDefault()
		setStateLoading(true)
		let result_data_coordinates = ''
		if (existsCoordinate !== undefined) {
			result_data_coordinates = existsCoordinate
		} else {
			// INSERT COORDINATES
			let formDataCoordinates = new FormData()
			formDataCoordinates.append(
				'coordinates',
				'[' +
				event.target.elements.coordinatesXPosition.value +
				',' +
				event.target.elements.coordinatesYPosition.value +
				']'
			)
			formDataCoordinates.append('sub_world_scene', idScene)
			result_data_coordinates = await axiosSupreme(
				'post',
				API_SUB_WORLD_SCENE_NPC_APPEARANCE,
				formDataCoordinates
			)
		}

		if (result_data_coordinates.hasOwnProperty('response')) {
			if (result_data_coordinates.response.status === 400) {
				setTypeMessage('error')
				let error = ''
				{
					Object.keys(result_data_coordinates.response.data).map(
						(key) => {
							error += key
							for (
								let i = 0;
								i <
								result_data_coordinates.response.data[key]
									.length;
								i++
							) {
								error +=
									': \t' +
									result_data_coordinates.response.data[
									key
									][0] +
									'\n'
							}
							error += '\n'
						}
					)
				}
				setMessage(error)
				showAlert()
			}
		} else {
			// INSERT NPC SCENE
			let formDataScene = new FormData()
			formDataScene.append(
				'npc_compose',
				event.target.elements.stateNpc.value
			)
			formDataScene.append(
				'sub_world_scene_npc_appearance',
				result_data_coordinates.id_sub_world_scene_npc_appearance
			)
			let result_npc_scene = await axiosSupreme(
				'post',
				API_NPC_SUB_WORLD_SCENE_NPC_APPEARANCE,
				formDataScene
			)

			if (result_npc_scene.hasOwnProperty('response')) {
				if (result_npc_scene.response.status === 400) {
					setTypeMessage('error')
					let error = ''
					{
						Object.keys(result_npc_scene.response.data).map(
							(key) => {
								error += key
								for (
									let i = 0;
									i <
									result_npc_scene.response.data[key].length;
									i++
								) {
									error +=
										': \t' +
										result_npc_scene.response.data[key][0] +
										'\n'
								}
								error += '\n'
							}
						)
					}
					setMessage(error)
					showAlert()
					setStateLoading(false)
				}
			} else {
				// HACER ALGO CUANDO SE EJECUTA CORRECTAMENTE
				clearFields()
				setTypeMessage('success')
				setMessage(t("success.action_success"))
				showAlert()
				setRefreshNpcsScene((prev) => !prev)
				setStateLoading(false)
				openModal()
			}
		}
	}

	// CLEAR VALUES FOR FIELDS
	const clearFields = () => {
		setNpc('')
		setStateNpc('')
		setCoordinatesXPosition('')
		setCoordinatesYPosition('')
	}

	const onChangeNpc = async (value, setFunction) => {
		setStateNpc(undefined)
		setListStateNpc({})
		setImageCompose(undefined)
		setErrorComposeScene(false)
		if (value === '') {
			setFunction(undefined)
		} else {
			await fetchOptionsStatesNpcs(
				axiosSupreme,
				GET_NPC_COMPOSE_BY_NPC + value + '/',
				setListStateNpc
			)
		}
	}

	const onInputChangeState = async (event, setFunction) => {
		setFunction(event.target.value)
		if (event.target.value !== '') {
			setImageCompose(
				event.target.value !== ''
					? listStateNpc[event.target.value].image_file
					: undefined
			)
			const result_check = await axiosSupreme(
				'get',
				GET_CHECK_NPC_SUB_WORLD_SCENE_NPC_APPEARANCE_BY_SCENE +
				event.target.value +
				'/' +
				idScene +
				'/',
				undefined
			)
			if (result_check.length > 0) {
				setErrorComposeScene(true)
			} else {
				setErrorComposeScene(false)
				const result_coordinate = await axiosSupreme(
					'get',
					GET_COORDINATE_NPC_SUB_WORLD_SCENE_NPC_APPEARANCE_BY_SCENE +
					event.target.value +
					'/' +
					idScene +
					'/',
					undefined
				)
				if (result_coordinate.length > 0) {
					setExistsCoordinate(
						result_coordinate[0].sub_world_scene_npc_appearance
					)
					setCoordinatesXPosition(
						result_coordinate[0].sub_world_scene_npc_appearance.coordinates
							.replace('[', '')
							.replace(']', '')
							.split(',')[0]
					)
					setCoordinatesYPosition(
						result_coordinate[0].sub_world_scene_npc_appearance.coordinates
							.replace('[', '')
							.replace(']', '')
							.split(',')[1]
					)
				} else {
					setExistsCoordinate(undefined)
					setCoordinatesXPosition('')
					setCoordinatesYPosition('')
				}
			}
		} else {
			setImageCompose(undefined)
			setErrorComposeScene(false)
		}
	}

	const onInputChange = async (event, setFunction) => {
		setFunction(event.target.value)
	}

	const handleKeyPress = (event) => {
		const charCode = event.charCode || event.keyCode
		if ((charCode < 48 || charCode > 57) && charCode != 46) {
			event.preventDefault()
		}
	}

	return (
		<form onSubmit={onSubmitNpcScene}>
			<div
				className='admin__container__inputs'
				id='admin__container__inputs'>
				<label
					className='admin__container__inputs__title'
					forhtml='admin__container__inputs__title'>
					{t("pages.editor.components.form_npc_appearance_escene.available_npcs")}
				</label>
				<select
					name='npc'
					className='admin__container__inputs__in'
					id='npc'
					placeholder={t("pages.editor.components.form_npc_appearance_escene.stars_placeholder")}
					autoComplete='off'
					required
					value={npc}
					onChange={(event) =>
						onChangeNpc(event.target.value, setNpc)
					}>
					<option key='' value=''>
						{t("pages.editor.components.form_npc_appearance_escene.select_npc")}
					</option>
					{Object.keys(listNpc).map((key) => (
						<option key={key} value={key}>
							{listNpc[key].name}
						</option>
					))}
				</select>
			</div>

			<div
				className='admin__container__inputs'
				id='admin__container__inputs'>
				<label
					className='admin__container__inputs__title'
					forhtml='admin__container__inputs__title'>
					{t("pages.editor.components.form_npc_appearance_escene.config_npc")}
				</label>
				<select
					name='stateNpc'
					className='admin__container__inputs__in'
					id='stateNpc'
					placeholder={t("pages.editor.components.form_npc_appearance_escene.stars_placeholder")}
					autoComplete='off'
					required
					value={stateNpc}
					onChange={(event) =>
						onInputChangeState(event, setStateNpc)
					}>
					<option key='' value=''>
						{t("pages.editor.components.form_npc_appearance_escene.select_config_npc")}
					</option>
					{Object.keys(listStateNpc).map((key) => (
						<option key={key} value={key}>
							{listStateNpc[key].name}
						</option>
					))}
				</select>
				{imageCompose != null && (
					<div className='admin__container__img'>
						<img src={imageCompose} alt={t("pages.editor.components.form_npc_appearance_escene.alt_config_npc")} loading='lazy' />
					</div>
				)}
			</div>

			{errorComposeScene === false ? (
				<>
					<div
						className='admin__container__inputsCoordenadas'
						id='admin__container__inputsCoordenadas'>
						<label
							className='admin__container__inputsCoordenadas__title'
							forhtml='admin__container__inputsCoordenadas__title'>
							{t("pages.editor.components.form_npc_appearance_escene.coordinates_npc")}{' '}
							{existsCoordinate !== undefined
								? t("pages.editor.components.form_npc_appearance_escene.default_assignment")
								: null}
						</label>
						<div className='admin__container__inputsCoordenadas__columns'>
							<input
								maxLength='45'
								name='coordinatesXPosition'
								className='admin__container__inputsCoordenadas__columns__in'
								id='coordinatesXPosition'
								type='text'
								placeholder={t("pages.editor.components.form_npc_appearance_escene.x_position")}
								autoComplete='off'
								value={coordinatesXPosition}
								onChange={(event) =>
									onInputChange(
										event,
										setCoordinatesXPosition
									)
								}
								onKeyPress={(event) => handleKeyPress(event)}
								readOnly={existsCoordinate !== undefined}
								required></input>
							<input
								maxLength='45'
								name='coordinatesYPosition'
								className='admin__container__inputsCoordenadas__columns__in'
								id='coordinatesYPosition'
								type='text'
								placeholder={t("pages.editor.components.form_npc_appearance_escene.y_position")}
								autoComplete='off'
								value={coordinatesYPosition}
								onChange={(event) =>
									onInputChange(
										event,
										setCoordinatesYPosition
									)
								}
								onKeyPress={(event) => handleKeyPress(event)}
								readOnly={existsCoordinate !== undefined}
								required></input>
						</div>
					</div>
					{stateLoading === false ? (
						<div className='admin__container__boxBtn'>
							<SaveButton></SaveButton>
						</div>
					) : (
						<LoadingIcon />
					)}
				</>
			) : (
				<h1>
					{t("pages.editor.components.form_npc_appearance_escene.config_already_assignment")}
				</h1>
			)}
		</form>
	)
}

const fetchOptionsNpcs = async (axiosSupreme, url, setList) => {
	url = returnProcessUrl(url)
	let result_data = await axiosSupreme('get', url, undefined)
	result_data.results.map((data_item) => {
		setList((prevData) => ({
			...prevData,
			[data_item.id_npc]: {
				name: data_item.name,
				silence_file: data_item.silence_file,
			},
		}))
	})
	if (result_data.next) {
		fetchOptionsNpcs(axiosSupreme, result_data.next, setList)
	}
}

const fetchOptionsStatesNpcs = async (axiosSupreme, url, setList) => {
	url = returnProcessUrl(url)
	let result_data = await axiosSupreme('get', url, undefined)
	result_data.results.map((data_item) => {
		setList((prevData) => ({
			...prevData,
			[data_item.id_npc_compose]: {
				name: data_item.name,
				image_file: data_item.image_file,
			},
		}))
	})
	if (result_data.next) {
		fetchOptionsNpcs(axiosSupreme, result_data.next, setList)
	}
}
