import React from 'react'
import { useTranslation } from 'react-i18next'

export const ItemBodyCollapse = ({ stateObjectItem }) => {
	const { t } = useTranslation()
	return (
		<div className='itemsAdminBody collapseStylesBody'>
			<div className='itemsAdmin__category'>
				<h1>{t("pages.editor.components.item_body_collapse.category")}</h1>
				<p>{stateObjectItem.item_category.name}</p>
			</div>
			<div className='itemsAdmin__media'>
				<div className='img'>
					<h3>{t("pages.editor.components.item_body_collapse.image")}</h3>
					<a
						href={stateObjectItem.image_file}
						target='_blank'
						rel='noreferrer noopener'
					>
						<img src={stateObjectItem.image_file} alt={t("pages.editor.components.item_body_collapse.alt_article")} loading='lazy' />
					</a>
				</div>
				<div className='imgPrev'>
					<h3>{t("pages.editor.components.item_body_collapse.preview_image")}</h3>
					<a
						href={stateObjectItem.image_preview_file}
						target='_blank'
						rel='noreferrer noopener'
					>
						<img src={stateObjectItem.image_preview_file} alt={t("pages.editor.components.item_body_collapse.alt_preview_article")} loading='lazy' />
					</a>
				</div>
			</div>
			<div className='itemsAdmin__category'>
				<h1>{t("pages.editor.components.item_body_collapse.sound")}</h1>
				{stateObjectItem.sound ? (
					<audio
						src={stateObjectItem.sound.audio_file}
						controls></audio>
				) : (
					t("pages.editor.components.item_body_collapse.none_sound_selected")
				)}
			</div>
		</div>
	)
}
