import '../../styles/LoadingScreen.scss'
import teodoroImageAvif from '../../assets/images/teodoro2.avif'
import teodoroImage from '../../assets/images/teodoro2.png'
import Spinner from '../shared/Spinner/Spinner'

export function LoadingScreen({ isVisible }) {
	return (
		<div
			className='loading__screen'
			style={{
				zIndex: 10000,
				transition: 'opacity 600ms ease-in',
				opacity: isVisible ? 1 : 0,
				left: isVisible ? 0 : '100%',
			}}>
			<div className='loading__screen__background'>
				<div className='loading__screen__background__image' />
				<picture className='loading__screen__background__teodoro'>
					<source srcSet={teodoroImageAvif} type='image/avif' />
					<img
						src={teodoroImage}
						alt='pantalla de carga'
						loading='eager'
						fetchpriority='high'
					/>
				</picture>
			</div>
			<div className='loading__screen__main'>
				<div className='loading__screen__main__spinner'>
					<Spinner
						size={46}
						style={{
							borderWidth: '5px',
							borderColor: 'black',
							borderBottomColor: 'transparent',
							opacity: '.3',
						}}
					/>
				</div>
			</div>
		</div>
	)
}
