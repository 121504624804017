import { useTranslation } from 'react-i18next'
import { ChallengeAndGameCollapse } from './ChallengeAndGameCollapse'

export const ChallengeContent = () => {
	const { t } = useTranslation()
	return (
		<div className='adminDesign__container__mainContainerBank'>
			<div className='adminDesign__container__mainContainerBank__Item'>
				<div className='adminDesign__container__mainContainerBank__Item__title'>
					<h1>{t("pages.editor.components.challenge_content.title")}</h1>
				</div>
				<div className='adminDesign__container__mainContainerBank__Item__content'>
					<hr />
					<ChallengeAndGameCollapse />
				</div>
			</div>
		</div>
	)
}
