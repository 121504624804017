import React, { useContext, useEffect, useState } from 'react'
import { SearchCamp } from '../../searchAndPagination/SearchCamp'
import ModalContext from '../../../../context/editor/ModalContext'
import { Pagination } from '../../searchAndPagination/Pagination'
import { UserForm } from './UserForm'
import { API_MANAGE_USERS_ADMIN } from '../../../../utils/constantsAdmin'
import { UserContent } from './UserContent'
import { LoadingIcon } from '../../LoadingIcon'
import { useAuthProvider } from '../../../../context/AuthProvider/AuthProvider'

export default function UserTable() {
	// AUTH PERMISSION
	const { gameUser, axiosSupreme } = useAuthProvider()

	//REFRESH USERS
	const [refreshUsers, setRefreshUsers] = useState(false)

	// DATA
	const [data, setData] = useState(undefined)

	// VALUES SEARCH
	const [search, setSearch] = useState('')

	// MODAL
	const { openModal, setTitleModal, setContentModal } =
		useContext(ModalContext)
	const OpenAddUserModal = () => {
		setTitleModal('Agregar Usuario')
		setContentModal(<UserForm setRefreshUsers={setRefreshUsers} />)
		openModal()
	}

	// GET ALL ANIMATIONS
	useEffect(() => {
		getData()
	}, [refreshUsers])

	const getData = async () => {
		try {
			const result_data = await axiosSupreme(
				'get',
				API_MANAGE_USERS_ADMIN
			)
			setData(result_data)
		} catch (e) {
			console.log('Error get Data: ', e)
		}
	}

	// ACTIVECOLLAPSE
	const [activeCollapseAnimations, setActiveCollapseAnimations] =
		useState(undefined)

	return (
		<div className='userContent'>
			<div className='userContent__btn'>
				{gameUser.permissions?.hasOwnProperty('add_user') ? (
					<button className='btnAdd' onClick={OpenAddUserModal}>
						Agregar
					</button>
				) : null}
			</div>
			{data !== undefined ? (
				<>
					{data === 'search' || data.results.length > 0 ? (
						<>
							<SearchCamp
								setData={setData}
								setRefreshDataList={setRefreshUsers}
								url={API_MANAGE_USERS_ADMIN}
								search={search}
								setSearch={setSearch}
							/>
							{data === 'search' ? (
								<p className='noSelect'>
									No se ha encontrado ningún Usuario
								</p>
							) : null}
						</>
					) : (
						<p className='noSelect'>
							No existe ningún sonido registrado.
						</p>
					)}
					<div className='userContent__table'>
						<table className='table'>
							<tbody>
								<tr>
									<th>Usuario</th>
									<th>Nombre</th>
									<th>Apellido</th>
									<th>E-mail</th>
									<th>País</th>
									{gameUser.permissions?.hasOwnProperty(
										'change_user'
									) ||
									gameUser.permissions?.hasOwnProperty(
										'delete_user'
									) ? (
										<th>Opciones</th>
									) : null}
								</tr>
								{data !== 'search' ? (
									<>
										{data.results.map((data_item, index) =>
											data_item.id !==
											gameUser.id_user ? (
												<UserContent
													key={data_item.id}
													objUser={data_item}
													setRefreshUsers={
														setRefreshUsers
													}
												/>
											) : null
										)}
									</>
								) : null}
							</tbody>
						</table>
					</div>
					<Pagination data={data} setData={setData}></Pagination>
				</>
			) : (
				<LoadingIcon />
			)}
		</div>
	)
}
