import { useEffect, useState } from "react"

export const useTutorialSecuence = (playerRef, profileData) => {
	const [tutorialSecuence, setTutorialSecuence] = useState({
		isActive: false,
		open: function () {
			playerRef.current.enableMovement(false)
			setTutorialSecuence((ts) => ({
				...ts,
				isActive: true,
			}))
		},
		onClose: function () {
			playerRef.current.enableMovement(true)
			setTutorialSecuence((ts) => ({
				...ts,
				isActive: false,
			}))
		},
	})

	useEffect(() => {
		if (profileData.total_xp < 100) tutorialSecuence.open()
	}, [])

	return [tutorialSecuence, setTutorialSecuence]
}
