import { useState } from "react"

export const useLocalTransitionScreen = () => {
	const transitionScreenStates = {
		normal: 'scene__cover',
		fade: 'scene__cover scene__cover__fade',
	}
	const [transitionScreen, setTransitionScreen] = useState(
		transitionScreenStates.normal
	)

	return [transitionScreenStates, transitionScreen, setTransitionScreen]
}
