import '../../styles/StepSlidingBar.scss'

export const StepSlidingBar = ({
	stepsAmount,
	actualStep,
	additionalClassName,
}) => {
	const style = {
		left: '-' + (100 - actualStep * (100 / stepsAmount)) + '%',
	}

	// See aditional classNames on the StepSlidingBar.scss
	return (
		<div
			className={
				'step__sliding__bar' +
				(additionalClassName
					? ' step__sliding__bar__' + additionalClassName
					: '')
			}>
			<div
				className={
					'step__sliding__bar__slider' +
					(additionalClassName
						? ' step__sliding__bar__slider__' + additionalClassName
						: '')
				}
				style={style}></div>
			{[...Array(stepsAmount).keys()].map((divider, index) => (
				<div key={index} className='step__sliding__bar__divider'></div>
			))}
		</div>
	)
}
