import volumeOnIcon from '../../../assets/icons/volumeOn.png'

export const AudioInWordSearch = ({ src, style = {}, playAudio, soupWord }) => {

	const handlerPlayAudio = () => {
		playAudio({ src })
	}

	return (
		<div className='audio__help__button' style={style}>
			<button
				className='audio__help__button__container'
				disabled={!src}
				onClick={handlerPlayAudio}
			>
				<img
					src={volumeOnIcon}
					className='audio__help__button__container__image'
					alt={`audio of ${soupWord}`}
					loading='lazy'
				/>
			</button>
		</div>
	)
}
