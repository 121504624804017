import React, { useContext, useEffect, useRef, useState } from 'react'
import AlertContext from '../../../../../context/editor/AlertContext'
import ModalContext from '../../../../../context/editor/ModalContext'
import { API_MANAGE_NPC } from '../../../../../utils/constantsAdmin'
import SaveButton from '../../../SaveButton'
import {
	onInputChangeDefault,
	onInputChangeImage,
	processErrors,
} from '../../../../../utils/general_functions_forms'
import { LoadingIcon } from '../../../LoadingIcon'
import { useAuthProvider } from '../../../../../context/AuthProvider/AuthProvider'
import { useTranslation } from 'react-i18next'

export const FormNpcs = ({
	stateObjectNpc,
	setStateObjectNpc,
	setRefreshNpcs,
}) => {
	const { t } = useTranslation()
	const { gameUser, axiosSupreme } = useAuthProvider()
	// ALERT
	const { showAlert, setMessage, setTypeMessage } = useContext(AlertContext)

	// MODAL
	const { openModal } = useContext(ModalContext)

	// VALUES FORM
	const [name, setName] = useState('')
	const [description, setDescription] = useState('')
	const silenceFile = useRef(null)

	// ERROR FOR FORM
	const [errorImage, setErrorImage] = useState('')
	const [errorImageSilence, setErrorImageSilence] = useState('')

	// LOADING
	const [stateLoading, setStateLoading] = useState(false)

	// CLEAR VALUES FOR FIELDS
	const clearFields = () => {
		setName('')
		setDescription('')
		silenceFile.current.value = null
	}

	// SET NPC WHEN UPDATE
	useEffect(() => {
		if (stateObjectNpc !== undefined) {
			setName(stateObjectNpc.name)
			setDescription(stateObjectNpc.description)
		} else {
			clearFields()
		}
	}, [stateObjectNpc])

	// SUBMIT FORM NPC
	const onSubmitNpc = async (event) => {
		event.preventDefault()
		setStateLoading(true)
		// MAKE DICT
		let formData = new FormData()
		formData.append('name', event.target.elements.name.value)
		formData.append('description', event.target.elements.description.value)
		if (event.target.elements.silence_file.value) {
			formData.append(
				'silence_file',
				event.target.elements.silence_file.files[0]
			)
		}
		if (stateObjectNpc === undefined) {
			// SAVE
			manageNpc(API_MANAGE_NPC, formData, 'add')
		} else {
			// UPDATE
			manageNpc(
				API_MANAGE_NPC + stateObjectNpc.id_npc + '/',
				formData,
				'update'
			)
		}
	}

	// FUNCTION FOR SAVE OR UPDATE ANIMATION
	const manageNpc = async (url, obj, action) => {
		try {
			// PROCESS DATA
			let result_data =
				action === 'add'
					? await axiosSupreme('post', url, obj)
					: await axiosSupreme('patch', url, obj)
			clearFields()
			setTypeMessage('success')
			setMessage(
				action === 'add'
					? t("common.added")
					: t("common.modified")
			)
			showAlert()
			if (action === 'add') setRefreshNpcs((prev) => !prev)
			if (action === 'update') getNewObject()
			openModal()
		} catch (errorPromise) {
			setTypeMessage('error')
			setMessage(t("errors.request_error"))
			if (errorPromise.response.status === 403) {
				setMessage(
					t("common.insufficient_permissions")
				)
			}
			if (errorPromise.response.status === 400) {
				let error = processErrors(errorPromise.response.data)
				setMessage(error)
			}
			showAlert()
		}
		setStateLoading(false)
	}

	const getNewObject = async () => {
		const result_data = await axiosSupreme(
			'get',
			API_MANAGE_NPC + stateObjectNpc.id_npc + '/',
			undefined
		)
		setStateObjectNpc(result_data)
	}

	return (
		<form onSubmit={onSubmitNpc}>
			<div
				className='admin__container__inputs'
				id='admin__container__inputs'>
				<label
					className='admin__container__inputs__title'
					forhtml='admin__container__inputs__title'>
					{t("pages.editor.components.form_npcs.name")}
				</label>
				<input
					maxLength='45'
					name='name'
					className='admin__container__inputs__in'
					id='name'
					type='text'
					placeholder={t("pages.editor.components.form_npcs.name_placeholder")}
					autoComplete='off'
					value={name}
					onChange={(event) => onInputChangeDefault(event, setName)}
					required></input>
			</div>
			<div
				className='admin__container__inputs'
				id='admin__container__inputs'>
				<label
					className='admin__container__inputs__title'
					forhtml='admin__container__inputs__title'>
					{t("pages.editor.components.form_npcs.description")}
				</label>
				<input
					maxLength='45'
					name='description'
					className='admin__container__inputs__in'
					id='description'
					type='text'
					placeholder={t("pages.editor.components.form_npcs.description_placeholder")}
					autoComplete='off'
					value={description}
					onChange={(event) =>
						onInputChangeDefault(event, setDescription)
					}
					required></input>
			</div>
			<div
				className='admin__container__inputs'
				id='admin__container__inputs'>
				<label
					className='admin__container__inputs__title'
					forhtml='admin__container__inputs__title'>
					{t("pages.editor.components.form_npcs.silence_image")}
				</label>
				{stateObjectNpc !== undefined ? (
					<>
						{stateObjectNpc.silence_file !== null ? (
							<label htmlFor=''>
								{t("common.current_file")}
								<a
									href={stateObjectNpc.silence_file}
									target='_blank'
									rel='noopener noreferrer'>
									{t("common.open")}
								</a>
							</label>
						) : null}
					</>
				) : null}
				<input
					name='silence_file'
					className='admin__container__inputs__in'
					id='silence_file'
					type='file'
					ref={silenceFile}
					autoComplete='off'
					onChange={(event) =>
						onInputChangeImage(
							event,
							setErrorImageSilence,
							silenceFile
						)
					}
					accept='image/*'></input>
				{errorImageSilence && <p>{errorImageSilence}</p>}
			</div>
			{stateLoading === false ? (
				<div className='admin__container__boxBtn'>
					<SaveButton></SaveButton>
				</div>
			) : (
				<LoadingIcon />
			)}
		</form>
	)
}
