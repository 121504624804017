import { Sprite } from "./Sprite"

export class Decoration extends Sprite {
	#hidden
	#isSuperimposed

	constructor(id, name, pos, spriteSheets, hidden, isSuperimposed) {
		super(id, name, pos, spriteSheets)
		this.#hidden = hidden
		this.#isSuperimposed = isSuperimposed
	}
	create(scene, bodyType) {
		super.create(scene, bodyType)

		if (this.#hidden) this.sprite.alpha = 0
		if (this.#isSuperimposed) this.sprite.depth = 20
	}
	destroyEnd() {
		if (this.#hidden) this.sprite.alpha = 1
	}
}
