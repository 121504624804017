import React, { useContext, useEffect, useState } from 'react'
import { FormChallenge } from './FormChallenge'
import ModalContext from '../../../../../context/editor/ModalContext'
import { API_MANAGE_CHALLENGES } from '../../../../../utils/constantsAdmin'
import { SearchCamp } from '../../../searchAndPagination/SearchCamp'
import { ChallengeCollapse } from './ChallengeCollapse'
import { Pagination } from '../../../searchAndPagination/Pagination'
import { LoadingIcon } from '../../../LoadingIcon'
import { useAuthProvider } from '../../../../../context/AuthProvider/AuthProvider'
import { useTranslation } from 'react-i18next'

export const ListChallenges = () => {
	const { t } = useTranslation()
	// AUTH PERMISSION
	const { gameUser, axiosSupreme } = useAuthProvider()

	// REFRESH CHALLENGES
	const [refreshChallenges, setRefreshChallenges] = useState(false)

	// DATA
	const [data, setData] = useState(undefined)


	// GET ALL CHALLENGES
	useEffect(() => {
		getData()
	}, [refreshChallenges])

	const getData = async () => {
		try {
			const result_data = await axiosSupreme(
				'get',
				API_MANAGE_CHALLENGES
			)
			setData(result_data)
		} catch (e) {
			console.log('Error get Data: ', e)
		}
	}

	// VALUES SEARCH
	const [search, setSearch] = useState('')

	// MODAL
	const { openModal, setTitleModal, setContentModal } =
		useContext(ModalContext)
	const OpenAddChallenges = () => {
		setTitleModal(t("pages.editor.components.list_challenges.title_modal"))
		setContentModal(
			<FormChallenge setRefreshChallenges={setRefreshChallenges} />
		)
		openModal()
	}

	// ACTIVECOLLAPSE
	const [activeCollapse, setActiveCollapse] = useState(undefined)

	return (
		<>
			{data !== undefined ? (
				<>
					<div className='challengeAndGameDataContent__btnAdd'>
						<h1>{t("pages.editor.components.list_challenges.title")}</h1>
						<div className='boxBtnAdd'>
							{gameUser.permissions?.hasOwnProperty(
								'add_challenge'
							) ? (
								<button
									className='editorBtnActionAdd'
									onClick={OpenAddChallenges}>
									{t("common.add")}
								</button>
							) : null}
						</div>
					</div>
					{data === 'search' || data.results.length > 0 ? (
						<>
							<SearchCamp
								setData={setData}
								setRefreshDataList={setRefreshChallenges}
								url={API_MANAGE_CHALLENGES}
								search={search}
								setSearch={setSearch}
							/>
							{data === 'search' ? (
								<p className='noSelect'>
									{t("pages.editor.components.list_challenges.none_challenge")}
								</p>
							) : null}
						</>
					) : (
						<p className='noSelect'>
							{t("pages.editor.components.list_challenges.none_challenge")}
						</p>
					)}
					{data !== 'search' ? (
						<>
							{data.results.map((data_item, index) => (
								<ChallengeCollapse
									key={data_item.id_challenge}
									objectChallenge={data_item}
									setRefreshChallenges={setRefreshChallenges}
									activeCollapse={activeCollapse}
									setActiveCollapse={setActiveCollapse}
								/>
							))}
							<Pagination
								data={data}
								setData={setData}></Pagination>
						</>
					) : null}
				</>
			) : (
				<LoadingIcon />
			)}
		</>
	)
}
