import { useEffect, useRef, useState } from 'react'
import { useSceneConfig } from '../context/SceneConfigProvider'

const NAME_OF_SOUNDS = {
	MUSICA: 'MUSICA',
	'EFFECTOS DE SONIDO': 'EFFECTOS DE SONIDO',
	VOCES: 'VOCES',
}

export const useLocalSounds = (sceneData) => {
	const { soundPointers, loadingIsVisible } = useSceneConfig()
	const audioStoryTeller = useRef(new Audio())
	// Background, NPCS, Spritesheets, Games.
	const NPCS = useRef(sceneData.sounds.NPCS)
	const effects = useRef(sceneData.sounds.effects)
	const storyteller = useRef(sceneData.sounds.storyteller)
	const games = useRef(sceneData.sounds.games)
	const background = useRef()
	const backgroundPlaying = useRef(false)
	const ambientSound = useRef()
	const ambientSoundPlaying = useRef(false)
	const intervalSoundBackground = useRef(0)
	const intervalPauseSoundBackground = useRef(0)
	const intervalAmbientSound = useRef(0)
	const intervalPausaAmbientSound = useRef(0)
	const [, setReload] = useState(false)

	const playAudioBackground = () => {
		const musicLS = localStorage.getItem('MUSIC_IS_ACTIVE')
		if (Number.isNaN(Number(musicLS)) || musicLS === '0') return
		const srcAudio = sceneData.sounds.background?.file

		try {
			new URL(srcAudio)
		} catch (error) {
			return
		}

		if (backgroundPlaying.current) return

		backgroundPlaying.current = true
		background.current = new Audio()
		background.current.src = srcAudio
		background.current.loop = true
		background.current.volume = 0

		background.current.onloadeddata = () => {
			let promise = background.current.play()

			const volume = Number.isNaN(Number(musicLS)) ? 1 : Number(musicLS)
			clearInterval(intervalPauseSoundBackground.current)
			clearInterval(intervalSoundBackground.current)

			if (promise !== undefined) {
				promise
					.then((_) => {
						intervalSoundBackground.current = setInterval(() => {
							if (background.current.volume >= volume) {
								background.current.volume = volume
								clearInterval(intervalSoundBackground.current)
							} else {
								if (background.current.volume + 0.01 > 1) {
									background.current.volume = 1
								} else {
									background.current.volume += 0.01
								}
							}
						}, 100)
					})
					.catch((err) => console.error(err))
			}
		}
	}

	const pauseAudioBackground = () => {
		if (background.current == null) return

		clearInterval(intervalSoundBackground.current)
		intervalPauseSoundBackground.current = setInterval(() => {
			if (background.current.volume === 0) {
				clearInterval(intervalPauseSoundBackground.current)
				background.current.pause()
				backgroundPlaying.current = false
			} else {
				if (background.current.volume - 0.01 < 0) {
					background.current.volume = 0
				} else {
					background.current.volume -= 0.01
				}
			}
		}, 50)
	}

	const playAudioAmbientSound = () => {
		const musicLS = localStorage.getItem('MUSIC_IS_ACTIVE')
		if (Number.isNaN(Number(musicLS)) || musicLS === '0') return
		const srcAudio = sceneData.sounds.ambientSound?.file

		try {
			new URL(srcAudio)
		} catch (error) {
			return
		}

		if (ambientSoundPlaying.current) return

		ambientSoundPlaying.current = true
		ambientSound.current = new Audio()
		ambientSound.current.src = srcAudio
		ambientSound.current.loop = true
		ambientSound.current.volume = 0

		ambientSound.current.onloadeddata = () => {
			let promise = ambientSound.current.play()

			const volume = Number.isNaN(Number(musicLS)) ? 1 : Number(musicLS)
			clearInterval(intervalPausaAmbientSound.current)
			clearInterval(intervalAmbientSound.current)

			if (promise !== undefined) {
				promise
					.then((_) => {
						intervalAmbientSound.current = setInterval(() => {
							if (ambientSound.current.volume >= volume) {
								ambientSound.current.volume = volume
								clearInterval(intervalAmbientSound.current)
							} else {
								if (ambientSound.current.volume + 0.05 > 1) {
									ambientSound.current.volume = 1
								} else {
									ambientSound.current.volume += 0.05
								}
							}
						}, 100)
					})
					.catch((err) => console.error(err))
			}
		}
	}

	const pauseAudioAmbientSound = () => {
		if (ambientSound.current == null) return

		clearInterval(intervalAmbientSound.current)
		intervalPausaAmbientSound.current = setInterval(() => {
			if (ambientSound.current.volume === 0) {
				clearInterval(intervalPausaAmbientSound.current)
				ambientSound.current.pause()
				ambientSoundPlaying.current = false
			} else {
				if (ambientSound.current.volume - 0.05 < 0) {
					ambientSound.current.volume = 0
				} else {
					ambientSound.current.volume -= 0.05
				}
			}
		}, 50)
	}

	const soundAction = (thing, ID, action) => {
		// Espera la interacción del usuario para ejecutar el audio de fondo y de ambiente
		const musicLS = localStorage.getItem('MUSIC_IS_ACTIVE')
		if (Number.isNaN(Number(musicLS)) || musicLS !== '0') {
			const effectLS = localStorage.getItem('EFFECTS_IS_ACTIVE')
			if (
				(Number.isNaN(Number(effectLS)) || effectLS !== '0') &&
				ID === 'flying'
			) {
				playAudioBackground()
				playAudioAmbientSound()
			}
		}

		if (thing === 'NPC') {
			const voicesLS = localStorage.getItem('VOICES_IS_ACTIVE')
			if (Number.isNaN(Number(voicesLS)) || voicesLS === '0') return

			const dataAudio = NPCS.current?.[ID]
			// ----- Return if id doesn't exists
			if (!dataAudio) return

			// ----- MAIN ACTION
			if (action === 'play' && !dataAudio.isPlaying) {
				const srcAudio = dataAudio.file
				let audio

				try {
					new URL(srcAudio)
				} catch (error) {
					return
				}

				if (!NPCS.current[ID].audio) {
					audio = new Audio(srcAudio)
					audio.onloadeddata = () => {
						audio.volume = Number.isNaN(Number(voicesLS))
							? 1
							: Number(voicesLS)
						let promise = audio.play()

						if (promise !== undefined) {
							promise
								.then((_) => {})
								.catch((err) => console.error(err))
						}
					}
				} else {
					audio = NPCS.current[ID].audio
					audio.volume = Number.isNaN(Number(voicesLS))
						? 1
						: Number(voicesLS)
					let promise = audio.play()

					if (promise !== undefined) {
						promise
							.then((_) => {})
							.catch((err) => console.error(err))
					}
				}

				audio.onended = () => {
					NPCS.current[ID] = {
						...dataAudio,
						isPlaying: false,
					}
				}

				if (!NPCS.current[ID].audio) {
					NPCS.current[ID] = {
						...dataAudio,
						isPlaying: true,
						audio,
					}
				} else {
					NPCS.current[ID] = {
						...dataAudio,
						isPlaying: true,
					}
				}

				return
			}

			if (action === 'pause') {
				NPCS.current[ID].audio?.pause()

				NPCS.current[ID] = {
					...dataAudio,
					isPlaying: false,
				}
			}
		} else if (thing === 'game') {
			if (Number.isNaN(Number(musicLS)) || musicLS === '0') return

			if (action === 'pausePlayingAudio') {
				Object.keys(games.current).forEach((key) => {
					if (games.current[key]?.audio) {
						games.current[key].audio.pause()
						games.current[key].audio.currentTime = 0
					}
				})
			}

			if (action === 'replayAudio') {
				Object.keys(games.current).forEach((key) => {
					games.current[key]?.audio?.play()
				})
			}

			if (action === 'removeAudio') {
				Object.keys(games.current).forEach((key) => {
					if (games.current[key].audio) {
						games.current[key].audio.pause()
						delete games.current[key].audio
					}
				})
			}

			const dataAudio = games.current?.[ID]
			// ----- Return if id doesn't exists
			if (!dataAudio) return

			// // ----- MAIN ACTION
			if (action === 'play' && !dataAudio.isPlaying) {
				const srcAudio = dataAudio.file
				let audio

				try {
					new URL(srcAudio)
				} catch (error) {
					return
				}

				if (!games.current[ID].audio) {
					audio = new Audio(srcAudio)
					audio.onloadeddata = () => {
						audio.loop = dataAudio.isLoop
						audio.currentTime = 0
						audio.volume = Number.isNaN(Number(musicLS))
							? 1
							: Number(musicLS)
						audio.play()
					}
				} else {
					audio = games.current[ID].audio
					audio.loop = dataAudio.isLoop
					audio.currentTime = 0
					audio.volume = Number.isNaN(Number(musicLS))
						? 1
						: Number(musicLS)
					let promise = audio.play()

					if (promise !== undefined) {
						promise
							.then((_) => {})
							.catch((err) => console.error(err))
					}
				}

				audio.onended = () => {
					games.current[ID] = {
						...dataAudio,
						isPlaying: false,
					}
				}

				if (!games.current[ID].audio) {
					games.current[ID] = {
						...dataAudio,
						isPlaying: true,
						audio,
					}
				} else {
					games.current[ID] = {
						...dataAudio,
						isPlaying: true,
					}
				}

				pauseAudioBackground()
				pauseAudioAmbientSound()
				effects.current['flying']?.audio?.pause()
			}

			if (action === 'pause') {
				games.current[ID].audio?.pause()

				games.current[ID] = {
					...dataAudio,
					isPlaying: false,
				}

				playAudioBackground()
				playAudioAmbientSound()
			}
		} else if (thing === 'effect') {
			const effectLS = localStorage.getItem('EFFECTS_IS_ACTIVE')
			if (Number.isNaN(Number(effectLS)) || effectLS === '0') return

			const dataAudio = effects.current?.[ID]
			// ----- Return if id doesn't exists
			if (!dataAudio) return

			// ----- MAIN ACTION
			if (action === 'play' && !dataAudio.isPlaying) {
				const srcAudio = dataAudio.file
				let audio

				if (!effects.current[ID].audio) {
					audio = new Audio(srcAudio)
					audio.loop = dataAudio.isLoop
					audio.volume = Number.isNaN(Number(effectLS))
						? 1
						: Number(effectLS)
					audio.onloadeddata = () => {
						let promise = audio.play()

						if (promise !== undefined) {
							promise
								.then((_) => {})
								.catch((err) => console.error(err))
						}
					}
				} else {
					audio = effects.current[ID].audio
					audio.volume = Number.isNaN(Number(effectLS))
						? 1
						: Number(effectLS)
					if (dataAudio.isLoop) audio.currentTime = 0
					let promise = audio.play()

					if (promise !== undefined) {
						promise
							.then((_) => {})
							.catch((err) => console.error(err))
					}
				}

				if (ID === 'TLME4' || ID === 'TLME3') {
					if (games.current) {
						Object.keys(games.current).forEach((game) => {
							games.current[game]?.audio?.pause()
						})
					}
				}

				if (!dataAudio.isLoop) {
					audio.onended = () => {
						effects.current[ID] = {
							...dataAudio,
							isPlaying: false,
						}
					}
				}

				if (!effects.current[ID].audio) {
					effects.current[ID] = {
						...dataAudio,
						isPlaying: true,
						audio,
					}
				} else {
					effects.current[ID] = {
						...dataAudio,
						isPlaying: true,
					}
				}
			}

			if (action === 'pause') {
				effects.current[ID].audio?.pause()

				effects.current[ID] = {
					...dataAudio,
					isPlaying: false,
				}
			}
		} else if (thing === 'storyteller') {
			const voicesLS = localStorage.getItem('VOICES_IS_ACTIVE')
			if (Number.isNaN(Number(voicesLS)) || voicesLS === '0') return

			// ----- Return if id doesn't exists
			if (action === 'pause') {
				audioStoryTeller.current.pause()
				return
			}
			if (!storyteller.current?.[ID]) return

			try {
				const srcAudio = storyteller.current[ID].file
				new URL(srcAudio)
				audioStoryTeller.current.src = srcAudio
				audioStoryTeller.current.volume = Number.isNaN(Number(voicesLS))
					? 1
					: Number(voicesLS)
				audioStoryTeller.current.onloadeddata = () => {
					let promise = audioStoryTeller.current.play()

					if (promise !== undefined) {
						promise
							.then((_) => {})
							.catch((err) => console.error(err))
					}
				}
			} catch (error) {
				console.error(error)
				return
			}
		}
	}

	const changeVolumeOfAudios = ({ nameSound, volume }) => {
		volume = Number(volume)
		if (NAME_OF_SOUNDS.MUSICA === nameSound) {
			if (background.current) {
				background.current.volume = volume
				if (volume === 0) {
					clearInterval(intervalSoundBackground.current)
					backgroundPlaying.current = false
				} else backgroundPlaying.current = true
			}

			if (ambientSound.current) {
				ambientSound.current.volume = volume
				if (volume === 0) {
					clearInterval(intervalAmbientSound.current)
					ambientSoundPlaying.current = false
				} else ambientSoundPlaying.current = true
			}

			if (games.current) {
				Object.keys(games.current).forEach((keyOfAudioGame) => {
					if (games.current[keyOfAudioGame].audio)
						games.current[keyOfAudioGame].audio.volume = volume
				})
			}
		} else if (NAME_OF_SOUNDS['EFFECTOS DE SONIDO'] === nameSound) {
			if (effects.current) {
				Object.keys(effects.current).forEach((keyOfAudioGame) => {
					if (effects.current[keyOfAudioGame].audio)
						effects.current[keyOfAudioGame].audio.volume = volume
				})
			}
		} else if (NAME_OF_SOUNDS.VOCES === nameSound) {
			if (NPCS.current) {
				Object.keys(NPCS.current).forEach((keyOfAudioGame) => {
					if (NPCS.current[keyOfAudioGame].audio)
						NPCS.current[keyOfAudioGame].audio.volume = volume
				})
			}
		}
	}

	// Reload the hook for the sounds can stoped and playing
	useEffect(() => {
		if (!loadingIsVisible) setReload((prevState) => !prevState)

		return () => {
			if (loadingIsVisible) {
				pauseAudioBackground()
				pauseAudioAmbientSound()
				effects.current?.['flying']?.audio?.pause()
			}
		}
	}, [soundPointers, loadingIsVisible])

	return [
		soundAction,
		playAudioBackground,
		pauseAudioBackground,
		playAudioAmbientSound,
		pauseAudioAmbientSound,
		changeVolumeOfAudios,
	]
}
