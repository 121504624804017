import { useContext, useEffect, useLayoutEffect, useRef, useState } from 'react'
import { BsFillPencilFill, BsFillTrashFill } from 'react-icons/bs'
import BodyCollapseWorld from './BodyCollapseWorlds'
import { API_MANAGE_WORLDS } from '../../../../utils/constantsAdmin'
import ModalContext from '../../../../context/editor/ModalContext'
import { FormWorlds } from './FormWorlds'
import AlertContext from '../../../../context/editor/AlertContext'
import DeleteContext from '../../../../context/editor/DeleteContext'
import { toggleCollapseHistory } from '../../../../utils/general_functions'
import { ManageStorageWorld } from '../../../../utils/manage_local_storage'
import { FaChevronDown, FaChevronUp } from 'react-icons/fa'
import { useAuthProvider } from '../../../../context/AuthProvider/AuthProvider'
import { useTranslation } from 'react-i18next'

export default function WorldComponent({
	idLevelLanguage,
	objectWorld,
	setRefreshWorlds,
	activeCollapseWorld,
	setActiveCollapseWorld,
}) {
	const { t } = useTranslation()
	// AUTH PERMISSION
	const { gameUser, axiosSupreme } = useAuthProvider()

	// LOCAL STORAGE
	const { getWorld, setWorld } = ManageStorageWorld()

	// SET OBJECT WORLD
	const [stateObjectWorld, setStateObjectWorld] = useState(undefined)
	useEffect(() => {
		setStateObjectWorld(objectWorld)
	}, [objectWorld])

	// ALERT
	const {
		showAlert,
		setMessage: setMessageAlert,
		setTypeMessage,
	} = useContext(AlertContext)

	// MODAL
	const { openModal, setTitleModal, setContentModal } =
		useContext(ModalContext)

	// DELETE
	const { openDelete, setMessage, setFunctionDelete } =
		useContext(DeleteContext)

	// UPDATE WORLD
	const UpdateWorld = async () => {
		setTitleModal(t("pages.editor.components.world_component.update_world"))
		setContentModal(
			<FormWorlds
				idLevelLanguage={idLevelLanguage}
				stateObjectWorld={stateObjectWorld}
				setStateObjectWorld={setStateObjectWorld}
				setRefreshWorlds={setRefreshWorlds}
			/>
		)
		openModal()
	}

	// DELETE WORLD
	const DeleteLevelWorld = async (idLevelLanguage) => {
		const result_data = await axiosSupreme(
			'delete',
			API_MANAGE_WORLDS + stateObjectWorld.id_world + '/',
			undefined
		)
		if (result_data.hasOwnProperty('response')) {
			openDelete()
			setTypeMessage('error')
			setMessageAlert(
				t("common.cannot_delete")
			)
			showAlert()
		} else {
			setRefreshWorlds((prev) => !prev)
			openDelete()
		}
	}

	return (
		<>
			{stateObjectWorld !== undefined ? (
				<div className='adminComponents' id={stateObjectWorld.id_world}>
					<div className='adminComponents__button collapseStylesBtn'>
						{activeCollapseWorld === objectWorld.id_world ? (
							<div className='arrowOpenCollapse'>
								<FaChevronUp />{' '}
							</div>
						) : (
							<div className='arrowOpenCollapse'>
								{' '}
								<FaChevronDown />
							</div>
						)}
						<div
							className='adminComponents__button__title title'
							onClick={() =>
								toggleCollapseHistory(
									activeCollapseWorld,
									setActiveCollapseWorld,
									stateObjectWorld.id_world,
									setWorld
								)
							}>
							<h2>
								{stateObjectWorld.order}.{' '}
								{stateObjectWorld.name}
							</h2>
						</div>
						<div className='adminComponents__button__options options'>
							{gameUser.permissions?.hasOwnProperty(
								'delete_world'
							) ? (
								<button
									className='editorBtnActionDelete'
									onClick={() => {
										setFunctionDelete(
											() => DeleteLevelWorld
										)
										setMessage(
											t("pages.editor.components.world_component.confirm_deleting_1") +
											stateObjectWorld.name +
											'?'
										)
										openDelete()
									}}>
									<p>
										<BsFillTrashFill />{' '}
									</p>
								</button>
							) : null}
							{gameUser.permissions?.hasOwnProperty(
								'change_world'
							) ? (
								<button
									className='editorBtnActionEdit'
									onClick={UpdateWorld}>
									<p>
										<BsFillPencilFill />
									</p>
								</button>
							) : null}
						</div>
					</div>
					{activeCollapseWorld === stateObjectWorld.id_world && (
						<BodyCollapseWorld
							idLevelLanguage={idLevelLanguage}
							stateObjectWorld={
								stateObjectWorld
							}></BodyCollapseWorld>
					)}
				</div>
			) : null}
		</>
	)
}
