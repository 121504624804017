import { useContext, useEffect, useRef, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import ModalContext from '../../../../context/editor/ModalContext'
import { FormSceneSubWorld } from './FormSceneSubWorld'

import { API_MANAGE_SCENE_SUB_WORLDS } from '../../../../utils/constantsAdmin'
import { BsFillPencilFill, BsFillTrashFill } from 'react-icons/bs'
import AlertContext from '../../../../context/editor/AlertContext'
import DeleteContext from '../../../../context/editor/DeleteContext'
import { useAuthProvider } from '../../../../context/AuthProvider/AuthProvider'
import { useTranslation } from 'react-i18next'

export const SceneSubWorld = ({
	idSubWorld,
	objectSceneSubWorld,
	setRefreshSceneSubWorlds,
}) => {
	const { t } = useTranslation()
	// AUTH PERMISSION
	const { gameUser, axiosSupreme } = useAuthProvider()

	// SET OBJECT SCENE
	const audioRef = useRef(null)
	const [stateObjectSceneSubWorld, setStateObjectSceneSubWorld] =
		useState(undefined)
	useEffect(() => {
		setStateObjectSceneSubWorld(objectSceneSubWorld)
	}, [objectSceneSubWorld])

	// NAVIGATE
	const navigate = useNavigate()

	// ALERT
	const {
		showAlert,
		setMessage: setMessageAlert,
		setTypeMessage,
	} = useContext(AlertContext)

	// MODAL
	const { openModal, setTitleModal, setContentModal } =
		useContext(ModalContext)

	// DELETE
	const { openDelete, setMessage, setFunctionDelete } =
		useContext(DeleteContext)

	// UPDATE SCENE
	const UpdateSceneSubWorld = async () => {
		setTitleModal(t("pages.editor.components.scene_sub_world.update_scene_sub_world"))
		setContentModal(
			<FormSceneSubWorld
				idSubWorld={idSubWorld}
				stateObjectSceneSubWorld={stateObjectSceneSubWorld}
				setStateObjectSceneSubWorld={setStateObjectSceneSubWorld}
				setRefreshSceneSubWorlds={setRefreshSceneSubWorlds}
			/>
		)
		openModal()
	}

	// DELETE SCENE
	const DeleteSceneSubWorld = async () => {
		const result_data = await axiosSupreme(
			'delete',
			API_MANAGE_SCENE_SUB_WORLDS +
			stateObjectSceneSubWorld.id_scene_sub_world +
			'/',
			undefined
		)
		if (result_data.hasOwnProperty('response')) {
			openDelete()
			setTypeMessage('error')
			setMessageAlert(
				t("common.cannot_delete")
			)
			showAlert()
		} else {
			setRefreshSceneSubWorlds((prev) => !prev)
			openDelete()
		}
	}

	// OPEN JSON
	const OpenFileColision = () => {
		window.open(stateObjectSceneSubWorld.collision_file, '_blank').focus()
	}

	const reDirectEdit = (action) => {
		localStorage.setItem('TYPE_CONTEXT_SPACE', 'Subworld')
		localStorage.setItem(
			'ID_SCENE',
			stateObjectSceneSubWorld.id_scene_sub_world
		)
		localStorage.setItem('ITEM_DESIGNER', action)
		navigate('/manage-scene-world')
	}

	return (
		<div className='subWorld__component__scene__ContainerSceneSubWorld__ListSceneSubWorld__Item'>
			{stateObjectSceneSubWorld != null && (
				<div className='sceneSubWorlds'>
					<div
						className='sceneSubWorlds__background'
						style={{
							backgroundColor:
								stateObjectSceneSubWorld.background_color,
						}}>
						<a
							href={stateObjectSceneSubWorld.image_file}
							rel='noreferrer noopener'
							target='_blank'
						>
							<img
								src={stateObjectSceneSubWorld.image_file}
								alt={t("pages.editor.components.scene_sub_world.alt_sub_world")}
							/>
						</a>
						<p>{stateObjectSceneSubWorld.background_color}</p>
					</div>
					<div className='sceneSubWorlds__data'>
						<div className='sceneSubWorlds__data__name'>
							<div className='sceneSubWorlds__data__name__title'>
								<h2 className=''>
									{stateObjectSceneSubWorld.name}
								</h2>
							</div>
							<div className='sceneSubWorlds__data__name__options'>
								{gameUser.permissions?.hasOwnProperty(
									'delete_sub_world_scene'
								) ? (
									<button
										className='editorBtnActionDelete'
										onClick={() => {
											setFunctionDelete(
												() => DeleteSceneSubWorld
											)
											setMessage(
												t("pages.editor.components.scene_sub_world.confirm_deleting_1") +
												stateObjectSceneSubWorld.name +
												'?'
											)
											openDelete()
										}}>
										<p>
											<BsFillTrashFill />{' '}
										</p>
									</button>
								) : null}
								{gameUser.permissions?.hasOwnProperty(
									'change_sub_world_scene'
								) ? (
									<button
										className='editorBtnActionEdit'
										onClick={UpdateSceneSubWorld}>
										<p>
											<BsFillPencilFill />
										</p>
									</button>
								) : null}
							</div>
						</div>

						<div className='sceneSubWorlds__data__soundandcollision'>
							<div className='sceneSubWorlds__data__soundandcollision__sound'>
								<div className='sceneSubWorlds__data__soundandcollision__sound__title'>
									<h2 className=''>{t("pages.editor.components.scene_sub_world.sound")}</h2>
								</div>
								<div className='sceneSubWorlds__data__soundandcollision__sound__player'>
									{stateObjectSceneSubWorld.sound ? (
										<audio
											ref={audioRef}
											controls
											src={
												stateObjectSceneSubWorld.sound
													.audio_file
											}
										/>
									) : (
										<p>{t("pages.editor.components.scene_sub_world.none")}</p>
									)}
								</div>
							</div>

							<div className='sceneSubWorlds__data__soundandcollision__sound'>
								<div className='sceneSubWorlds__data__soundandcollision__sound__title'>
									<h2 className=''>{t("pages.editor.components.scene_sub_world.ambient_sound")}</h2>
								</div>
								<div className='sceneSubWorlds__data__soundandcollision__sound__player'>
									{stateObjectSceneSubWorld.ambient_sound ? (
										<audio
											ref={audioRef}
											controls
											src={
												stateObjectSceneSubWorld
													.ambient_sound.audio_file
											}
										/>
									) : (
										<p>{t("pages.editor.components.scene_sub_world.none")}</p>
									)}
								</div>
							</div>

							<div className='sceneSubWorlds__data__soundandcollision__collision'>
								<div className='sceneSubWorlds__data__soundandcollision__collision__title'>
									<h2 className=''>{t("pages.editor.components.scene_sub_world.collition_file")}</h2>
								</div>
								<div className='sceneSubWorlds__data__soundandcollision__collision__collisionfile'>
									{stateObjectSceneSubWorld.collision_file ? (
										<button
											className='editorBtnActionAdd'
											onClick={OpenFileColision}>
											{t("common.open")}
										</button>
									) : (
										<p>{t("pages.editor.components.scene_sub_world.none")}</p>
									)}
								</div>
							</div>
						</div>

						<div className='sceneSubWorlds__data__edition'>
							<div className='sceneSubWorlds__data__edition__title'>
								<h2 className=''>{t("pages.editor.components.scene_sub_world.edit")}</h2>
							</div>
							<div className='sceneSubWorlds__data__edition__contentButtons'>
								{gameUser.permissions?.hasOwnProperty(
									'view_sub_world_scene_decoration'
								) ? (
									<button
										className='editorBtnActionAdd'
										onClick={() =>
											reDirectEdit('Decorations')
										}>
										{t("pages.editor.components.scene_sub_world.decorations")}
									</button>
								) : null}
								{gameUser.permissions?.hasOwnProperty(
									'view_storyteller'
								) ? (
									<button
										className='editorBtnActionAdd'
										onClick={() =>
											reDirectEdit('StoryTeller')
										}>
										{t("pages.editor.components.scene_sub_world.teller")}
									</button>
								) : null}
								{gameUser.permissions?.hasOwnProperty(
									'view_sub_world_door'
								) ? (
									<button
										className='editorBtnActionAdd'
										onClick={() => reDirectEdit('Doors')}>
										{t("pages.editor.components.scene_sub_world.doors")}
									</button>
								) : null}
								{gameUser.permissions?.hasOwnProperty(
									'view_sub_world_scene_npc_appearance'
								) ? (
									<button
										className='editorBtnActionAdd'
										onClick={() => reDirectEdit('Npcs')}>
										{t("pages.editor.components.scene_sub_world.npcs")}
									</button>
								) : null}
							</div>
						</div>
					</div>
				</div>
			)}
		</div>
	)
}
