import { createContext, useContext, useEffect, useState } from 'react'
import { API_LOST_ITEMS } from '../utils/constants'
import { useAuthProvider } from './AuthProvider/AuthProvider'

const LostItemsContext = createContext(null)

const useLostItems = () => {
	return useContext(LostItemsContext)
}

const LostItemsProvider = ({ children }) => {
	const { gameUser, axiosSupreme } = useAuthProvider()

	const [lostItemsData, setLostItemsData] = useState(null)
	const [
		lostItemsThereIsAnUnseenInteraction,
		setLostItemsThereIsAnUnseenInteraction,
	] = useState(false)

	const updateLostItemsData = async (profileDataID, initMapCheck) => {
		let lastLength = lostItemsData ? lostItemsData.length : 0

		try {
			const lostItemsRequest = await axiosSupreme(
				'get',
				API_LOST_ITEMS + profileDataID
			)

			setLostItemsData(lostItemsRequest)

			// AT THE BEGINING OF EACH MAP
			if (initMapCheck) {
				// IF THERE ARE MORE, THEN THIS MAP HAS A LOST ITEM NPC OR THERE WERE ALREADY LOST ITEMS
				// THE FIRST TIME EVER THE USER OPENED THE GAME
				if (lostItemsRequest.length > lastLength)
					setLostItemsThereIsAnUnseenInteraction(true)
			}
		} catch (error) {
			console.error(error)
			setTimeout(async () => {
				await updateLostItemsData(profileDataID)
			}, 1500)
		}
	}

	return (
		<LostItemsContext.Provider
			value={{
				lostItemsData,
				updateLostItemsData,
				lostItemsThereIsAnUnseenInteraction,
				setLostItemsThereIsAnUnseenInteraction,
			}}>
			{children}
		</LostItemsContext.Provider>
	)
}

export default LostItemsProvider
export { useLostItems }
