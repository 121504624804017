import React from 'react'
import { useTranslation } from 'react-i18next'

export const ChallengeBodyCollapse = ({ stateObjectChallenge }) => {
	const { t } = useTranslation()
	return (
		<div className='challengeContent collapseStylesBody'>
			{stateObjectChallenge !== undefined ? (
				<>
					<div className='challengeContent__container'>
						<div className='challengeContent__container__data'>
							<div className='info'>
								<h1 className='titleDesc'>{t("pages.editor.components.challenge_body_collapse.category")}</h1>
								<p>{stateObjectChallenge.category}</p>
							</div>
							<div className='info'>
								<h1 className='titleDesc'>{t("pages.editor.components.challenge_body_collapse.reward")}</h1>
								<p>{stateObjectChallenge.reward}</p>
							</div>
							<div className='info'>
								<h1 className='titleDesc'>{t("pages.editor.components.challenge_body_collapse.stars")}</h1>
								<p>{stateObjectChallenge.stars}</p>
							</div>
							<div className='info'>
								<h1 className='titleDesc'>{t("pages.editor.components.challenge_body_collapse.experience")}</h1>
								<p>{stateObjectChallenge.xp}</p>
							</div>
						</div>
					</div>
				</>
			) : null}
		</div>
	)
}
