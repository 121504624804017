import '../../styles/SocialMediaBar.scss'
import IconButton from '../shared/IconButton/IconButton'

import instagramIcon from '../../assets/icons/socialMediaFacebook.png'
import facebookIcon from '../../assets/icons/socialMediaInstagram.png'
import xIcon from '../../assets/icons/socialMediaX.png'

const SocialMeidaBar = ({ style }) => {
	return (
		<div className='social__media__bar' style={{ ...style }}>
			<IconButton src={instagramIcon} size={'36px'} />
			<IconButton src={facebookIcon} size={'36px'} />
			<IconButton src={xIcon} size={'36px'} />
		</div>
	)
}

export default SocialMeidaBar
