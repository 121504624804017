import { useNavigate } from 'react-router-dom'
import { SceneSwitcher } from '../components/SceneSwitcher'
import useAuthNavigator from '../context/AuthProvider/useAuthNavigator'

import myCookies from '../utils/myCookies'

const Game = () => {
	return useAuthNavigator(<PrivatePage />)
}

function PrivatePage(props) {
	const navigate = useNavigate()
	if (!myCookies.get.gameLanguageID()) navigate('/')

	return <SceneSwitcher />
}
export default Game
