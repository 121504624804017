import { ListNpcCompose } from '../../NpcCompose/ListNpcCompose'
import { useAuthProvider } from '../../../../../context/AuthProvider/AuthProvider'
import { useTranslation } from 'react-i18next'

export const NpcsBodyCollapse = ({ stateObjectNpc }) => {
	const { t } = useTranslation()
	// AUTH PERMISSION
	const { gameUser } = useAuthProvider()

	return (
		<div className='npcContent collapseStylesBody'>
			<div className='npcContent__container'>
				<div className='npcContent__container__Left'>
					{stateObjectNpc.silence_file ? (
						<a
							href={stateObjectNpc.silence_file}
							rel='noreferrer noopener'
							target='_blank'>
							<img src={stateObjectNpc.silence_file} alt={t("pages.editor.components.npcs_body_collapse.alt_npc_img")} loading='lazy' />
						</a>
					) : (
						<p className='noSelect'>
							{t("pages.editor.components.npcs_body_collapse.none_images_selected")}
						</p>
					)}
				</div>
				<div className='npcContent__container__Right'>
					<h2 className='titleDesc'>{t("pages.editor.components.npcs_body_collapse.description")}</h2>
					<p>{stateObjectNpc.description}</p>
				</div>

				{gameUser.permissions?.hasOwnProperty('view_npc_compose') && (
					<ListNpcCompose idNpc={stateObjectNpc.id_npc} />
				)}
			</div>
		</div>
	)
}
