import { Alert } from '../../../components/editor/Alert'
import { Navigate } from 'react-router-dom'
import { useAuthProvider } from '../../../context/AuthProvider/AuthProvider'
import Modal from '../../../components/editor/Modal'
import TitleAdministratorAndMenu from '../../../components/editor/TitleAdministratorAndMenu'
import '../../../styles/AdminDesign.scss'
import { BackButton } from '../../../components/editor/BackButton'
import ModalDelete from '../../../components/editor/ModalDelete'
import { ListAnimations } from '../../../components/editor/design/Animations/ListAnimations'
import { ErrorPerms } from '../../../components/editor/ErrorPerms'
import useAuthNavigator from '../../../context/AuthProvider/useAuthNavigator'

export default function ManageAnimations() {
	return useAuthNavigator(<PrivatePage />)
}

function PrivatePage() {
	// AUTH PERMISSION
	const { gameUser, axiosSupreme } = useAuthProvider()

	return (
		<>
			<Alert></Alert>
			<Modal></Modal>
			<ModalDelete></ModalDelete>
			<BackButton url={'/manage-scene-world'}></BackButton>
			<div className='animations'>
				<div className='animations__container'>
					<TitleAdministratorAndMenu name='ANIMACIONES' />
					{gameUser.permissions?.hasOwnProperty(
						'view_designer_administration'
					) &&
					gameUser.permissions?.hasOwnProperty('view_animation') ? (
						<div className='adminD'>
							<div className='adminDesign'>
								<div className='adminDesign__container ExtendManageExternal'>
									<div className='adminDesign__container__mainContainerBank'>
										<div className='adminDesign__container__mainContainerBank__Item'>
											<div className='adminDesign__container__mainContainerBank__Item__content'>
												<ListAnimations></ListAnimations>
											</div>
										</div>
									</div>
								</div>
							</div>
						</div>
					) : (
						<ErrorPerms />
					)}
				</div>
			</div>
		</>
	)
}
