import { useRef, useState } from 'react'
import '../../styles/Storyteller.scss'

import { API_STORYTELLER_SEEN } from '../../utils/constants'

import narratorNormalImageAvif from '../../assets/images/Storyteller/normal.avif'
import narratorNormalImage from '../../assets/images/Storyteller/normal.png'
import narratorHappyImageAvif from '../../assets/images/Storyteller/happy.avif'
import narratorHappyImage from '../../assets/images/Storyteller/happy.png'
import narratorSurpricedImageAvif from '../../assets/images/Storyteller/surpriced.avif'
import narratorSurpricedImage from '../../assets/images/Storyteller/surpriced.png'
import narratorSadImageAvif from '../../assets/images/Storyteller/sad.avif'
import narratorSadImage from '../../assets/images/Storyteller/sad.png'
import narratorParrotNormalAvif from '../../assets/images/Storyteller/teoloro_thats_right.avif'
import narratorParrotNormal from '../../assets/images/Storyteller/teoloro_thats_right.png'
import narratorParrotHappyAvif from '../../assets/images/Storyteller/teoloro-well-done.avif'
import narratorParrotHappy from '../../assets/images/Storyteller/teoloro-well-done.png'
import narratorParrotSurpricedAvif from '../../assets/images/Storyteller/teoloro_good.avif'
import narratorParrotSurpriced from '../../assets/images/Storyteller/teoloro_good.png'
import narratorParrotSadAvif from '../../assets/images/Storyteller/teoloro_you_lose.avif'
import narratorParrotSad from '../../assets/images/Storyteller/teoloro_you_lose.png'
import { useAuthProvider } from '../../context/AuthProvider/AuthProvider'
import { useSoundAction } from '../../hooks/useSoundAction'

const narratorImagesPaivi = {
	normal: {
		img: narratorNormalImage,
		imgAvif: narratorNormalImageAvif,
	},
	felicidad: {
		img: narratorHappyImage,
		imgAvif: narratorHappyImageAvif,
	},
	sorpresa: {
		img: narratorSurpricedImage,
		imgAvif: narratorSurpricedImageAvif,
	},
	tristeza: {
		img: narratorSadImage,
		imgAvif: narratorSadImageAvif,
	},
}

const narratorImagesParrot = {
	normal: {
		img: narratorParrotNormal,
		imgAvif: narratorParrotNormalAvif,
	},
	felicidad: {
		img: narratorParrotHappy,
		imgAvif: narratorParrotHappyAvif,
	},
	sorpresa: {
		img: narratorParrotSurpriced,
		imgAvif: narratorParrotSurpricedAvif,
	},
	tristeza: {
		img: narratorParrotSad,
		imgAvif: narratorParrotSadAvif,
	},
}

const selectNarratorImages = {
	n: 'n',
	l: 'l',
}

const storytellerVisuals = {
	normal: 'storyteller',
	close: 'storyteller storyteller__animations__close',
}
export default function Storyteller({ storytellerData, profileData, close }) {
	const { soundAction } = useSoundAction()
	const { axiosSupreme } = useAuthProvider()
	const [narratorImage, setNarratorImage] = useState(() => {
		if (!storytellerData[0]) return null

		if (storytellerData[0].type_character === selectNarratorImages.n) {
			return narratorImagesPaivi[storytellerData[0].emotion]
		} else {
			return narratorImagesParrot[storytellerData[0].emotion]
		}
	})
	const [visual, setVisual] = useState(storytellerVisuals.normal)

	if (narratorImage == null) return <></>

	async function markAsSeen() {
		try {
			await axiosSupreme('post', API_STORYTELLER_SEEN, {
				seen: true,
				storyteller: storytellerData[0].storyteller,
				profile: profileData.id_profile,
			})
		} catch (error) {
			console.error('STORYTELLER FETCH ERROR: ', error)
			markAsSeen()
		}
	}

	function onClose() {
		soundAction('effect', 'TLSG10', 'play')
		soundAction('storyteller', null, 'pause')
		setVisual(storytellerVisuals.close)
		markAsSeen()

		const timeout = setTimeout(() => {
			clearTimeout(timeout)

			close()
		}, 250)
	}

	return (
		<div
			className={visual}
			onClick={(e) => {
				e.stopPropagation()
			}}>
			<Character image={narratorImage} />
			<Card
				storytellerData={storytellerData}
				setNarratorImage={setNarratorImage}
				close={onClose}
			/>
		</div>
	)
}

const Character = ({ image }) => {
	return (
		<div className='storyteller__space storyteller__space__back'>
			<div className='storyteller__space__back__border__left'></div>
			<picture className='storyteller__space__back__main'>
				<source srcSet={image.imgAvif} type='image/avif' />
				<img
					src={image.img}
					alt='narrator character'
					loading='eager'
					decoding='async'
				/>
			</picture>
			<div className='storyteller__space__back__border__right'></div>
		</div>
	)
}

const dynamicTextVisuals = {
	telling: 'storyteller__card__content__text',
	waiting: 'storyteller__card__content__tsoundActionbuext',
	transitioning:
		'storyteller__card__content__text storyteller__card__content__text__animations__fade',
}
const Card = ({ storytellerData, setNarratorImage, close }) => {
	const { soundAction } = useSoundAction()
	const [state, setState] = useState('telling') // waiting, transitioning
	const actualIndex = useRef(-1)
	const actualText = useRef(storytellerData[0].text)
	const dynamicTextLenght = useRef(0)
	const [dynamicText, setDynamicText] = useState('')
	const tellingIntervalRef = useRef(setInterval(() => {}, 0))
	const tellingSpeed = 50 // ms
	const dynamicTextVisual = dynamicTextVisuals[state]

	function startTelling() {
		setState('telling')
		actualIndex.current += 1
		actualText.current = storytellerData[actualIndex.current].text
		setDynamicText('')
		setNarratorImage(() => {
			if (
				storytellerData[actualIndex.current].type_character ===
				selectNarratorImages.n
			) {
				return narratorImagesPaivi[
					storytellerData[actualIndex.current].emotion
				]
			} else {
				return narratorImagesParrot[
					storytellerData[actualIndex.current].emotion
				]
			}
		})

		soundAction(
			'storyteller',
			storytellerData[actualIndex.current].id_storyteller_content,
			'play'
		)

		clearInterval(tellingIntervalRef.current)
		tellingIntervalRef.current = setInterval(() => {
			dynamicTextLenght.current = dynamicTextLenght.current + 1
			if (dynamicTextLenght.current < actualText.current.length) {
				setDynamicText(
					actualText.current.substring(0, dynamicTextLenght.current)
				)
			} else {
				endTelling()
			}
		}, tellingSpeed)
	}

	function endTelling() {
		setState('waiting')
		clearInterval(tellingIntervalRef.current)
		setDynamicText(actualText.current)
	}

	function startTransition() {
		setState('transitioning')

		// ----- Wait till the animation finishes
		const timeout = setTimeout(() => {
			clearTimeout(timeout)
			dynamicTextLenght.current = 0

			startTelling()
		}, 700)
	}

	function onContinue() {
		soundAction('effect', 'TLSG8', 'play')
		if (state === 'telling') {
			endTelling()
		} else if (state === 'waiting') {
			if (actualIndex.current < storytellerData.length - 1) {
				startTransition()
			} else {
				close()
			}
		}
	}

	const once = useRef(false)
	if (!once.current) {
		once.current = true

		const timeout = setTimeout(() => {
			clearTimeout(timeout)
			startTelling()
		}, 1000)
	}

	const textOfStoryteller =
		actualIndex > -1
			? storytellerData[actualIndex.current].type_character
			: storytellerData[0].type_character

	return (
		<div className='storyteller__space storyteller__space__front'>
			<div className='storyteller__space__front__border__left'></div>
			<div className='storyteller__space__front__main'>
				<div className='storyteller__card'>
					<div className='storyteller__card__bar'>
						<div>
							{textOfStoryteller === 'n'
								? 'Narradora'
								: 'Teoloro'}
						</div>
						<button onClick={onContinue}>
							<div>Continuar</div>
						</button>
						<button onClick={close}>
							<div>Skip ►►</div>
						</button>
					</div>
					<div className='storyteller__card__content'>
						<div className={dynamicTextVisual}>{dynamicText}</div>
					</div>
				</div>
			</div>
			<div className='storyteller__space__front__border__right'></div>
		</div>
	)
}
