import { useEffect, useRef } from 'react'

import '../../styles/SessionClosed.scss'
import { ACCOUNT_FRONTEND } from '../../urls'

const SessionClosedPage = () => {
	const dialogueRef = useRef(null)

	useEffect(() => {
		const theDialogue = dialogueRef.current
		if (theDialogue) theDialogue.showModal()
		return () => {
			if (theDialogue) theDialogue.close()
		}
	}, [])

	return (
		<dialog className='sessionClosed' ref={dialogueRef}>
			<div className='sessionClosedContainer'>
				<div className='sessionClosedContainerCard'>
					<h2>FIN DE LA SESSIÓN</h2>
					<p>
						Una vez te hayas logueado vuelve a esta página y
						recargala para seguir disfrutando de tu contenido.
					</p>
					<a
						href={ACCOUNT_FRONTEND}
						target='_black'
						rel='noreferrer noopener'>
						<div>LOGIN</div>
					</a>
				</div>
			</div>
		</dialog>
	)
}

export default SessionClosedPage
