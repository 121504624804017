import { useState } from 'react'
import { ListNpcOutfit } from './NPCOutfit/ListNpcOutfit'
import { ListNpcPoses } from './NPCPoses/ListNpcPoses'
import { ListNpcState } from './NPCState/ListNpcState'
import { FaChevronDown, FaChevronUp } from 'react-icons/fa'
import { useAuthProvider } from '../../../../context/AuthProvider/AuthProvider'
import { useTranslation } from 'react-i18next'

export const NpcStateCollapse = () => {
	const { t } = useTranslation()
	// AUTH PERMISSION
	const { gameUser, axiosSupreme } = useAuthProvider()

	// COLLAPSE
	const [isCollapsed, setIsCollapsed] = useState(true)
	const toggleCollapse = () => {
		setIsCollapsed(!isCollapsed)
	}

	// NAV TAB
	const [activeTab, setActiveTab] = useState(
		gameUser.permissions?.hasOwnProperty('view_npc_outfit')
			? 'outfits'
			: gameUser.permissions?.hasOwnProperty('view_npc_pose')
				? 'poses'
				: gameUser.permissions?.hasOwnProperty('view_npc_state')
					? 'state'
					: ''
	)
	const handleTabClick = (tabName) => {
		setActiveTab(tabName)
	}

	return (
		<div className='collapseNpc '>
			{gameUser.permissions?.hasOwnProperty('view_npc_outfit') ||
				gameUser.permissions?.hasOwnProperty('view_npc_pose') ||
				gameUser.permissions?.hasOwnProperty('view_npc_state') ? (
				<>
					<div className='collapseNpc__button collapseStylesBtn'>
						{!isCollapsed ? (
							<div className='arrowOpenCollapse'>
								<FaChevronUp />{' '}
							</div>
						) : (
							<div className='arrowOpenCollapse'>
								{' '}
								<FaChevronDown />
							</div>
						)}
						<div
							className='collapseNpc__button__title'
							onClick={toggleCollapse}>
							<h2>{t("pages.editor.components.npc_state_collapse.characteristics_for_npcs")}</h2>
						</div>
					</div>
					{!isCollapsed && (
						<div className='stateNpcContent collapseStylesBody'>
							<div className='stateNpcContent__container'>
								<div className='contentNpcs'>
									<div className='contentNpcs__container'>
										<nav>
											<ul>
												{gameUser.permissions?.hasOwnProperty(
													'view_npc_outfit'
												) ? (
													<li
														className={`nav-item ${activeTab ===
															'outfits'
															? 'active'
															: ''
															}`}
														onClick={() =>
															handleTabClick(
																'outfits'
															)
														}>
														<a className='nav-link'>
															{t("pages.editor.components.npc_state_collapse.suits")}
														</a>
													</li>
												) : null}
												{gameUser.permissions?.hasOwnProperty(
													'view_npc_pose'
												) ? (
													<li
														className={`nav-item ${activeTab ===
															'poses'
															? 'active'
															: ''
															}`}
														onClick={() =>
															handleTabClick(
																'poses'
															)
														}>
														<a className='nav-link'>
															{t("pages.editor.components.npc_state_collapse.poses")}
														</a>
													</li>
												) : null}
												{gameUser.permissions?.hasOwnProperty(
													'view_npc_state'
												) ? (
													<li
														className={`nav-item ${activeTab ===
															'state'
															? 'active'
															: ''
															}`}
														onClick={() =>
															handleTabClick(
																'state'
															)
														}>
														<a className='nav-link'>
															{t("pages.editor.components.npc_state_collapse.state")}
														</a>
													</li>
												) : null}
											</ul>
										</nav>
										<div>
											{activeTab === 'outfits' &&
												gameUser.permissions?.hasOwnProperty(
													'view_npc_outfit'
												) ? (
												<ListNpcOutfit />
											) : null}
											{activeTab === 'poses' &&
												gameUser.permissions?.hasOwnProperty(
													'view_npc_pose'
												) ? (
												<ListNpcPoses />
											) : null}
											{activeTab === 'state' &&
												gameUser.permissions?.hasOwnProperty(
													'view_npc_state'
												) ? (
												<ListNpcState />
											) : null}
										</div>
									</div>
								</div>
							</div>
						</div>
					)}
				</>
			) : null}
		</div>
	)
}
