import { useRef } from 'react'
import { useSceneConfig } from '../../../context/SceneConfigProvider'
import { AudioInWordSearch } from './AudioInWordSearch'

export function Words({ audiosOfWords, wordsFound }) {
	const { soundPointers } = useSceneConfig()
	const audio = useRef(new Audio())

	const playAudio = ({ src }) => {
		const { volume } = soundPointers.voices
		if (volume === '0') return

		try {
			new URL(src)
			audio.current.src = src
			audio.current.onloadeddata = () => {
				audio.current.volume = Number.isNaN(Number(volume))
					? 1
					: Number(volume)
				audio.current.play()
			}
		} catch (error) {}
	}

	const wordsData = Array.from(audiosOfWords.keys())

	return (
		<div className='playground__word__search__words'>
			{wordsData.map((soupWord, soupWordIndex) => {
				let audio = audiosOfWords.get(soupWord)
				let includesWord = wordsFound.includes(soupWord.toLowerCase())
				const reverseSoupWord = soupWord.split('').reverse().join('')

				if (!audio) {
					audio = audiosOfWords.get(reverseSoupWord)
				}

				if (!includesWord) {
					includesWord = wordsFound.includes(
						reverseSoupWord.toLowerCase()
					)
				}

				return (
					<div
						key={soupWordIndex}
						className='playground__word__search__words__element'>
						<AudioInWordSearch
							src={audio}
							style={{ position: 'relative' }}
							playAudio={playAudio}
							soupWord={soupWord}
						/>
						<span
							className={`
								playground__word__search__words__element__text
								${
									includesWord
										? 'playground__word__search__words__element__text__completed'
										: ''
								}
							`}>
							{soupWord.toLowerCase()}
						</span>
					</div>
				)
			})}
		</div>
	)
}
