import React, { useContext, useEffect, useState } from 'react'
import { BsFillPencilFill, BsFillTrashFill } from 'react-icons/bs'

import { UserForm } from './UserForm'
import ModalContext from '../../../../context/editor/ModalContext'
import AlertContext from '../../../../context/editor/AlertContext'
import DeleteContext from '../../../../context/editor/DeleteContext'
import { API_MANAGE_USERS_ADMIN } from '../../../../utils/constantsAdmin'
import { useAuthProvider } from '../../../../context/AuthProvider/AuthProvider'

export const UserContent = ({ objUser, setRefreshUsers }) => {
	// AUTH PERMISSION
	const { gameUser, axiosSupreme } = useAuthProvider()

	// SET OBJECT USER
	const [stateObjectUser, setStateObjectUser] = useState(undefined)
	useEffect(() => {
		setStateObjectUser(objUser)
	}, [objUser])
	// ALERT
	const {
		showAlert,
		setMessage: setMessageAlert,
		setTypeMessage,
	} = useContext(AlertContext)

	// MODAL
	const { openModal, setTitleModal, setContentModal } =
		useContext(ModalContext)

	// DELETE
	const { openDelete, setMessage, setFunctionDelete } =
		useContext(DeleteContext)

	// UPDATE USER
	const UpdateUser = async () => {
		const data_result = await axiosSupreme(
			'get',
			API_MANAGE_USERS_ADMIN + stateObjectUser.id + '/',
			undefined
		)
		setTitleModal('Actualizar Usuario')
		setContentModal(
			<UserForm
				stateObjectUser={stateObjectUser}
				setStateObjectUser={setStateObjectUser}
				setRefreshUsers={setRefreshUsers}
			/>
		)
		openModal()
	}

	// DELETE USER
	const DeleteUser = async () => {
		const result_data = await axiosSupreme(
			'delete',
			API_MANAGE_USERS_ADMIN + stateObjectUser.id + '/',
			undefined
		)
		if (result_data.hasOwnProperty('response')) {
			openDelete()
			setTypeMessage('error')
			setMessageAlert(
				'No se ha podido eliminar, existe llave foranea asociada.'
			)
			showAlert()
		} else {
			setRefreshUsers((prev) => !prev)
			openDelete()
		}
	}
	return (
		<>
			{stateObjectUser !== undefined ? (
				<tr>
					<td>{stateObjectUser.username}</td>
					<td>{stateObjectUser.name}</td>
					<td>{stateObjectUser.last_name}</td>
					<td>{stateObjectUser.email}</td>
					<td>{stateObjectUser.country}</td>
					{gameUser.permissions?.hasOwnProperty('change_user') ||
					gameUser.permissions?.hasOwnProperty('delete_user') ? (
						<td>
							{gameUser.permissions?.hasOwnProperty(
								'delete_user'
							) ? (
								<button
									className='btnDelete'
									onClick={() => {
										setFunctionDelete(() => DeleteUser)
										setMessage(
											'¿Está seguro de que desea eliminar: ' +
												stateObjectUser.name +
												'?'
										)
										openDelete()
									}}>
									<p>
										<BsFillTrashFill />
									</p>
								</button>
							) : null}
							{gameUser.permissions?.hasOwnProperty(
								'change_user'
							) ? (
								<button
									className='btnEdit'
									onClick={UpdateUser}>
									<p>
										<BsFillPencilFill />
									</p>
								</button>
							) : null}
						</td>
					) : null}
				</tr>
			) : null}
		</>
	)
}
