import { ACCOUNT_BACKEND, BASE_API } from '../urls'

// AUTH
// export const GAME_LANGUAGE = '6ac9930f-aa3f-48b4-8d59-2b1164db18cd'
export const GAME_LANGUAGES = BASE_API + '/profile_client/profile_language/'
export const API_AUTH_LOGOUT = ACCOUNT_BACKEND + '/users/auth/logout/'
export const USER_API = BASE_API + '/users/auth/me/'
export const ACCESS_TOKEN_API = ACCOUNT_BACKEND + '/users/auth/token/refresh/' // obj{refresh: 'refresh'}

// ----- PROFILE
export const URL_UPDATE_PROFILE =
	BASE_API + '/profile_client/profile_update_door/' // + {id_profile}
export const URL_PROFILE_INITIAL = BASE_API + '/profile_client/profile/' // + {id_profile}
export const API_PROFILE_CLOSE = '/get_profile/'

// GAME
// ----- PROGRESS BAR
export const API_PROGRESS_BAR = BASE_API + '/profile_client/profile/' // + {id_profile}
export const API_PROGRESS_BAR_CLOSE = '/get_mission_profile/'
export const API_UPDATE_MISSION =
	BASE_API + '/mission_client/mission_profile_complete/' // + <id_mission>/<id_profile>/

// ----- DOORS
//export const API_DOOR_DESTINATION = BASE_API + '/world/world_door_destination/?search='; // + id_door_worldhttps:
export const API_WORLD_DOOR_DESTINATION =
	BASE_API + '/world_client/get_destination_world_door/' // + id_door_world
export const API_SUB_WORLD_DOOR_DESTINATION =
	BASE_API + '/world_client/get_destination_sub_world_door/' // + id_door_world + id_profile

// ----- SCENE
export const API_WORLD_SCENE = BASE_API + '/world_client/world_scene/' // + {id_scene_world}
export const API_WORLD_SCENE_CLOSE = '/world_scene_all/' // {id_profile}/{id_game_languaje}

export const API_SUB_WORLD_SCENE = BASE_API + '/world_client/sub_world_scene/' // + {id_scene_world}
export const API_SUB_WORLD_SCENE_CLOSE = '/sub_world_scene_all/' // {id_profile}/{id_game_languaje}

export const API_LEVEL_RESUME = BASE_API + '/profile_client/profile/' // + id_profile
export const API_LEVEL_RESUME_CLOSE = '/the_last_sub_world_scene/' // + id_sub_world

// ----- STORE & EQUIPMENT
export const API_EQUIPMENT = '/store_client/purchased_profile_skin/' // + profileID
export const API_STORE = '/store_client/unpurchased_profile_skin/' // + profileID

export const API_STORE_BUY = '/store_client/buy_item_profile/'
// export const API_STORE_BUY = '/store/store/item/' // + itemID
// export const API_STORE_BUY_CLOSE = '/buy_item/' // + profileID

export const API_EQUIPMENT_SELECT = '/store_client/select_skin/' // + itemID

// ----- LOST ITEMS
export const API_LOST_ITEM_CATCHED =
	BASE_API + '/mission_client/mission_profile_object_catched/'
export const API_LOST_ITEMS =
	BASE_API + '/mission_client/get_mission_profile_lost_items/' // id_profile

// ----- STORYTELLER
export const API_STORYTELLER_SEEN =
	BASE_API + '/profile_client/storyteller_profile/'

// PROFILE IMAGES
export const LIST_IMAGE_PROFILE_NPC =
	BASE_API + '/npc_client/list_image_profile_npc/'
