import { useContext, useState } from 'react'
import AlertContext from '../../../context/editor/AlertContext'
import '../../../styles/Modal.scss'
import { useAuthProvider } from '../../../context/AuthProvider/AuthProvider'
import { useTranslation } from 'react-i18next'

export const ManageFile = ({
	pathFile,
	attribute,
	url,
	getNewObject,
	setStateObject,
}) => {
	const { t } = useTranslation()
	const { gameUser, axiosSupreme } = useAuthProvider()
	const [visible, setVisible] = useState(true)

	// ALERT
	const { showAlert, setMessage, setTypeMessage } = useContext(AlertContext)

	// REMOVE FILE
	const removeFile = async () => {
		try{
			let formData = new FormData()
			formData.append(attribute, '')
			const result_data = await axiosSupreme('patch', url, formData)
			if (result_data.hasOwnProperty('response')) {
				setTypeMessage('error')
				setMessage(result_data.response.data)
				showAlert()
			} else {
				getNewObject !== undefined
					? getNewObject()
					: setStateObject(result_data)
				setVisible(false)
			}
		}catch(e){
			removeFile()
		}
	}

	return (
		<>
			{visible ? (
				<label htmlFor=''>
					{t("common.current_file")}
					<a
						href={pathFile}
						target='_blank'
						rel='noopener noreferrer'>
						{' '}
						{t("common.open")}
					</a>
					&nbsp;
					<a
						className='delFile'
						rel='noreferrer'
						onClick={removeFile}>
						{' '}
						{t("common.remove")}
					</a>
				</label>
			) : null}
		</>
	)
}
