import { useTranslation } from 'react-i18next'
import { useAuthProvider } from '../../../../context/AuthProvider/AuthProvider'
import { ListStoryTellerContent } from '../StoryTellerContent/ListStoryTellerContent'

export const BodyCollapseStoryTeller = ({ stateObjectStoryTeller }) => {
	const { t } = useTranslation()
	// AUTH PERMISSION
	const { gameUser, axiosSupreme } = useAuthProvider()

	return (
		<div className='storyTellerContent collapseStylesBody'>
			{stateObjectStoryTeller !== undefined ? (
				<>
					<div className='storyTellerContent__container'>
						<div className='storyTellerContent__container__data'>
							<div className='info'>
								<h1>{t("pages.editor.components.body_collapse_storyteller.title")}</h1>
								<p>{stateObjectStoryTeller.description}</p>
							</div>
						</div>
						{gameUser.permissions?.hasOwnProperty(
							'view_storyteller_content'
						) ? (
							<ListStoryTellerContent
								stateObjectStoryTeller={stateObjectStoryTeller}
							/>
						) : null}
					</div>
				</>
			) : null}
		</div>
	)
}
