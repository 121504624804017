import { useTranslation } from 'react-i18next'
import { useAuthProvider } from '../../../../context/AuthProvider/AuthProvider'
import { ListNpcs } from './NPCs/ListNpcs'
import { NpcStateCollapse } from './NpcStateCollapse'

export const NpcsContent = () => {
	const { t } = useTranslation()
	// AUTH PERMISSION
	const { gameUser, axiosSupreme } = useAuthProvider()

	return (

		<div className='adminDesign__container__mainContainerBank'>
			<div className="adminDesign__container__mainContainerBank__Item">
				<div className='adminDesign__container__mainContainerBank__Item__title'>
					<h1>{t("pages.editor.components.npcs_content.bank_npcs")}</h1>
				</div>
				<div className='adminDesign__container__mainContainerBank__Item__content'>
					<hr />
					<div className="containerNpcState">
						<NpcStateCollapse />
					</div>

					{gameUser.permissions?.hasOwnProperty('view_npc') ? (
						<ListNpcs />
					) : null}
				</div>
			</div>
		</div>
	)
}
