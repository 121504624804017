import React, { useState, useEffect, useRef, forwardRef } from 'react'
import Sortable from 'sortablejs'
import '../../styles/Anagram.scss'

import { useGameStatus } from '../../provider/ActivityProvider'
import TimerLine from '../fragments/TimerLine'
import ImageOnFlex1 from '../fragments/ImageOnFlex1'
import AudioHelpButton from '../fragments/AudioHelpButton'
import AudioGame from '../fragments/AudioGame'

import { useSoundAction } from '../../hooks/useSoundAction'

/**
 * @typedef {Object} splits
 * @property {Object} word the config when split a word into letters.
 * @property {Object} phrase the config to split a phrase into words.
 */
const splits = {
	word: {
		separator: '',
		wordStyle: {},
		letterStyle: {},
	},
	phrase: {
		separator: ' ',
		wordStyle: { width: '100%' },
		letterStyle: {
			flex: 'auto',
			width: 'min-content',
			fontSize: '.9em',
			padding: '0 .3rem',
		},
	},
}

/**
 * Renders a game with the words in wordsData passed by the Playground Component
 * @param {Array<Object>} wordsData Each object has a word, translation, audio, image, etc...
 * @returns {JSX}
 */
const Anagram = ({ wordsData }) => {
	const [, setGameStatus] = useGameStatus()

	/**
	 * @typedef {Object} disorder
	 * @property {String} word the word or phrase in disorder: 'RAC' or 'NAME MY FELIPE IS',
	 * @property {Array<Numbers>} keys as this component uses a library that changes the divs of
	 * position and React keeps track of the position of elements, it could case a DE-SYNCRONIZATION
	 * and a source of bugs, that's why we use 'keys' to mirror the library and keeps this component
	 * in sync with the library: [0, 1, 2].
	 * @property {Number} indexReference a pointer to a field in @type wordsData, so we have access to word,
	 * translation, audio, image and so on...
	 * @property {String} splitReference a pointer (a key that points to a value) to an Object in @type splits so we keep the right
	 * split config for this object: 'phrase'
	 */
	/**
	 * @type {Array<disorder>} disorders
	 * @type {Number} currentIndex keeps track of the current index (like a pointer to the actual
	 * disorder in @type disorders).
	 * @type {Boolean} currentDisorderIsCompleted if the actual disorder was completed by the user.
	 */
	const [disorders, setDisorders] = useState([])
	const [currentIndex, setCurrentIndex] = useState(0)
	const currentIndexRef = useRef(currentIndex)
	const currentDisorderIsCompleted = useRef(false)

	/**
	 * @type {Boolean} gameIsFinished true when the player losts or wins
	 * @type {Boolean} timerJustEnd it is called when then the animation div that represents a timer
	 * ends.
	 */
	const gameIsFinished = useRef(false)
	const timerJustEnd = useRef(false)

	const passToNextWordValidations = useRef({
		audio: false,
		animation: false,
	})

	// ----- VARIABLES, FUNCIONES Y OBJETOS

	// ----------------------------------------------------------- $STATES

	/**
	 * All of these objects are exactly the same but focused on different containers.
	 * @typedef {Object} states An object that contains all of the different possible visual states
	 * of a Component.
	 * @property {String} hidden a className that represents a visual state of the DomElement.
	 */
	const letterStates = {
		normal: 'playground__anagram__words__wrapper__container__word__letter',
		completed:
			'playground__anagram__words__wrapper__container__word__letter playground__anagram__words__wrapper__container__word__letter__animation__color',
		disabled:
			'playground__anagram__words__wrapper__container__word__letter playground__anagram__words__wrapper__container__word__letter__disabled',
	}
	const wordsContainerStyles = {
		withTransition: { top: '0px' },
		withoutTransition: { top: '0px', transition: 'top 0ms ease-in' },
	}

	// ----------------------------------------------------------- $ACTUAL STATE

	// ----- IMAGE
	/**
	 * @type {String} currentImageSrc the url of the image that represents the word
	 */
	const [currentImageSrc, setCurrentImageSrc] = useState('')

	// ----- WORDS CONTAINER
	/**
	 * @type {Number} wordsContainerTopDisplacement the distance between the center of a letter div
	 * and the center of the letter of the word under this one.
	 * @type {Number} wordsContainerTop the top css property which directly affects its position
	 * @type {Object} wordsContainerStyle the style argument of the words container.
	 */
	const wordsContainerTopDisplacement = 40 + 64 // The height of a letter + the word bottom margin.
	const wordsContainerTop = useRef(0)
	const [wordsContainerStyle, setWordscontainerStyle] = useState(
		wordsContainerStyles.withoutTransition
	)

	// ----- TIMER
	/**
	 * @type {String} timerStaate The className of the div representing the timer.
	 */
	const [timerlineIsRunning, setTimerlineIsRunning] = useState(false)

	// ----- LETTERS
	/**
	 * @type {Number} actualLetterAnimationIndex The index of a letter that indicates that every letter
	 * with an index smaller than this one shuld have a className with an animation of completition in
	 * it. So setActualLetterAnimationIndex gets called in a function every 100ms incresing the index,
	 * and causing the effect of the letters being animater one after the other.
	 */
	const [actualLetterAnimationIndex, setActualLetterAnimationIndex] =
		useState(0)

	// ----- AUDIO
	/**
	 * @type {String} currentAudioSrc the url of the audio of the the word
	 */
	const [currentAudioSrc, setCurrentAudioSrc] = useState('')

	// ----------------------------------------------------------- $ACTIONS

	// Called from Words
	/**
	 * Update the @property keys of a disorder so we keep in sync the libray with React.
	 * @param {Number} oldIndex The user moved a letter, the old position of that letter
	 * @param {Number} newIndex The new position of that letter in the word.
	 * @param {String} newDisorderedWord The disordered word after the user moved a letter.
	 * @returns
	 */
	const updateWordDataOnChange = (oldIndex, newIndex, newDisorderedWord) => {
		// ----- IF THE TIMER ENDED RETURN
		if (timerJustEnd.current) {
			return
		}

		// ----- COPY OF DISORDERS
		const disordersCopy = disorders.map((disorder) => {
			return { ...disorder }
		})

		// ----- REMOVE THE KEY IN THE POSITION OF THE OLD INDEX
		const popedIndex = disordersCopy[currentIndex].keys.splice(
			oldIndex,
			1
		)[0]

		// ----- ADD THE REMOVED KEY IN THE NEW POSITION
		disordersCopy[currentIndex].keys.splice(newIndex, 0, popedIndex)

		// ----- SET THE DISORDER WORD TO THE NEW ONE.
		disordersCopy[currentIndex].word = newDisorderedWord

		// ----- UPDATE DISORDERS
		setDisorders(disordersCopy)
	}

	const audioAlreadyStartedTimeLimit = 4000
	const audioAlreadyStartedTimeoutRef = useRef(null)
	const audioAlreadyStartedRef = useRef(false)
	/**
	 *  - PLAY THE SOUND OF THE WORD.
	 *  - LA PALABRA COMPLETADA SE MOSTRARÁ EN LA LISTA.
	 *  - LAS LETRAS SE VAN A ANIMAR UNA POR UNA.
	 *  - WIN OR NEXT WORD.
	 *  - EL CONTENEDOR DE LAS PALABRAS SE MOVERÁ HACIA ARRIA.
	 */
	const handleWordCompleted = () => {
		// ----- IF THE TIMER ENDED
		if (timerJustEnd.current) return
		// ----- COMPLETE THE TIMER (PAUSE IT)
		setTimerlineIsRunning(false)
		// ----- INCREASE THE CURRENT INDEX
		setCurrentIndex(currentIndex + 1)
		currentIndexRef.current = currentIndex + 1

		// ----- START THE TIMEOUT TO SEE IF THE AUDIO STARTED
		audioAlreadyStartedRef.current = false
		audioAlreadyStartedTimeoutRef.current = setTimeout(() => {
			clearTimeout(audioAlreadyStartedTimeoutRef.current)

			// Si entra aqui, quiere decir que nunca arrancó el audio.
			// por ende lo marcamos como completado.
			passToNextWordValidations.current.audio = true
		}, audioAlreadyStartedTimeLimit)

		// ----- SET THE CURRENT WORD TO TRUE
		currentDisorderIsCompleted.current = true

		// ----- #1 PLAY THE SOUND OF THE WORD.
		playWordAudio()

		// ----- #1. LAS LETRAS SE VAN A ANIMAR UNA POR UNA.
		const delay = 260
		let letterIndex = 0
		const currentDisorder = disorders[currentIndex]
		const separator = splits[currentDisorder.splitReference].separator
		for (const _ of wordsData[currentDisorder.indexReference].word.split(
			separator
		)) {
			let timeout = setTimeout(
				(animIndex, isTheLast) => {
					clearTimeout(timeout)
					setActualLetterAnimationIndex(animIndex)

					// ----- #2 (CONDICIONAL) WIN OR NEXT WORD.
					if (isTheLast) {
						timeout = setTimeout(() => {
							clearTimeout(timeout)
							handlePassToNextWordOrWin('animation')
						}, 1000)
					}
				},
				delay * letterIndex,
				letterIndex,
				letterIndex ===
					wordsData[currentDisorder.indexReference].word.split(
						separator
					).length -
						1
			) // the time of the animation
			letterIndex += 1
		}
	}

	// ----- #3. EL CONTENEDOR DE LAS PALABRAS SE MOVERÁ HACIA ARRIBA.
	const moveTheWordsContainer = () => {
		// ----- #3
		wordsContainerTop.current -= wordsContainerTopDisplacement
		setWordscontainerStyle({
			...wordsContainerStyles.withTransition,
			top: wordsContainerTop.current + 'px',
		})
		const timeout = setTimeout(() => {
			clearTimeout(timeout)
			setTimerlineIsRunning(true)
			currentDisorderIsCompleted.current = false
		}, 600)
	}

	const handlePassToNextWordOrWin = (type) => {
		const theOtherType = {
			animation: 'audio',
			audio: 'animation',
		}

		if (type === 'animation') {
			if (!audioAlreadyStartedRef.current) {
				// Si entra aqui, quiere decir que se completo primero la
				// animación y el audio ni siquiera comenzó a sonar.
				clearTimeout(audioAlreadyStartedTimeoutRef.current)
				passToNextWordValidations.current.audio = true
			}
		}
		// si la del audio ya se completó tambien, entonces pasemos al próximo paso.
		passToNextWordValidations.current[type] = true
		if (passToNextWordValidations.current[theOtherType[type]]) {
			// restaurar los valores para la próxima palabra
			passToNextWordValidations.current = {
				audio: false,
				animation: false,
			}
			if (currentIndexRef.current === disorders.length) {
				win()
			} else {
				// Important stuff
				moveTheWordsContainer()
				setCurrentImageSrc(
					wordsData[disorders[currentIndexRef.current].indexReference]
						.image
				)
			}
		}
	}

	/**
	 * Plays the audio of the current word.
	 */
	const playWordAudio = () => {
		setCurrentAudioSrc(
			wordsData[disorders[currentIndex].indexReference].audio
		)
	}

	const handleAudioStart = (audioIndex) => {
		// That is perfect, so... basically ignore this function if -1 or the
		// index is different than the currentIndexRef.current
		if (audioIndex === -1 || audioIndex !== currentIndexRef.current) return

		// Si ya aparece como completada, quiere decir que la animación se
		// completo antes de que este wey comenzara a sonar.
		// o que el tiempo limite para sonar llegó a su fin.
		if (passToNextWordValidations.current.audio) return

		clearTimeout(audioAlreadyStartedTimeoutRef.current)
		audioAlreadyStartedRef.current = true
	}

	const handleAudioEnd = (audioIndex) => {
		// That is perfect, so... basically ignore this function if -1 or the
		// index is different than the currentIndexRef.current
		if (audioIndex === -1 || audioIndex !== currentIndexRef.current) return

		// Si ya esta marcada como completada, quiere decir que la animación terminó
		// antes de que comenzara a sonar o que el timepo limite llegó a su fin.
		if (passToNextWordValidations.current.audio) return

		// ----- IF THE TIMER HASN'T END YET
		if (!timerJustEnd.current) {
			// ----- IF THE GAME FINISHED BECAUSE IT WAS THE LAST WORD RETURN
			if (gameIsFinished.current) return

			handlePassToNextWordOrWin('audio')
		}
	}

	// ----------------------------------------------------------- $CONCLUTIONS

	/**
	 * Called when the timer ends. Basically game over.
	 */
	const onTimerEnd = () => {
		if (currentDisorderIsCompleted.current) return
		timerJustEnd.current = true
		gameIsFinished.current = true
		gameOver()
	}

	/**
	 * Comunicate the Dialogue Provider that the game was lost
	 */
	const gameOver = () => {
		setGameStatus('gameOver')
	}

	/**
	 * Sets the NPC profile expression to Happy
	 */
	const win = () => {
		gameIsFinished.current = true

		// ----- IF THERE ARE NO MORE WORDS THEN INFORM THE DIALOGUE PROVIDER THAT THE GAME WAS COMPLETED
		setGameStatus('completed')
	}

	// ----------------------------------------------------------- $INIT

	/**
	 * Iterates over wordsData and creates an array of disorders
	 */
	const bornOfDisorders = () => {
		/**
		 * Takes a String and disorders it.
		 * @param {String} string the string to disorder: 'HOLA' 'MI NOMBRE ES'
		 * @param {String} separator how you want to split the String: '' by letters or, ' ' by words
		 * @returns the disorderer word or phrase 'OHLA' or 'NOMBRE MI ES'
		 */
		const getDisorderedString = (string, separator) => {
			let disorderedString = ''

			const stringArray = string.split(separator)
			let stringArrayCopy
			let disorderedStringArray

			const tryCounterLimitForAvoidInfiniteLoop = 40
			let tryCounter = 0

			do {
				stringArrayCopy = stringArray.slice()
				disorderedStringArray = []

				// Pop random letters of stringArrayCopy and push them into disorderedStringArray
				do {
					const randomLetterIndex = Math.round(
						Math.random() * (stringArrayCopy.length - 1)
					)
					const popedLetter = stringArrayCopy.splice(
						randomLetterIndex,
						1
					)
					disorderedStringArray.push(popedLetter)
				} while (stringArrayCopy.length > 0)

				tryCounter += 1
			} while (
				disorderedStringArray.join(separator) === string &&
				tryCounter < tryCounterLimitForAvoidInfiniteLoop
			)

			if (tryCounter === tryCounterLimitForAvoidInfiniteLoop) {
				return null
			} else {
				disorderedString = disorderedStringArray.join(separator)
				return disorderedString
			}
		}

		/**
		 * @type {Array<Object>} disorderCopy the one that will the disorders
		 */
		let disordersCopy = []
		// ----- CREATE A DISORDER PER WORD IN WORDSDATA
		wordsData.forEach((wordObj, wordIndex) => {
			// ----- IT IS A WORD OR A PHRASE?
			const splitReference =
				wordObj.word.split(' ').length === 1 ? 'word' : 'phrase'
			// ----- THE APPROPIATE SEPARATOR
			const separator = splits[splitReference].separator

			// ----- GET THE DISORDER WORD
			const w = getDisorderedString(wordObj.word, separator) // Puede retornar null si nunca consigue que sea diferente a la palabra original.
			if (w) {
				const newDisorder = {}
				newDisorder.splitReference = splitReference
				newDisorder.word = w // este es el valor que debo obtener
				const separator = splits[newDisorder.splitReference].separator
				newDisorder.keys = wordObj.word
					.split(separator)
					.map((letter, index) => index)
				newDisorder.indexReference = wordIndex

				disordersCopy.push(newDisorder)
			}
		})
		return disordersCopy
	}

	const once = useRef(false)
	if (!once.current) {
		once.current = true

		// CREATE THE DISORDERS
		const disordersCopy = bornOfDisorders()
		setDisorders(disordersCopy)

		// SET THE CURRENT IMAGE
		setCurrentImageSrc(wordsData[disordersCopy[0].indexReference].image)

		// WAIT A LITTLE BIT AND START THE TIMER
		const timeout2 = setTimeout(() => {
			clearTimeout(timeout2)

			setTimerlineIsRunning(true)
			timerJustEnd.current = false
		}, 2000)
	}

	/**
	 * Necesito dos puntos:
	 *  Si el audio no ha comenzado despues de 4 segundos o antes
	 * de que la animación termine, entonces
	 * marcar la casilla del audio como terminada.
	 */

	// ----------------------------------------------------------- $UNIVERSE

	/**
	 * @type {String|Null} currentAudio the url of the current disorder
	 */
	let currentAudio
	let currentWord
	let currentWordLength
	if (
		disorders.length > 0 &&
		currentIndex <= wordsData.length - 1 &&
		disorders[currentIndex]
	) {
		currentAudio = wordsData[disorders[currentIndex].indexReference].audio
		currentWord = wordsData[disorders[currentIndex].indexReference].word
		currentWordLength =
			currentWord.split(' ').length === 1
				? currentWord?.length
				: currentWord.split(' ').length
	}

	return (
		<div
			className='playground__game playground__anagram'
			id='playground__anagram'>
			<TimerLine
				isRunning={timerlineIsRunning}
				onAnimationEnd={onTimerEnd}
				duration={currentWordLength * (9000 + currentWordLength * 100)}
			/>
			<ImageOnFlex1
				src={currentImageSrc}
				isGame
				wordsData={wordsData}
				isAnagram
				currentIndex={currentIndex}>
				<AudioHelpButton key={currentAudio} src={currentAudio} />
			</ImageOnFlex1>
			<Words
				timerJustEnd={timerJustEnd}
				currentIndex={currentIndex}
				letterStates={letterStates}
				disorders={disorders}
				wordsData={wordsData}
				handleWordCompleted={handleWordCompleted}
				updateWordDataOnChange={updateWordDataOnChange}
				wordsContainerStyle={wordsContainerStyle}
				actualLetterAnimationIndex={actualLetterAnimationIndex}
			/>
			<AudioGame
				key={currentIndex}
				src={currentAudioSrc}
				index={currentIndexRef.current}
				handleAudioStart={handleAudioStart}
				handleAudioEnd={handleAudioEnd}
			/>
		</div>
	)
}

/**
 * Renders a container with containers representing words (or phrases)
 * with containers representing letter (or words if phrase)
 * @param {Object} props
 * @returns {JSX}
 */
const Words = (props) => {
	const { soundAction } = useSoundAction()
	/**
	 * @type {DomElement} actualWordContainerRef the div containing the actual disorder
	 * @type {Object} sortableRef Special library object, which gives the div (actualWordContainerRef)
	 * the ability to move divs and exchage its positions.
	 * @type {Array<sortableRef>} sortables Array of sortableRefs to destroy them all once the game
	 * have finished
	 */
	let actualWordContainerRef = useRef(null)
	let sortableRef = useRef(null)
	let sortables = useRef([])

	/**
	 * This is called automatically from Word Component, when props.currentIndex changes. (useEffect)
	 * Gives the current disorderWordContainer to change childrens positions between them.
	 * And manages all that functionality from sortableRef.
	 */
	const createNextSortable = () => {
		// ----- Destroy the sortables already created.
		destroySortables()
		sortableRef.current = Sortable.create(actualWordContainerRef.current, {
			// ANIMATION TIME.
			animation: 150,
			// THE CLASS THE SELECTED CHILD EMPTY SPOT IS GOING TO BE
			ghostClass:
				'playground__anagram__words__wrapper__container__word__letter' +
				'__ghost',
			setData: null,
			// WHEN THE USER START MOVING A CHILD
			onStart: () => {
				if (props.timerJustEnd.current) {
					destroySortables()
					return
				}
			},
			// WHEN THE USER ENDS MOVING A CHILD
			onEnd: handleWordChanged,
		})
		// ADD THIS SORTABLE TO THE LIST
		sortables.current = [...sortables.current, sortableRef]
	}

	/**
	 * DESTROYS ALL OF THE SORTABLES (OBJECTS THAT GIVES THE ABILITY TO CONTAINERS TO SORT
	 * CHILDREN WITH MOUSE)
	 */
	const destroySortables = () => {
		for (const s of sortables.current) {
			s.current.destroy()
		}
		sortables.current = []
	}

	/**
	 * Called each time the user changes the position of the children of the div
	 * @param {Object} e
	 * @returns
	 */
	const handleWordChanged = (e) => {
		soundAction('effect', 'TLIJ3', 'play')

		// IF THE TIMER ENDED, DESTROY THE SORTABLES AND RETURN
		if (props.timerJustEnd.current) {
			destroySortables()
			return
		}

		/**
		 * @type {Object} currentDisorder the current @type disorder
		 * @type {String} separator '' if word. ' ' if phrase
		 * @type {String} newActualChangedDisorderedWord how to word is after the user change children
		 * positions.
		 */
		const currentDisorder = props.disorders[props.currentIndex]
		const separator = splits[currentDisorder.splitReference].separator
		const newActualChangedDisorderedWord = [
			...actualWordContainerRef.current.children,
		]
			.map((e, i) => e.innerHTML)
			.join(separator)

		// Inform the parent the new change
		props.updateWordDataOnChange(
			e.oldIndex,
			e.newIndex,
			newActualChangedDisorderedWord
		)

		// If the word that the user changed is now the ordered version, then infom the parent
		if (
			newActualChangedDisorderedWord ===
			props.wordsData[props.disorders[props.currentIndex].indexReference]
				.word
		) {
			destroySortables()
			props.handleWordCompleted()
		}
	}

	return (
		<div
			className='playground__anagram__words__wrapper'
			id='playground__anagram__words__wrapper'>
			<div
				style={props.wordsContainerStyle}
				className='playground__anagram__words__wrapper__container'
				id='playground__anagram__words__wrapper__container'>
				{props.disorders.map((disorder, index) => {
					return (
						<Word
							timerJustEnd={props.timerJustEnd}
							currentIndex={props.currentIndex}
							wordIndex={index}
							letterStates={props.letterStates}
							actualLetterAnimationIndex={
								props.actualLetterAnimationIndex
							}
							createNextSortable={createNextSortable}
							ref={
								props.currentIndex === index
									? actualWordContainerRef
									: null
							}
							ID={
								props.currentIndex === index
									? props.actualWordContainerID
									: props.notActualWordContainerID
							}
							key={index}
							disorder={disorder}></Word>
					)
				})}
			</div>
		</div>
	)
}

/**
 * Renders a container that contains other divs representing the letters (or the words if phrase)
 */
const Word = forwardRef((props, ref) => {
	/**
	 * Each time the current index changes, create a new sortable with the new new word container
	 */
	useEffect(() => {
		if (ref) {
			// En este punto la referencia que pasaron del padre ya tiene el valor correcto.
			props.createNextSortable()
		}
	}, [props.currentIndex])

	/**
	 * A @type splits
	 * @type {String} separator
	 * @type {Object} wordStyle
	 * @type {Object} letterStyle
	 */
	const separator = splits[props.disorder.splitReference].separator
	const wordStyle = splits[props.disorder.splitReference].wordStyle
	const letterStyle = splits[props.disorder.splitReference].letterStyle

	return (
		<div
			ref={ref}
			className='playground__anagram__words__wrapper__container__word'
			style={wordStyle}
			id={props.ID}>
			{props.disorder.word.split(separator).map((letter, index) => {
				// Assign the state depending on the state of the letter
				let state = props.letterStates.normal
				if (props.timerJustEnd.current) {
					state = props.letterStates.disabled
				} else if (props.wordIndex < props.currentIndex - 1) {
					state = props.letterStates.completed
				} else if (
					props.wordIndex < props.currentIndex &&
					index <= props.actualLetterAnimationIndex
				) {
					state = props.letterStates.completed
				}

				return (
					<Letter
						key={props.disorder.keys[index]}
						state={state}
						letterStyle={letterStyle}
						letter={letter}></Letter>
				)
			})}
		</div>
	)
})

/**
 * Renders a div with a letter (or word if it is a phrase)
 * @param {String} state the className of the div
 * @param {Object} letterStyle the style of the div
 * @param {String} letter the content of the div
 * @returns
 */
const Letter = ({ state, letterStyle, letter }) => {
	const { soundAction } = useSoundAction()
	return (
		<div
			id='playground__anagram__words__wrapper__container__word__letter'
			className={state}
			onMouseDown={() => {
				soundAction('effect', 'TLIJ2', 'play')
			}}
			style={letterStyle}>
			{letter}
		</div>
	)
}

export default Anagram
