import { useState, createContext, useContext, useRef } from 'react'

/**
 * Used by Lesson and Playground to keep everything working.
 * @returns {Object} All of the info of the activity
 */
export const ActivityContext = createContext(null)
export function useActivity() {
	return useContext(ActivityContext)
}

/**
 * Games import this function so they keep the activity provider up to date.
 * @returns {Function} that changes the status of the current game, so this keeps updated
 */
export const GameStatusContext = createContext(null)
export function useGameStatus() {
	return useContext(GameStatusContext)
}

/**
 * ***** CHANGE THE IS LOADING PROPERTY FOR THE GAME *****
 */
/**
 * This component povides and manages all of the activity information to its children.
 * And controls the visibility and accessibility of its contents by load resources.
 * Also provides its children with functions to keep everything up to them updated.
 * ALL OF THIS PARAMS COMES FROM @type activity
 * @param {Array<Object>} items
 * @param {String} isGame The NPC interacting is type game or not? (class)
 * @param {JSX} children
 * @returns
 */
export default function ActivityProvider({ items, isGame, children }) {
	const [gameStatus, setGameStatus] = useState('inProgress') // gameOver, Completed
	const gameMistakesCounter = useRef(0)
	const starsEarned = useRef(0)

	/**
	 * ActivityContextData This is the most important, this is the info provided to children
	 * @typedef {Object} ActivityContextData
	 * @property {Boolean} isLoading if the secuence and the profile are already loaded
	 * @property {Boolean} isGame if it is game or not (class)
	 * @type {Object} ActivityContextData This is the most important, this is the info provided to children
	 */
	const ActivityContextData = {
		isLoading: items ? false : true,
		wordsFragments: items,
		isGame: isGame,
	}

	return (
		<ActivityContext.Provider value={ActivityContextData}>
			<GameStatusContext.Provider
				value={[
					gameStatus,
					setGameStatus,
					gameMistakesCounter,
					starsEarned,
				]}
			>
				{children}
			</GameStatusContext.Provider>
		</ActivityContext.Provider>
	)
}
