import React from 'react'
import { useTranslation } from 'react-i18next';
import { MdOutlineReportGmailerrorred } from "react-icons/md";

export const ErrorPerms = () => {
    const { t } = useTranslation()
    return (
        <div className='contentErrorPerms'>
            <p className='iconErrorPerms'><MdOutlineReportGmailerrorred></MdOutlineReportGmailerrorred></p>

            <p className='textErrorPerms'>{t("pages.editor.components.error_perms.insufficient_permissions")}</p>
        </div>
    )
}
