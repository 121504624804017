import {
	createCamera,
	zoomCamera,
	setZoomBoundaries,
	setCameraBounds,
} from './Camera'

import { updateMissionState } from '../sceneCores/progressBar'

import { useUpdateProfileContext } from '../components/SceneDataFetch'

/**
 * Draws on the canvas the images and config physics and camera props.
 * Iterate over all the instances drawing them on the screen.
 */
export default function create(
	setPhaserCreateAlreadyCalled,
	game,
	scene,
	axiosSupreme,

	levelResume,
	profileData,
	sceneData,
	goToNextScene,
	setReloadScene,
	updateProfileData,

	setProgressBarData,
	progressBarDataRef,
	missionIsAvailable,
	missionIsCompleted,
	setActivity,
	setActivityFunctions,
	setActivityState,
	setLostItemsThereIsAnUnseenInteraction,
	setApplyNewSkin,
	levelResults,
	soundAction,
	setOnZoomClick,

	player,
	doors,
	NPCS,
	lostItems,
	decorations,

	// function for delete listener of resize
	handlerResizeGame
) {
	// ----- MAP
	var shapes
	var map
	window.scene = scene

	if (sceneData.collisionFile) {
		shapes = scene.cache.json.get('shapes')
		map = scene.matter.add.sprite(0, 0, 'map', null, { shape: shapes.map })
		map.setPosition(map.body.centerOffset.x, map.body.centerOffset.y)
		map.setStatic(true)
	} else map = scene.add.image(0, 0, 'map').setOrigin(0, 0)

	// The physical limits that the bodies can't pass through.
	scene.matter.world.setBounds(0, 0, map.width, map.height)

	// ----- DOORS
	for (const door of doors) door.create(scene, 'floating')

	// ----- DECORATIONS
	for (const decoration of decorations) decoration.create(scene, 'floating')

	// ----- PLAYER
	player.create(scene, 'dynamic') //, thumbstick)
	setApplyNewSkin(() => (skinName, skinUrl) => {
		player.applyNewSkin(scene, skinName, skinUrl)
	})

	// ----- MISSION OBJECTS
	for (const lostItem of lostItems) lostItem.create(scene, 'floating')

	// ----- NPCS
	for (const npc of NPCS.class) {
		npc.create(
			scene,
			'static',
			() => {
				openNPC(npc)
			},
			axiosSupreme
		)
	}
	for (const npc of NPCS.game) {
		npc.create(
			scene,
			'static',
			() => {
				openNPC(npc)
			},
			axiosSupreme
		)
	}
	for (const npc of NPCS.lostItem) npc.create(scene, 'static')
	for (const npc of NPCS.easterEgg) npc.create(scene, 'floating')

	function updateMission(missionID, earnedStars, skipUpdateProgressBar) {
		updateMissionState(
			axiosSupreme,
			setProgressBarData,
			progressBarDataRef,
			updateProfileData,
			levelResults,
			levelResume,
			sceneData,
			profileData,
			doors,
			scene,
			missionID,
			earnedStars,
			skipUpdateProgressBar
		)
	}

	function openDoor(door) {
		game.scene.pause('default')
		goToNextScene(
			door.id,
			profileData.id_profile,
			door.name,
			door.goesToWorld
		)
	}
	/**
	 * called when the NPC or its button gets clicked.
	 * Assign the @type dialogueFunction and @type activity its corresponging
	 * pointers (well... they are copies) to the NPC properties.
	 */
	function openNPC(npc) {
		// ----- RE-ASSIGN FUNCTIONS
		setActivityFunctions((fs) => ({
			...fs,
			randomiseContentItems: () => {
				npc.randomiseContentItems(setActivity)
			},
			onComplete: (earnedStars) => {
				// ONLY FOR LESSON AND GAME
				npc.onActivityCompleted(
					updateMission,
					earnedStars,
					missionIsCompleted
				)
			},
			onClose: (gameStatus) => {
				soundAction('effect', 'TLSG2', 'play')
				npc.onActivityClosed(
					scene,
					missionIsCompleted,
					player,
					setActivityState,
					setActivity,
					gameStatus,
					soundAction
				)

				// ***** ME HACE FALTA ESPECIFICAR QUE SI HA GANADO MIENTRAS TENIA
				// EL JUEGO ABIERTO, QUE NO SE SALTA LA EXPERIENCIA.
				// ***** TAMBIEN OTRO DETALLE ES QUE LA EXPERIENCIA VAYA
				// ACUMULANDOSE, SI QUE ES GANA MÁS DE UNA PARTIDA SEGUIDA.
			},
		}))

		soundAction('effect', 'TLSG1', 'play')

		// ----- OPEN THE DIALOGUE
		npc.onActivityOpen(player, setActivityState, setActivity)
	}

	{
		// ----- $COLLITIONS
		const otherBodies = {
			NPC: 'NPC ZONE',
			door: 'DOOR ZONE',
			lostItem: 'LOST ITEM ZONE',
			easterEggAudio: 'EASTER EGG AUDIO ZONE',
		}
		const onStartCollisions = {
			[otherBodies.NPC]: onNPCStartCollision,
			[otherBodies.door]: onDoorStartCollision,
			[otherBodies.lostItem]: onLostItemStartCollision,
			[otherBodies.easterEggAudio]: onEasterEggAudioStartCollision,
		}
		const onEndCollisions = {
			[otherBodies.NPC]: onNPCEndCollision,
			[otherBodies.door]: onDoorEndCollision,
			[otherBodies.lostItem]: onLostItemEndCollision,
			[otherBodies.easterEggAudio]: onEasterEggAudioEndCollision,
		}
		const isInABody = (bodyA, bodyB, zoneName) => {
			return (
				bodyA.label.text === zoneName || bodyB.label.text === zoneName
			)
		}
		const bodiesIdentification = (bodyA, bodyB) => {
			if (bodyA.label !== 'PLAYER' && bodyB.label !== 'PLAYER')
				return [null, null]

			let otherBody
			if (isInABody(bodyA, bodyB, otherBodies.NPC))
				otherBody = otherBodies.NPC
			else if (isInABody(bodyA, bodyB, otherBodies.door))
				otherBody = otherBodies.door
			else if (isInABody(bodyA, bodyB, otherBodies.lostItem))
				otherBody = otherBodies.lostItem
			else if (isInABody(bodyA, bodyB, otherBodies.easterEggAudio))
				otherBody = otherBodies.easterEggAudio

			const zoneID = bodyB.label.id ? bodyB.label.id : bodyA.label.id
			return [otherBody, zoneID]
		}
		scene.matter.world.addListener(
			'collisionstart',
			(event, bodyA, bodyB) => {
				const [otherBody, zoneID] = bodiesIdentification(bodyA, bodyB)
				if (otherBody) onStartCollisions[otherBody](zoneID)
			}
		)
		scene.matter.world.addListener(
			'collisionend',
			(event, bodyA, bodyB) => {
				const [otherBody, zoneID] = bodiesIdentification(bodyA, bodyB)
				if (otherBody) onEndCollisions[otherBody](zoneID)
			}
		)

		function calculateActualDoor(zoneID) {
			for (const door of doors) {
				if (door.id === zoneID) return door
			}
		}
		function calculateActualLostItem(zoneID) {
			for (const lostItem of lostItems) {
				if (lostItem.id === zoneID) return lostItem
			}
		}
		function calculateActualNPC(zoneID) {
			for (const npc of NPCS.class) if (npc.id === zoneID) return npc
			for (const npc of NPCS.game) if (npc.id === zoneID) return npc
			for (const npc of NPCS.lostItem) if (npc.id === zoneID) return npc
			for (const npc of NPCS.easterEgg) if (npc.id === zoneID) return npc
		}

		// ----- $COLLITIONS NPC
		function onNPCStartCollision(zoneID) {
			const actualNPC = calculateActualNPC(zoneID)

			let args = []
			if (actualNPC.type === 'o')
				args = [
					profileData,
					setReloadScene,
					scene,
					axiosSupreme,
					setLostItemsThereIsAnUnseenInteraction,
					updateProfileData,
				]
			else args = [missionIsAvailable, soundAction, missionIsCompleted]
			actualNPC.onStartZoneCollision(...args)
		}
		function onNPCEndCollision(zoneID) {
			const actualNPC = calculateActualNPC(zoneID)

			let args = []
			if (actualNPC.type === 'ee')
				args = [scene, updateMission, missionIsCompleted, soundAction]
			else args = [soundAction]

			actualNPC.onEndZoneCollision(...args)
		}

		// ----- $COLLITIONS EASTER EGG AUDIO
		function onEasterEggAudioStartCollision(easterEggID) {
			const actualNPC = calculateActualNPC(easterEggID)
			actualNPC.onStartAudioZoneCollision(missionIsCompleted, soundAction)
		}
		function onEasterEggAudioEndCollision(easterEggID) {
			const actualNPC = calculateActualNPC(easterEggID)
			actualNPC.onEndAudioZoneCollision(soundAction)
		}

		// ----- $COLLITIONS DOOR
		function onDoorStartCollision(zoneID) {
			const actualDoor = calculateActualDoor(zoneID)
			actualDoor.onStartCollision(openDoor, player)
		}
		function onDoorEndCollision(zoneID) {
			const actualDoor = calculateActualDoor(zoneID)
			actualDoor.onEndCollision()
		}

		// ----- $COLLITIONS LOST ITEM
		function onLostItemStartCollision(zoneID) {
			const actualLostItem = calculateActualLostItem(zoneID)
			actualLostItem.onStartCollision(
				setReloadScene,
				useUpdateProfileContext,
				axiosSupreme,
				setLostItemsThereIsAnUnseenInteraction,
				soundAction
			)
		}
		function onLostItemEndCollision(zoneID) {
			const actualLostItem = calculateActualLostItem(zoneID)
			actualLostItem.onEndCollision(scene)
		}
	}

	/**
	 * Attatch the camera to the player sprite
	 */
	// ----- CAMERA
	createCamera(scene, player.sprite)
	setZoomBoundaries(scene, map)
	setCameraBounds(scene, map)
	setOnZoomClick(() => {
		return (zoomIn) => {
			zoomCamera(scene, zoomIn)
		}
	})
	handlerResizeGame(scene, map)
	setPhaserCreateAlreadyCalled(true)
}
