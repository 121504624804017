/**
 * A sprite is a container who can take different images or animations.
 */
export class Sprite {
	#id
	get id() {
		return this.#id
	}

	#type // ----------------------------- THIS ONE IS EMPTY. IMPLEMENT IT.
	get type() {
		return this.#type
	}

	#pos = { x: 0, y: 0 }
	get pos() {
		return this.#pos
	}
	#sprite
	get sprite() {
		return this.#sprite
	}
	#name // 'vaca'
	get name() {
		return this.#name
	}
	set name(newName) {
		this.#name = newName
	}
	/**
	 * [
	 *    {
	 *        name: 'vacaIdleAndSaluteSpriteSheet',
	 *        url: 'http//platform.com/image.png',
	 *        frameSize: [width, height],
	 *        #animations: [{
	 *                name: '${this.#name}-idle', // 'vaca-idle'
	 *                frames..., // here comes the fileName
	 *                duration...,
	 *                repeat...,
	 *            },{
	 *                name: '${this.#name}-salute', // 'vaca-salute'
	 *                frames..., // here comes the fileName
	 *                duration...,
	 *                repeat...,
	 *            }
	 *        ]
	 *    }
	 * ]
	 */
	#spriteSheets = []
	get spriteSheets() {
		return this.#spriteSheets
	}

	constructor(id, name, pos, spriteSheets, type) {
		this.#id = id
		this.#name = name
		this.#pos = this.parseStringVector(pos, ['x', 'y'])
		this.#spriteSheets = spriteSheets
		this.#type = type
	}
	preload(scene) {
		for (const spriteSheet of this.#spriteSheets) {
			// ----- LOAD THE IMAGE
			const frameSize = this.parseStringVector(spriteSheet.frameSize, [
				'frameWidth',
				'frameHeight',
			])
			scene.load.spritesheet(spriteSheet.name, spriteSheet.url, frameSize)
		}
	}
	createAnimations(scene) {
		for (const spriteSheet of this.#spriteSheets) {
			for (const animation of spriteSheet.animations) {
				scene.anims.create({
					key: this.#name + animation.name,
					frames: scene.anims.generateFrameNumbers(
						spriteSheet.name,
						animation.frames
					),
					duration: animation.duration,
					repeat: animation.repeat,
				})
			}
		}
	}
	create(scene, bodyType) {
		// ----- CREATE THE ANIMATIONS
		this.createAnimations(scene)

		// ----- DRAW THE IMAGE ON THE SCREEN
		const spriteSheetName = this.#spriteSheets[0].name
		if (bodyType === 'floating')
			this.#sprite = scene.add.sprite(
				this.#pos.x,
				this.#pos.y,
				spriteSheetName
			)
		else if (bodyType === 'dynamic')
			this.#sprite = scene.matter.add.sprite(
				this.#pos.x,
				this.#pos.y,
				spriteSheetName
			)
		//else if (bodyType === 'static')   this.#sprite = scene.matter.add.staticSprite(this.#pos.x, this.#pos.y, spriteSheetName)
		else if (bodyType === 'static')
			this.#sprite = scene.matter.add.sprite(
				this.#pos.x,
				this.#pos.y,
				spriteSheetName
			)

		if (bodyType === 'static') this.#sprite.setStatic(true)

		// ----- PLAY THE IDLE ANIMATION
		this.#sprite.anims.play(this.#name + '-idle')
	}
	parseStringVector(stringVector, keys) {
		let vector = stringVector.replace('[', '').replace(']', '').split(',')
		vector = {
			[keys[0]]: parseFloat(vector[0]),
			[keys[1]]: parseFloat(vector[1]),
		}

		return vector
	}
}
