import { useContext, useEffect, useState } from 'react'
import ModalContext from '../../../../../context/editor/ModalContext'
import { BsFillPencilFill, BsFillTrashFill } from 'react-icons/bs'

import { API_MANAGE_NPC } from '../../../../../utils/constantsAdmin'
import { NpcsBodyCollapse } from './NpcsBodyCollapse'
import { FormNpcs } from './FormNpcs'
import AlertContext from '../../../../../context/editor/AlertContext'
import DeleteContext from '../../../../../context/editor/DeleteContext'
import { toggleCollapse } from '../../../../../utils/general_functions'
import { FaChevronDown, FaChevronUp } from 'react-icons/fa'
import { useAuthProvider } from '../../../../../context/AuthProvider/AuthProvider'
import { useTranslation } from 'react-i18next'

export const NpcsCollapse = ({
	objectNpc,
	setRefreshNpcs,
	activeCollapseNpc,
	setActiveCollapseNpc,
}) => {
	const { t } = useTranslation()
	// AUTH PERMISSION
	const { gameUser, axiosSupreme } = useAuthProvider()

	// SET OBJECT ITEM
	const [stateObjectNpc, setStateObjectNpc] = useState(undefined)
	useEffect(() => {
		setStateObjectNpc(objectNpc)
	}, [objectNpc])

	// ALERT
	const {
		showAlert,
		setMessage: setMessageAlert,
		setTypeMessage,
	} = useContext(AlertContext)

	// MODAL
	const { openModal, setTitleModal, setContentModal } =
		useContext(ModalContext)

	// DELETE
	const { openDelete, setMessage, setFunctionDelete } =
		useContext(DeleteContext)

	// UPDATE NPCS
	const UpdateNpc = async () => {
		setTitleModal(t("pages.editor.components.npc_collapse.update_npc"))
		setContentModal(
			<FormNpcs
				stateObjectNpc={stateObjectNpc}
				setStateObjectNpc={setStateObjectNpc}
				setRefreshNpcs={setRefreshNpcs}
			/>
		)
		openModal()
	}

	const DeleteNpc = async () => {
		const result_data = await axiosSupreme(
			'delete',
			API_MANAGE_NPC + stateObjectNpc.id_npc + '/',
			undefined
		)
		if (result_data.hasOwnProperty('response')) {
			openDelete()
			setTypeMessage('error')
			setMessageAlert(
				t("common.cannot_delete")
			)
			showAlert()
		} else {
			setRefreshNpcs((prev) => !prev)
			openDelete()
		}
	}

	return (
		<>
			{stateObjectNpc !== undefined ? (
				<div className='adminComponents'>
					<div className='adminComponents__button collapseStylesBtn'>
						{activeCollapseNpc === objectNpc.id_npc ? (
							<div className='arrowOpenCollapse'>
								<FaChevronUp />{' '}
							</div>
						) : (
							<div className='arrowOpenCollapse'>
								{' '}
								<FaChevronDown />
							</div>
						)}
						<div
							className='adminComponents__button__title title'
							onClick={() =>
								toggleCollapse(
									activeCollapseNpc,
									setActiveCollapseNpc,
									stateObjectNpc.id_npc
								)
							}>
							<h2>{stateObjectNpc.name}</h2>
						</div>
						<div className='adminComponents__button__options options'>
							{gameUser.permissions?.hasOwnProperty(
								'delete_npc'
							) ? (
								<button
									className='editorBtnActionDelete'
									onClick={() => {
										setFunctionDelete(() => DeleteNpc)
										setMessage(
											t("pages.editor.components.npc_collapse.confirm_deleting_1") +
											stateObjectNpc.name +
											'?'
										)
										openDelete()
									}}>
									<p>
										<BsFillTrashFill />{' '}
									</p>
								</button>
							) : null}
							{gameUser.permissions?.hasOwnProperty(
								'change_npc'
							) ? (
								<button
									className='editorBtnActionEdit'
									onClick={UpdateNpc}>
									<p>
										<BsFillPencilFill />
									</p>
								</button>
							) : null}
						</div>
					</div>
					{activeCollapseNpc === stateObjectNpc.id_npc && (
						<NpcsBodyCollapse
							stateObjectNpc={stateObjectNpc}></NpcsBodyCollapse>
					)}
				</div>
			) : null}
		</>
	)
}
