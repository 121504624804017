import { useState } from 'react'
import '../../styles/WindowPopUp.scss'

import Coins from '../fragments/Coins'
import closeIcon from '../../assets/icons/closePlayground.png'
import tutorialIcon from '../../assets/icons/helpPlayground.png'
import Tabs from '../fragments/Tabs'
import { useTutorial } from '../../hooks/useTutorial'
import { useSoundAction } from '../../hooks/useSoundAction'

const WindowPopUp = ({ data, onClose, profileData }) => {
	const { tutorialSecuence } = useTutorial()
	const { soundAction } = useSoundAction()

	const tabs = data?.tabs
	const Component = data?.component

	const [activeTabIndex, setActiveTabIndex] = useState(0)

	return (
		<div
			className='popup'
			id='popup'
			onClick={(e) => {
				if (e.target.id !== 'popup') return
				onClose(e)
			}}>
			<div className='popup__window'>
				<Tabs
					data={tabs}
					activeTabIndex={activeTabIndex}
					setActiveTabIndex={setActiveTabIndex}
				/>
				<div className='popup__window__bar'>
					<div
						className='popup__window__bar__money'
						style={{
							height: data.showCoins ? '' : '1rem',
							backgroundColor: data.showCoins
								? ''
								: 'transparent',
						}}>
						{data.showCoins && (
							<Coins coinsAmount={profileData.rewards} />
						)}
					</div>

					{data.tutorial && (
						<button
							className='popup__window__bar__close'
							onClick={() => {
								tutorialSecuence.open()
								soundAction('effect', 'TLSG6', 'play')
							}}>
							<img
								src={tutorialIcon}
								alt='tutorial'
								loading='lazy'
								decoding='async'
							/>
						</button>
					)}
					<button
						className='popup__window__bar__close'
						onClick={onClose}>
						<img
							src={closeIcon}
							alt='cerrar'
							loading='lazy'
							decoding='async'
						/>
					</button>
				</div>
				<Content key={tabs[0].name}>
					<Component
						activeTabIndex={activeTabIndex}
						profileData={profileData}
						onClose={onClose}
					/>
				</Content>
			</div>
		</div>
	)
}

const Content = ({ children }) => {
	return (
		<div className='popup__window__content'>
			<div>{children}</div>
		</div>
	)
}

export default WindowPopUp
