import '../../styles/Alerts.scss'
import AlertContext from '../../context/editor/AlertContext'
import { useContext } from 'react'

export const Alert = () => {
	// CONTROLS FOR ALERTS
	const { visible, typeMessage, message } = useContext(AlertContext)

	return (
		<>
			{visible === true ? (
				<div
					className={'alerts__message__' + typeMessage}
					id={'alerts__message__' + typeMessage}>
					{message}
				</div>
			) : null}
		</>
	)
}
