/**
 * This class defines a tag with a name.
 */
export class StringTag {
	#pos
	#origin
	#text
	#className
	#isVisible

	#container
	get container() {
		return this.#container
	}
	#element
	get element() {
		return this.#element
	}

	#spanText
	get spanText() {
		return this.#spanText
	}

	constructor(pos, origin, text, className, isVisible) {
		this.#pos = pos
		this.#origin = origin
		this.#text = text
		this.#className = className
		this.#isVisible = isVisible
	}
	create(scene) {
		// ----- CREATE HTML
		let span = document.createElement('span')
		span.setAttribute('class', this.#className)

		if (this.#text.includes('🔮')) {
			let image = document.createElement('img')
			image.setAttribute('class', 'map__coin__image')

			this.#spanText = document.createElement('span')
			this.#spanText.innerText =
				' ' + this.#text.substring(2, this.#text.length)

			span.appendChild(image)
			span.appendChild(this.#spanText)
		} else {
			span.innerText = this.#text
		}

		// ----- CREATE THE HTML PHASER ELEMENT
		this.#element = scene.add
			.dom(0, 0, span)
			.setOrigin(this.#origin.x, this.#origin.y)

		// ----- CREATE THE CONTAINER WHERE THE ELEMENT LIVES
		this.#container = scene.add.container(this.#pos.x, this.#pos.y)
		this.#container.add(this.#element)

		// ----- ENABLE THE VISIBILITY
		this.enableVisibility(this.#isVisible)
	}
	enableVisibility(visibility) {
		if (visibility) this.#container.alpha = 1
		else this.#container.alpha = 0
	}
}
