import '../../styles/TimerLine.scss'

const TimerLine = ({ isRunning, onAnimationEnd, duration = 10000, className }) => {
	const sliderClass = isRunning
		? 'timerline__container__slider timerline__container__slider__animation__decrease'
		: 'timerline__container__slider'

	return (
		<div className={`timerline ${className}`}>
			<div className='timerline__container'>
				<div
					className={sliderClass}
					style={{ animationDuration: duration + 'ms' }}
					id='playground__anagram__timer__wrapper__timer__container__timer'
					onAnimationEnd={onAnimationEnd}></div>
			</div>
		</div>
	)
}

export default TimerLine
