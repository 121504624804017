import { useRef } from 'react'
import { useTranslation } from 'react-i18next'

export default function BodyCollapseAnimations({ stateObjectAnimation }) {
	const audioRef = useRef(null)
	const { t } = useTranslation()

	return (
		<div className='animationContent collapseStylesBody'>
			{stateObjectAnimation != null && (
				<div className='animationContent__container'>
					<div className='animationContent__container__Left'>
						{stateObjectAnimation.image_file ? (
							<a
								href={stateObjectAnimation.image_file}
								target='_blank'
								rel="noreferrer noopener"
							>
								<img
									src={stateObjectAnimation.image_file}
									alt='animation'
									loading='lazy'
								/>
							</a>
						) : (
							<p className='noSelect'>
								{t("pages.editor.components.body_collapse_animations.no_image_selected")}
							</p>
						)}
					</div>
					<div className='animationContent__container__Right'>
						<div className='animationContent__container__Right__Info'>
							<h4>{t("pages.editor.components.body_collapse_animations.frame_size")}</h4>
							<p>{stateObjectAnimation.frame_size}</p>
						</div>
						<div className='animationContent__container__Right__Info'>
							<h4>{t("pages.editor.components.body_collapse_animations.frame_number")}</h4>
							<p>{stateObjectAnimation.frames_amount}</p>
						</div>
						<div className='animationContent__container__Right__Info'>
							<h4>{t("pages.editor.components.body_collapse_animations.frame_duration")}</h4>
							<p>{stateObjectAnimation.frames_duration}</p>
						</div>
						<div className='animationContent__container__Right__Info'>
							<h4>{t("pages.editor.components.body_collapse_animations.sound")}</h4>
							{stateObjectAnimation.sound ? (
								<audio
									ref={audioRef}
									controls
									src={stateObjectAnimation.sound.audio_file}
								/>
							) : (
								<p>{t("pages.editor.components.body_collapse_animations.none")}</p>
							)}
						</div>
					</div>
				</div>
			)}
		</div>
	)
}
