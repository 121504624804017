import { useContext, useEffect, useState } from 'react'
import { FormNpcCompose } from './FormNpcCompose'
import ModalContext from '../../../../context/editor/ModalContext'
import {
	API_MANAGE_NPC_COMPOSE,
	GET_NPC_COMPOSE_BY_NPC,
} from '../../../../utils/constantsAdmin'
import { SearchCamp } from '../../searchAndPagination/SearchCamp'
import { NpcComposeContent } from './NpcComposeContent'
import { Pagination } from '../../searchAndPagination/Pagination'
import { useAuthProvider } from '../../../../context/AuthProvider/AuthProvider'
import { LoadingIcon } from '../../LoadingIcon'
import { useTranslation } from 'react-i18next'

export const ListNpcCompose = ({ idNpc }) => {
	const { t } = useTranslation()
	// AUTH PERMISSION
	const { gameUser, axiosSupreme } = useAuthProvider()

	//REFRESH NPCS COMPOSE
	const [refreshNpcsCompose, setRefreshNpcsCompose] = useState(false)

	// DATA
	const [data, setData] = useState(undefined)

	// GET ALL NPC COMPOSE
	useEffect(() => {
		getData()
	}, [refreshNpcsCompose])

	const getData = async () => {
		try {
			const result_data = await axiosSupreme(
				'get',
				GET_NPC_COMPOSE_BY_NPC + idNpc + '/'
			)
			setData(result_data)
		} catch (e) {
			console.log('Error get Data: ', e)
		}
	}

	// VALUES SEARCH
	const [search, setSearch] = useState('')

	// MODAL
	const { openModal, setTitleModal, setContentModal } =
		useContext(ModalContext)
	const OpenAddNpcsModal = () => {
		setTitleModal(t("pages.editor.components.list_npc_compose.add_npc"))
		setContentModal(
			<FormNpcCompose
				idNpc={idNpc}
				setRefreshNpcsCompose={setRefreshNpcsCompose}
			/>
		)
		openModal()
	}

	return (
		<div className='dataNpcCompose'>
			<div className='dataNpcCompose__titleAndAdd'>
				<h1 className='dataNpcCompose__titleAndAdd__title'>
					{t("pages.editor.components.list_npc_compose.list_composes")}
				</h1>
				<div className='dataNpcCompose__titleAndAdd__btnAdd'>
					{gameUser.permissions?.hasOwnProperty('add_npc_compose') ? (
						<button
							className='editorBtnActionAdd'
							onClick={OpenAddNpcsModal}>
							{t("common.add")}
						</button>
					) : null}
				</div>
			</div>
			{data !== undefined ? (
				<>
					{data === 'search' || data.results.length > 0 ? (
						<>
							<SearchCamp
								setData={setData}
								setRefreshDataList={setRefreshNpcsCompose}
								url={API_MANAGE_NPC_COMPOSE}
								search={search}
								setSearch={setSearch}
							/>
							{data === 'search' ? (
								<p className='noSelect'>
									{t("pages.editor.components.list_npc_compose.none_npc_found")}
								</p>
							) : null}
						</>
					) : (
						<p className='noSelect'>
							{t("pages.editor.components.list_npc_compose.none_npc_compose_found")}
						</p>
					)}
					{data !== 'search' ? (
						<>
							{data.results.map((data_item, index) => (
								<NpcComposeContent
									key={data_item.id_npc_compose}
									idNpc={idNpc}
									objectNpcCompose={data_item}
									setRefreshNpcsCompose={
										setRefreshNpcsCompose
									}
								/>
							))}
							<Pagination
								data={data}
								setData={setData}></Pagination>
						</>
					) : null}
				</>
			) : (
				<LoadingIcon />
			)}
		</div>
	)
}
