import { FeedbackStringTag } from "./FeedbackStringTag"
import { SpriteWithZoneAndCollisions } from "./SpriteWithZoneAndCollisions"
import { StringTag } from "./StringTag"

/**
 * This class defines a sprite who collides with the player.
 * It has a zone that makes this NPC greeting when the user collides with.
 * An NPC has a secuence that has the necessary contents to show a classs or
 * a game.
 *
 * Basically an NPC is a sprite who greets and shows a class or a game.
 */
export class NPC extends SpriteWithZoneAndCollisions {
	static types = { game: 'j', class: 'l' }

	// --------------
	#isCompleted = false // WOULD BE ONLY FOR MISSION NPC // NOT IN USE CURRENTLY
	#dialogues
	get dialogues() {
		return this.#dialogues
	}
	#audios = { greeting: { finish: '' } } // ----- MODIFICATION HERE: ADD THE .finish
	get audios() {
		return this.#audios
	}

	#missions
	get missions() {
		return this.#missions
	}

	#nameTag
	get nameTag() {
		return this.#nameTag
	}
	#greetingTag
	get greetingTag() {
		return this.#greetingTag
	}

	#feedbackTags
	get feedbackTags() {
		return this.#feedbackTags
	}

	#lockLetter
	get lockLetter() {
		return this.#lockLetter
	}
	#lockMessage = 'COMPLETA LAS MISIONES ANTERIORES PARA DESBLOQUEAR ESTE NPC'

	constructor(id, name, pos, spriteSheets, type, dialogues, missions) {
		super(id, name, pos, spriteSheets, type)
		this.#dialogues = dialogues
		this.#missions = missions
	}
	create(scene, bodyType, ignoreThis) {
		super.create(scene, bodyType, 1, 'NPC ZONE')

		// ----- CREATE REWARDS TAG
		this.#feedbackTags = new FeedbackStringTag(
			scene,
			{ x: this.pos.x, y: this.pos.y },
			['🔮' + this.#missions.reward, 'XP:' + this.#missions.xp]
		)

		if (ignoreThis) return // Easter Egg
		// ----- CREATE NAME TAG
		this.#nameTag = new StringTag(
			{ x: this.pos.x, y: this.pos.y + this.sprite.height / 2 + 10 },
			{ x: 0.5, y: 0 },
			'● ' + this.name[0].toUpperCase() + this.name.slice(1) + ' ●',
			'map__npc__name',
			true
		)
		this.#nameTag.create(scene)

		// ----- CREATE GREETING TAG
		this.#greetingTag = new StringTag(
			{ x: this.pos.x, y: this.pos.y + this.sprite.height / 2 + 10 },
			{ x: 0.5, y: 0 },
			this.#dialogues[0].translation,
			'map__npc__greeting',
			false
		)
		this.#greetingTag.create(scene)

		// ----- ANIMATION LOGIC
		this.sprite.on('animationcomplete', () => {
			this.sprite.anims.play(this.name + '-idle')
		})

		/// ----- LOCK LETTER
		this.#lockLetter = new StringTag(
			{ x: this.pos.x, y: this.pos.y - this.sprite.height / 2 - 10 },
			{ x: 0.5, y: 1 },
			this.#lockMessage,
			'map__door__locked',
			true
		)
		this.#lockLetter.create(scene)
		this.#lockLetter.enableVisibility(false)
	}
	switchStateTo(state) {
		if (state === 'normal') {
			this.#nameTag.enableVisibility(true)
			this.#greetingTag.enableVisibility(false)
		} else if (state === 'greeting') {
			// ----- SWITCH TAGS VISIBILITY
			this.#nameTag.enableVisibility(false)
			this.#greetingTag.enableVisibility(true)

			// ----- RUN THE ANIMATION (NPCS are configured to execute the idle anim once any other anim ends)
			this.sprite.anims.play(this.name + '-greeting')
		} else if (state === 'greetingInactive') {
			// ----- WHEN DIALOGUE IS OPENED

			// ----- HIDE THE GREETING TAG
			this.#greetingTag.enableVisibility(false)
		} else if (state === 'greetingActiveAgain') {
			// ----- WHEN DIALOGUE CLOSES

			// ----- SHOW THE GREETING TAG
			this.#greetingTag.enableVisibility(true)
		}
	}
	onStartZoneCollision(missionIsAvailable) {
		if (missionIsAvailable(this.#missions.id))
			this.switchStateTo('greeting')
		else this.#lockLetter.enableVisibility(true)
	}
	onEndZoneCollision() {
		// ----- CHANGE THE NPC STATE TO GREETING...
		this.switchStateTo('normal')
		this.#lockLetter.enableVisibility(false)
	}
	getVisualReward(scene, onlyXP) {
		this.#feedbackTags.play(scene, onlyXP)
	}
	destroyStart() {
		this.#nameTag?.container.setPosition(10000, 10000)
		this.#greetingTag?.container.setPosition(10000, 10000)
		this.#feedbackTags.destroyStart()
	}
	destroyEnd() {
		this.sprite.setPosition(10000, 10000)
	}
}
