import { useState } from 'react'
import AlertContext from '../context/editor/AlertContext'

const AlertProvider = (props) => {
	const [visible, setVisible] = useState(false)
	const [typeMessage, setTypeMessage] = useState('')
	const [message, setMessage] = useState('')

	const showAlert = () => {
		setVisible(true)
		setTimeout(() => {
			setVisible(false)
		}, 3000)
	}

	return (
		<AlertContext.Provider
			value={{
				visible,
				setVisible,
				showAlert,
				typeMessage,
				setTypeMessage,
				message,
				setMessage,
			}}>
			{props.children}
		</AlertContext.Provider>
	)
}

export default AlertProvider
