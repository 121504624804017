import { StringTag } from "./StringTag"

export class FeedbackStringTag {
	#initPos

	// 0: reward, 1: XP
	#stringTags = []
	get stringTags() {
		return this.#stringTags
	}

	constructor(scene, initPos, tags) {
		this.#initPos = initPos

		// ----- CREATE THE TAGS
		for (const text of tags) {
			const newStringTag = new StringTag(
				initPos,
				{ x: 0.5, y: 1 },
				text,
				'map__rewards',
				true
			)

			newStringTag.create(scene)
			newStringTag.enableVisibility(false)

			this.#stringTags.push(newStringTag)
		}
	}

	play(scene, onlyXP, texts) {
		const delayTime = 1200
		let delay = 0
		let index = 0
		for (const stringTag of this.#stringTags) {
			if (texts) {
				if (stringTag.spanText) {
					stringTag.spanText.innerText = texts[index]
				} else {
					stringTag.element.setHTML(texts[index])
					stringTag.element.setPosition(-90, 0)
				}
			}

			if (onlyXP && delay === 0) {
				delay = 1
				continue
			}
			index += 1
			const timeout = setTimeout(() => {
				clearTimeout(timeout)

				scene.tweens.add({
					targets: stringTag.container,
					y: this.#initPos.y - 150,
					duration: 2200,
					ease: 'power4',
					repeat: 0,
					onComplete: () => {
						stringTag.enableVisibility(false)
						stringTag.container.setPosition(
							this.#initPos.x,
							this.#initPos.y
						)
					},
				})
				scene.tweens.add({
					targets: stringTag.container,
					alpha: 1,
					duration: 100,
					repeat: 0,
				})
				scene.tweens.add({
					targets: stringTag.container,
					alpha: 0,
					duration: 200,
					delay: 2000,
					repeat: 0,
				})
			}, delay)
			delay += delayTime
		}
	}

	destroyStart() {
		for (const stringTag of this.#stringTags) {
			stringTag.container.setPosition(10000, 10000)
		}
	}
}
