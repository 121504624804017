import React, { useContext, useEffect, useRef, useState } from 'react'
import AlertContext from '../../../../context/editor/AlertContext'
import ModalContext from '../../../../context/editor/ModalContext'

import {
	API_MANAGE_NPC_COMPOSE,
	API_MANAGE_NPC_OUTFITS,
	API_MANAGE_NPC_POSES,
	API_MANAGE_NPC_STATES,
	API_MANAGE_SOUNDS,
} from '../../../../utils/constantsAdmin'
import SaveButton from '../../SaveButton'
import {
	onInputChangeDefault,
	onInputChangeImage,
	processErrors,
	returnProcessUrl,
} from '../../../../utils/general_functions_forms'
import { LoadingIcon } from '../../LoadingIcon'
import { useAuthProvider } from '../../../../context/AuthProvider/AuthProvider'
import { useTranslation } from 'react-i18next'

export const FormNpcCompose = ({
	idNpc,
	stateObjectNpcCompose,
	setStateObjectNpcCompose,
	setRefreshNpcsCompose,
}) => {
	const { gameUser, axiosSupreme } = useAuthProvider()
	// ALERT
	const { showAlert, setMessage, setTypeMessage } = useContext(AlertContext)

	// MODAL
	const { openModal } = useContext(ModalContext)

	// VALUES FORM
	const [frameSizeX, setFrameSizeX] = useState('')
	const [frameSizeY, setFrameSizeY] = useState('')
	const [amountFrames, setAmountFrames] = useState('')
	const [durationFrames, setDurationFrames] = useState('')
	const imageFile = useRef(null)
	const [npcOutfit, setNpcOutfit] = useState('')
	const [npcPose, setNpcPose] = useState('')
	const [npcState, setNpcState] = useState('')
	const [sound, setSound] = useState('')
	const { t } = useTranslation()

	// ERROR FOR FORM
	const [errorImage, setErrorImage] = useState('')

	// GET ALL SOUNDS
	const [listSounds, setListSounds] = useState({})
	useEffect(() => {
		fetchOptionsSounds(axiosSupreme, API_MANAGE_SOUNDS, setListSounds)
	}, [])

	// GET ALL OUTFITS
	const [listNpcOutfit, setListNpcOutfit] = useState({})
	useEffect(() => {
		fetchOptionsNpcOutfits(
			axiosSupreme,
			API_MANAGE_NPC_OUTFITS,
			setListNpcOutfit
		)
	}, [])

	// GET ALL POSES
	const [listNpcPoses, setListNpcPoses] = useState({})
	useEffect(() => {
		fetchOptionsNpcPoses(
			axiosSupreme,
			API_MANAGE_NPC_POSES,
			setListNpcPoses
		)
	}, [])

	// GET ALL STATES
	const [listNpcStates, setListNpcStates] = useState({})
	useEffect(() => {
		fetchOptionsNpcStates(
			axiosSupreme,
			API_MANAGE_NPC_STATES,
			setListNpcStates
		)
	}, [])

	// LOADING
	const [stateLoading, setStateLoading] = useState(false)

	// CLEAR VALUES FOR FIELDS
	const clearFields = () => {
		setFrameSizeX('')
		setFrameSizeY('')
		setAmountFrames('')
		setDurationFrames('')
		imageFile.current.value = null
	}

	// SET WORLD WHEN UPDATE
	useEffect(() => {
		if (stateObjectNpcCompose !== undefined) {
			if (stateObjectNpcCompose.frame_size) {
				setFrameSizeX(
					stateObjectNpcCompose.frame_size
						.replace('[', '')
						.replace(']', '')
						.split(',')[0]
				)
				setFrameSizeY(
					stateObjectNpcCompose.frame_size
						.replace('[', '')
						.replace(']', '')
						.split(',')[1]
				)
			}
			setAmountFrames(stateObjectNpcCompose.frames_amount)
			setDurationFrames(stateObjectNpcCompose.frames_duration)
			if (stateObjectNpcCompose.npc_outfit) {
				setNpcOutfit(stateObjectNpcCompose.npc_outfit.id_outfit_npc)
			}
			if (stateObjectNpcCompose.npc_pose) {
				setNpcPose(stateObjectNpcCompose.npc_pose.id_npc_pose)
			}
			if (stateObjectNpcCompose.npc_state) {
				setNpcState(stateObjectNpcCompose.npc_state.id_state_npc)
			}
			if (stateObjectNpcCompose.sound) {
				setSound(stateObjectNpcCompose.sound.id_sound)
			}
		} else {
			clearFields()
		}
	}, [stateObjectNpcCompose])

	const handleKeyPress = (event) => {
		const charCode = event.charCode || event.keyCode
		if ((charCode < 48 || charCode > 57) && charCode != 46) {
			event.preventDefault()
		}
	}

	// SUBMIT FORM NPC
	const onSubmitNpcCompose = async (event) => {
		event.preventDefault()
		setStateLoading(true)
		// MAKE DICT
		let formData = new FormData()
		formData.append(
			'name',
			listNpcOutfit[npcOutfit].name +
				' - ' +
				listNpcPoses[npcPose].name +
				' - ' +
				listNpcStates[npcState].name
		)
		if (
			event.target.elements.frameSizeX.value &&
			event.target.elements.frameSizeY.value
		) {
			formData.append(
				'frame_size',
				'[' + frameSizeX + ',' + frameSizeY + ']'
			)
		}
		formData.append(
			'frames_amount',
			event.target.elements.frames_amount.value
		)
		formData.append(
			'frames_duration',
			event.target.elements.frames_duration.value
		)
		if (event.target.elements.image_file.value) {
			formData.append(
				'image_file',
				event.target.elements.image_file.files[0]
			)
		}
		formData.append('npc', idNpc)

		if (event.target.elements.npc_outfit.value !== 'Selecciona el outfit') {
			formData.append('npc_outfit', npcOutfit)
		} else {
			formData.append('npc_outfit', '')
		}

		if (event.target.elements.npc_pose.value !== 'Selecciona la pose') {
			formData.append('npc_pose', npcPose)
		} else {
			formData.append('npc_pose', '')
		}

		if (event.target.elements.npc_state.value !== 'Selecciona el estado') {
			formData.append('npc_state', npcState)
		} else {
			formData.append('npc_state', '')
		}
		formData.append('sound', sound ? sound : '')

		if (stateObjectNpcCompose === undefined) {
			// SAVE
			manageNpcCompose(API_MANAGE_NPC_COMPOSE, formData, 'add')
		} else {
			// UPDATE
			manageNpcCompose(
				API_MANAGE_NPC_COMPOSE +
					stateObjectNpcCompose.id_npc_compose +
					'/',
				formData,
				'update'
			)
		}
	}

	// FUNCTION FOR SAVE OR UPDATE ANIMATION
	const manageNpcCompose = async (url, obj, action) => {
		try {
			// PROCESS DATA
			let result_data =
				action === 'add'
					? await axiosSupreme('post', url, obj)
					: await axiosSupreme('patch', url, obj)
			clearFields()
			setTypeMessage('success')
			setMessage(
				action === 'add'
					? t("common.added")
					: t("common.modified")
			)
			showAlert()
			if (action === 'add') setRefreshNpcsCompose((prev) => !prev)
			if (action === 'update') getNewObject()
			openModal()
		} catch (errorPromise) {
			setTypeMessage('error')
			setMessage(t("errors.request_error"))
			if (errorPromise.response.status === 403) {
				setMessage(
					t("common.insufficient_permissions")
				)
			}
			if (errorPromise.response.status === 400) {
				let error = processErrors(errorPromise.response.data)
				setMessage(error)
			}
			showAlert()
		}
		setStateLoading(false)
	}

	const getNewObject = async () => {
		const result_data = await axiosSupreme(
			'get',
			API_MANAGE_NPC_COMPOSE + stateObjectNpcCompose.id_npc_compose + '/',
			undefined
		)
		setStateObjectNpcCompose(result_data)
	}

	return (
		<form onSubmit={onSubmitNpcCompose}>
			<div
				className='admin__container__inputs'
				id='admin__container__inputs'>
				<label
					className='admin__container__inputs__title'
					forhtml='admin__container__inputs__title'>
					{t("pages.editor.components.form_npc_compose.appearance")}
				</label>
				{stateObjectNpcCompose !== undefined ? (
					<>
						{stateObjectNpcCompose.image_file !== null ? (
							<label htmlFor=''>
								{t("common.current_file")}
								<a
									href={stateObjectNpcCompose.image_file}
									target='_blank'
									rel='noopener noreferrer'>
									{t("common.open")}
								</a>
							</label>
						) : null}
					</>
				) : null}
				<input
					name='image_file'
					className='admin__container__inputs__in'
					id='admin__container__inputs__in'
					type='file'
					ref={imageFile}
					autoComplete='off'
					onChange={(event) =>
						onInputChangeImage(event, setErrorImage, imageFile)
					}
					accept='image/*'
					required={stateObjectNpcCompose === undefined}></input>
				{errorImage && <p>{errorImage}</p>}
			</div>

			<div
				className='admin__container__inputsCoordenadas'
				id='admin__container__inputsCoordenadas'>
				<label
					className='admin__container__inputsCoordenadas__title'
					forhtml='admin__container__inputsCoordenadas__title'>
					{t("pages.editor.components.form_npc_compose.frame_size")}
				</label>
				<div className='admin__container__inputsCoordenadas__columns'>
					<input
						maxLength='45'
						name='frameSizeX'
						className='admin__container__inputsCoordenadas__columns__in'
						id='frameSizeX'
						type='text'
						placeholder={t("pages.editor.components.form_npc_compose.placeholder_width")}
						autoComplete='off'
						value={frameSizeX}
						onChange={(event) =>
							onInputChangeDefault(event, setFrameSizeX)
						}
						onKeyPress={(event) => handleKeyPress(event)}
						required></input>
					<input
						maxLength='45'
						name='frameSizeY'
						className='admin__container__inputsCoordenadas__columns__in'
						id='frameSizeY'
						type='text'
						placeholder={t("pages.editor.components.form_npc_compose.placeholder_height")}
						autoComplete='off'
						value={frameSizeY}
						onChange={(event) =>
							onInputChangeDefault(event, setFrameSizeY)
						}
						onKeyPress={(event) => handleKeyPress(event)}
						required></input>
				</div>
			</div>

			<div
				className='admin__container__inputs'
				id='admin__container__inputs'>
				<label
					className='admin__container__inputs__title'
					forhtml='admin__container__inputs__title'>
					{t("pages.editor.components.form_npc_compose.amount_frames")}
				</label>
				<input
					maxLength='45'
					name='frames_amount'
					className='admin__container__inputs__in'
					id='frames_amount'
					type='text'
					placeholder={t("pages.editor.components.form_npc_compose.amount_frames_placeholder")}
					autoComplete='off'
					value={amountFrames}
					onChange={(event) =>
						onInputChangeDefault(event, setAmountFrames)
					}
					onKeyPress={(event) => handleKeyPress(event)}
					required></input>
			</div>

			<div
				className='admin__container__inputs'
				id='admin__container__inputs'>
				<label
					className='admin__container__inputs__title'
					forhtml='admin__container__inputs__title'>
					{t("pages.editor.components.form_npc_compose.frame_duration")}
				</label>
				<input
					maxLength='45'
					name='frames_duration'
					className='admin__container__inputs__in'
					id='frames_duration'
					type='text'
					placeholder={t("pages.editor.components.form_npc_compose.frame_duration_placeholder")}
					autoComplete='off'
					value={durationFrames}
					onChange={(event) =>
						onInputChangeDefault(event, setDurationFrames)
					}
					onKeyPress={(event) => handleKeyPress(event)}
					required></input>
			</div>

			<div
				className='admin__container__inputs'
				id='admin__container__inputs'>
				<label
					className='admin__container__inputs__title'
					forhtml='admin__container__inputs__title'>
					{t("pages.editor.components.form_npc_compose.list_outfit")}
				</label>
				<select
					name='npc_outfit'
					className='admin__container__inputs__in'
					id='npc_outfit'
					placeholder={t("pages.editor.components.form_npc_compose.list_outfit_placeholder")}
					autoComplete='off'
					value={npcOutfit}
					onChange={(event) =>
						onInputChangeDefault(event, setNpcOutfit)
					}
					required>
					<option key='' value=''>
					{t("pages.editor.components.form_npc_compose.select_outfit")}
					</option>
					{Object.keys(listNpcOutfit).map((key) => (
						<option key={key} value={key}>
							{listNpcOutfit[key].name}
						</option>
					))}
				</select>
			</div>

			<div
				className='admin__container__inputs'
				id='admin__container__inputs'>
				<label
					className='admin__container__inputs__title'
					forhtml='admin__container__inputs__title'>
					{t("pages.editor.components.form_npc_compose.list_poses")}
				</label>
				<select
					name='npc_pose'
					className='admin__container__inputs__in'
					id='npc_pose'
					placeholder={t("pages.editor.components.form_npc_compose.list_poses_placeholder")}
					autoComplete='off'
					value={npcPose}
					onChange={(event) =>
						onInputChangeDefault(event, setNpcPose)
					}
					required>
					<option key='' value=''>
					{t("pages.editor.components.form_npc_compose.select_pose")}
					</option>
					{Object.keys(listNpcPoses).map((key) => (
						<option key={key} value={key}>
							{listNpcPoses[key].name}
						</option>
					))}
				</select>
			</div>

			<div
				className='admin__container__inputs'
				id='admin__container__inputs'>
				<label
					className='admin__container__inputs__title'
					forhtml='admin__container__inputs__title'>
					{t("pages.editor.components.form_npc_compose.list_state")}
				</label>
				<select
					name='npc_state'
					className='admin__container__inputs__in'
					id='npc_state'
					placeholder={t("pages.editor.components.form_npc_compose.list_state_placeholder")}
					autoComplete='off'
					value={npcState}
					onChange={(event) =>
						onInputChangeDefault(event, setNpcState)
					}
					required>
					<option key='' value=''>
					{t("pages.editor.components.form_npc_compose.select_state")}
					</option>
					{Object.keys(listNpcStates).map((key) => (
						<option key={key} value={key}>
							{listNpcStates[key].name}
						</option>
					))}
				</select>
			</div>

			<div
				className='admin__container__inputs'
				id='admin__container__inputs'>
				<label
					className='admin__container__inputs__title'
					forhtml='admin__container__inputs__title'>
					{t("pages.editor.components.form_npc_compose.sound")}
				</label>
				<select
					name='sound'
					className='admin__container__inputs__in'
					id='sound'
					placeholder={t("pages.editor.components.form_npc_compose.sound_placeholder")}
					autoComplete='off'
					value={sound}
					onChange={(event) => onInputChangeDefault(event, setSound)}>
					<option key='' value=''>
					{t("pages.editor.components.form_npc_compose.select_sound")}
					</option>
					{Object.keys(listSounds).map((key) => (
						<option key={key} value={key}>
							{listSounds[key].name}
						</option>
					))}
				</select>
			</div>

			{stateLoading === false ? (
				<div className='admin__container__boxBtn'>
					<SaveButton></SaveButton>
				</div>
			) : (
				<LoadingIcon />
			)}
		</form>
	)
}

const fetchOptionsNpcOutfits = async (axiosSupreme, url, setListNpcOutfit) => {
	url = returnProcessUrl(url)
	let result_data = await axiosSupreme('get', url, undefined)
	result_data.results.map((data_item) => {
		setListNpcOutfit((prevData) => ({
			...prevData,
			[data_item.id_outfit_npc]: {
				name: data_item.name,
			},
		}))
	})
	if (result_data.next) {
		fetchOptionsNpcOutfits(axiosSupreme, result_data.next, setListNpcOutfit)
	}
}

const fetchOptionsNpcPoses = async (axiosSupreme, url, setListNpcPoses) => {
	url = returnProcessUrl(url)
	let result_data = await axiosSupreme('get', url, undefined)
	result_data.results.map((data_item) => {
		setListNpcPoses((prevData) => ({
			...prevData,
			[data_item.id_npc_pose]: {
				name: data_item.name,
			},
		}))
	})
	if (result_data.next) {
		fetchOptionsNpcPoses(axiosSupreme, result_data.next, setListNpcPoses)
	}
}

const fetchOptionsNpcStates = async (axiosSupreme, url, setListNpcStates) => {
	url = returnProcessUrl(url)
	let result_data = await axiosSupreme('get', url, undefined)
	result_data.results.map((data_item) => {
		setListNpcStates((prevData) => ({
			...prevData,
			[data_item.id_state_npc]: {
				name: data_item.name,
			},
		}))
	})
	if (result_data.next) {
		fetchOptionsNpcStates(axiosSupreme, result_data.next, setListNpcStates)
	}
}

const fetchOptionsSounds = async (axiosSupreme, url, setList) => {
	url = returnProcessUrl(url)
	let result_data = await axiosSupreme('get', url, undefined)
	result_data.results.map((data_item) => {
		setList((prevData) => ({
			...prevData,
			[data_item.id_sound]: {
				name: data_item.name,
			},
		}))
	})
	if (result_data.next) {
		fetchOptionsSounds(axiosSupreme, result_data.next, setList)
	}
}
