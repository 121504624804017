import { useEffect, useRef, useState } from 'react'
import volumeOnIcon from '../../assets/icons/volumeOn.png'

import '../../styles/AudioHelpButton.scss'
import { useSceneConfig } from '../../context/SceneConfigProvider'

const AudioHelpButton = ({ src, style = {}, styleChild = {} }) => {
	const { soundPointers } = useSceneConfig()
	const [isValidSource, setIsValidSource] = useState(false)
	const audio = useRef(new Audio())

	useEffect(() => {
		if (src) {
			try {
				new URL(src)
			} catch (error) {
				setIsValidSource(false)
				return
			}
			audio.current.src = src
			audio.current.addEventListener(
				'loadeddata',
				() => {
					setIsValidSource(true)
				},
				{ once: true }
			)
		}
	}, [src])

	/**
	 * Perfect, now, every game, make a sound play once they are right.
	 * So I need to do exactly this, but different.
	 */

	return (
		<div className='audio__help__button' style={style}>
			<button
				className='audio__help__button__container'
				style={styleChild}
				disabled={!isValidSource}
				onClick={() => {
					const { volume } = soundPointers.voices
					if (isValidSource && volume !== '0') {
						audio.current.volume = Number.isNaN(Number(volume))
							? 1
							: Number(volume)
						audio.current.play()
					}
				}}>
				<img
					src={volumeOnIcon}
					className='audio__help__button__container__image'
					alt='icon play audio'
					loading='lazy'
				/>
			</button>
		</div>
	)
}

export default AudioHelpButton
