import { ClickableNPC } from "./ClickableNPC"

export class NPCClass extends ClickableNPC {
	onActivityCompleted(updateMission, earnedStars, missionIsCompleted) {
		if (missionIsCompleted(this.missions.id)) return
		updateMission(this.missions.id, earnedStars, false)
	}
	onActivityClosed(
		scene,
		missionIsCompleted,
		player,
		setActivityState,
		setActivity,
		gameStatus,
		soundAction
	) {
		super.onActivityClosed(player, setActivityState, setActivity)
		super.randomiseContentItems(setActivity)

		if (!missionIsCompleted(this.missions.id)) return
		if (this.closedAfterFirstTimeCompleted) return
		this.closedAfterFirstTimeCompleted = true

		const timeout = setTimeout(() => {
			clearTimeout(timeout)
			super.getVisualReward(scene)
			soundAction('effect', 'TLSI5', 'play')
		}, 500)
	}
}
