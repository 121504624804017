import React, { useContext, useEffect, useState } from 'react'
import SaveButton from '../../SaveButton'
import AlertContext from '../../../../context/editor/AlertContext'
import ModalContext from '../../../../context/editor/ModalContext'
import { API_MANAGE_GROUPS_ADMIN } from '../../../../utils/constantsAdmin'

import { onInputChangeDefault } from '../../../../utils/general_functions_forms'
import { ManagePermissions } from '../Permissions/ManagePermissions'
import { useAuthProvider } from '../../../../context/AuthProvider/AuthProvider'

export default function GroupForm({
	stateObjectGroup,
	setStateObjectGroup,
	setRefreshGroups,
}) {
	// AUTH PERMISSION
	const { gameUser, axiosSupreme } = useAuthProvider()

	const [contenidoAbierto, setContenidoAbierto] = useState('BasicData')
	const handleClick = (contenido) => {
		setContenidoAbierto(contenido)
	}
	// ALERT
	const { showAlert, setMessage, setTypeMessage } = useContext(AlertContext)
	// MODAL
	const { openModal } = useContext(ModalContext)
	// VALUES FORM
	const [name, setName] = useState('')
	// CLEAR VALUES FOR FIELDS
	const clearFields = () => {
		setName('')
	}
	// LOADING
	const [stateLoading, setStateLoading] = useState(false)
	// SET GROUP WHEN UPDATE
	useEffect(() => {
		if (stateObjectGroup !== undefined) {
			setName(stateObjectGroup.name)
		} else {
			clearFields()
		}
	}, [stateObjectGroup])
	// SUBMIT FORM GROUP
	const onSubmitGroup = async (event) => {
		event.preventDefault()
		setStateLoading(true)
		// MAKE DICT
		let formData = new FormData()
		formData.append('name', event.target.elements.name.value)

		if (stateObjectGroup === undefined) {
			// SAVE
			manageGroup(API_MANAGE_GROUPS_ADMIN, formData, 'add')
		} else {
			// UPDATE
			manageGroup(
				API_MANAGE_GROUPS_ADMIN + stateObjectGroup.id + '/',
				formData,
				'update'
			)
		}
	}
	// FUNCTION FOR SAVE OR UPDATE GROUP
	const manageGroup = async (url, obj, action) => {
		// PROCESS DATA
		let result_data =
			action === 'add'
				? await axiosSupreme('post', url, obj)
				: await axiosSupreme('put', url, obj)
		if (result_data.hasOwnProperty('response')) {
			if (result_data.response.status === 403) {
				setTypeMessage('error')
				setMessage(
					'No tienes los permisos suficientes para realizar esta acción'
				)
			}
			if (result_data.response.status === 400) {
				setTypeMessage('error')
				let error = ''
				Object.keys(result_data.response.data).map((key) => {
					error += key
					for (
						let i = 0;
						i < result_data.response.data[key].length;
						i++
					) {
						error +=
							': \t' + result_data.response.data[key][0] + '\n'
					}
					error += '\n'
					return error
				})
				setTypeMessage('error')
				setMessage(error)
			}
			showAlert()
			setStateLoading(false)
		} else {
			clearFields()
			setTypeMessage('success')
			setMessage(
				action === 'add'
					? 'Agregado correctamente'
					: 'Actualizado correctamente'
			)
			showAlert()
			if (action === 'add') setRefreshGroups((prev) => !prev)
			if (action === 'update') setStateObjectGroup(result_data)
			setStateLoading(false)
			openModal()
		}
	}
	return (
		<>
			{stateObjectGroup === undefined ? (
				<div className='btnsManageGroupDataTwo'>
					<button
						className='btnAdd'
						onClick={() => handleClick('BasicData')}>
						Info Basica
					</button>
				</div>
			) : (
				<div className='btnsManageGroupData'>
					<button
						className='btnAdd'
						onClick={() => handleClick('BasicData')}>
						Info Basica
					</button>
					{gameUser.permissions?.hasOwnProperty('view_permission') ? (
						<button
							className='btnAdd'
							onClick={() => handleClick('ManageGroupPerms')}>
							Gestion de permisos
						</button>
					) : null}
				</div>
			)}

			{contenidoAbierto === 'BasicData' && (
				<div className='container_form'>
					<form onSubmit={onSubmitGroup}>
						<div
							className='admin__container__inputs'
							id='admin__container__inputs'>
							<label
								className='admin__container__inputs__title'
								forhtml='admin__container__inputs__title'>
								Nombre*
							</label>
							<input
								maxLength='45'
								name='name'
								className='admin__container__inputs__in'
								id='name'
								type='text'
								placeholder='Nombre'
								autoComplete='off'
								value={name}
								onChange={(event) =>
									onInputChangeDefault(event, setName)
								}
								required></input>
						</div>
						<div className='admin__container__boxBtn'>
							<SaveButton></SaveButton>
						</div>
					</form>
				</div>
			)}
			{contenidoAbierto === 'ManageGroupPerms' && (
				<div className='container_form'>
					{gameUser.permissions?.hasOwnProperty('view_permission') ? (
						<ManagePermissions
							stateObjectGroup={stateObjectGroup}
						/>
					) : null}
				</div>
			)}
		</>
	)
}
