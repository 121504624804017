import { useState } from "react"

export const useLocalStoryteller = (sceneData) => {
	const [storyteller, setStoryteller] = useState({
		isActive: sceneData.storyteller !== undefined ? true : false,
		open: function () {
			setStoryteller((st) => ({
				...st,
				isActive: true,
			}))
		},
		close: function () {
			setStoryteller((st) => ({
				...st,
				isActive: false,
			}))
		},
	})

	return [storyteller, setStoryteller]
}
