import { useRef, useState } from "react"

export const useLocalProgressBar = () => {
	/**
     * An array (where order matters) that contains the data of each circle that should be in
     * the progress bar: {
            ID: requestedData.id_mission,
            NPCID: requestedData.id_npc,
            description: requestedData.description,
            image: BASE_API + requestedData.npc_face,
            NPCName: requestedData.npc_name,
            object: requestedData.object,
            reward: requestedData.reward,
            state: requestedData.status,
        }
     * so we can then iterate over each one of those and render the progress bar.
     */
	const [progressBarData, setProgressBarData] = useState(null)
	const progressBarDataRef = useRef(null)

	return [progressBarData, setProgressBarData, progressBarDataRef]
}
