import { useTranslation } from "react-i18next"

export const GameBodyCollapse = ({ stateObjectGame }) => {
	const { t } = useTranslation()
	return (
		<div className='gameContent collapseStylesBody'>
			{stateObjectGame !== undefined ? (
				<>
					<div className='gameContent__container'>
						<div className='gameContent__container__data'>
							<div className='info'>
								<h1 className='titleDesc'>
									{t("pages.editor.components.game_body_collapse.translation_name")}
								</h1>
								{stateObjectGame.name_translation ? (
									<p>{stateObjectGame.name_translation}</p>
								) : (
									<p>{t("pages.editor.components.game_body_collapse.translation_name_not_assigned")}</p>
								)}
							</div>
							<div className='info'>
								<h1 className='titleDesc'>Descripcion</h1>
								{stateObjectGame.description ? (
									<p>{stateObjectGame.description}</p>
								) : (
									<p>{t("pages.editor.components.game_body_collapse.description_not_assigned")}</p>
								)}
							</div>
							<div className='info'>
								<h1 className='titleDesc'>
									{t("pages.editor.components.game_body_collapse.translation_description")}
								</h1>
								{stateObjectGame.description_translation ? (
									<p>
										{
											stateObjectGame.description_translation
										}
									</p>
								) : (
									<p>{t("pages.editor.components.game_body_collapse.translation_description_not_assigned")}</p>
								)}
							</div>
							<div className='info'>
								<h1 className='titleDesc'>{t("pages.editor.components.game_body_collapse.sound")}</h1>
								{stateObjectGame.sound ? (
									<audio
										src={stateObjectGame.sound.audio_file}
										controls></audio>
								) : (
									<p>{t("pages.editor.components.game_body_collapse.no_sound_selected")}</p>
								)}
							</div>
						</div>
					</div>
				</>
			) : null}
		</div>
	)
}
