import { useState } from 'react'
import { ListCategories } from './Categories/ListCategories'
import { ListItems } from './Items/ListItems'
import { useAuthProvider } from '../../../../context/AuthProvider/AuthProvider'
import { useTranslation } from 'react-i18next'

export const StoreContent = () => {
	const { t } = useTranslation()
	// AUTH PERMISSION
	const { gameUser, axiosSupreme } = useAuthProvider()

	const [openCategories, setOpenCategories] = useState(false)
	const [openItems, setOpenItems] = useState(false)

	const openContainer = (setFunction) => {
		setOpenCategories(false)
		setOpenItems(false)
		setFunction(true)
	}

	return (
		<div className='adminDesign__container__mainContainerBank'>
			<div className='adminDesign__container__mainContainerBank__Item'>
				<div className='adminDesign__container__mainContainerBank__Item__title'>
					<h1>{t("pages.editor.components.store_content.admin_items_store")}</h1>
				</div>
				<div className='adminDesign__container__mainContainerBank__Item__content'>
					<hr />
					<div className='storeAdmin'>
						<div className='storeAdmin__buttons'>
							{gameUser.permissions?.hasOwnProperty(
								'view_item_category'
							) ? (
								<button
									className={
										openCategories === true
											? 'editorBtnActionAddActive'
											: 'editorBtnActionAdd'
									}
									onClick={() =>
										openContainer(setOpenCategories)
									}>
									{t("pages.editor.components.store_content.admin_categories")}
								</button>
							) : null}
							{gameUser.permissions?.hasOwnProperty(
								'view_item'
							) ? (
								<button
									className={
										openItems === true
											? 'editorBtnActionAddActive'
											: 'editorBtnActionAdd'
									}
									onClick={() => openContainer(setOpenItems)}>
									{t("pages.editor.components.store_content.admin_items")}
								</button>
							) : null}
						</div>
						{openCategories ? (
							gameUser.permissions?.hasOwnProperty(
								'view_item_category'
							) ? (
								<ListCategories />
							) : null
						) : null}
						{openItems ? (
							gameUser.permissions?.hasOwnProperty(
								'view_item'
							) ? (
								<ListItems></ListItems>
							) : null
						) : null}
					</div>
				</div>
			</div>
		</div>
	)
}
