import { useEffect, useState } from 'react'
import { AiOutlineFileGif, AiOutlineUsergroupAdd } from 'react-icons/ai'
import { MdOutlineDoorSliding } from 'react-icons/md'
import '../../../styles/ObjectsAdmin.scss'
import { Alert } from '../../../components/editor/Alert'
import Modal from '../../../components/editor/Modal'

import { useNavigate } from 'react-router-dom'
import { ListDecorationsScene } from '../../../components/editor/design/DecorationsScene/ListDecorationsScene'
import { API_MANAGE_SCENE_WORLDS } from '../../../utils/constantsAdmin'
import { ListDoorScene } from '../../../components/editor/design/DoorsScene/ListDoorScene'
import { useAuthProvider } from '../../../context/AuthProvider/AuthProvider'
import TitleAdministratorAndMenu from '../../../components/editor/TitleAdministratorAndMenu'
import { API_MANAGE_SCENE_SUB_WORLDS } from '../../../utils/constantsAdmin'
import { NpcAppearanceScene } from '../../../components/editor/design/NpcAppearanceScene/NpcAppearanceScene'
import { BackButton } from '../../../components/editor/BackButton'
import { ListStoryTeller } from '../../../components/editor/design/StoryTeller/ListStoryTeller'
import ModalDelete from '../../../components/editor/ModalDelete'
import { ErrorPerms } from '../../../components/editor/ErrorPerms'
import useAuthNavigator from '../../../context/AuthProvider/useAuthNavigator'

export default function ManageSceneWorld() {
	return useAuthNavigator(<PrivatePage />)
}

function PrivatePage() {
	// AUTH PERMISSION
	const { gameUser, axiosSupreme } = useAuthProvider()

	// RESET SCROLL
	window.scroll(0, 0)

	let typeContextSpace = localStorage.getItem('TYPE_CONTEXT_SPACE')
	let idScene = localStorage.getItem('ID_SCENE')
	let itemDesigner = localStorage.getItem('ITEM_DESIGNER')

	// VALIDATE PREVIOUS ROUTE
	const navigate = useNavigate()
	const validateRoute = () => {
		if (
			localStorage.getItem('TYPE_CONTEXT_SPACE') == null ||
			localStorage.getItem('ID_SCENE') == null ||
			localStorage.getItem('ITEM_DESIGNER') == null
		) {
			navigate('/editor')
		}
	}
	useEffect(() => {
		validateRoute()
		if (
			itemDesigner === 'StoryTeller' &&
			gameUser.permissions?.hasOwnProperty('view_storyteller')
		) {
			setStatusStoryTeller(true)
		} else if (
			itemDesigner === 'Decorations' &&
			((typeContextSpace === 'World' &&
				gameUser.permissions?.hasOwnProperty(
					'view_world_scene_decoration'
				)) ||
				(typeContextSpace === 'Subworld' &&
					gameUser.permissions?.hasOwnProperty(
						'view_sub_world_scene_decoration'
					)))
		) {
			setStatusDecorations(true)
		} else if (
			itemDesigner === 'Doors' &&
			((typeContextSpace === 'World' &&
				gameUser.permissions?.hasOwnProperty('view_world_door')) ||
				(typeContextSpace === 'Subworld' &&
					gameUser.permissions?.hasOwnProperty(
						'view_sub_world_door'
					)))
		) {
			setStatusDoors(true)
		} else if (
			itemDesigner === 'Npcs' &&
			gameUser.permissions?.hasOwnProperty(
				'view_sub_world_scene_npc_appearance'
			)
		) {
			setStatusNpc(true)
		}
	}, [])

	// GET DETAIL SCENE
	const [dataScene, setDataScene] = useState(undefined)

	const [statusNpc, setStatusNpc] = useState(false)
	const [statusDoors, setStatusDoors] = useState(false)
	const [statusStoryTeller, setStatusStoryTeller] = useState(false)
	const [statusDecorations, setStatusDecorations] = useState(false)

	useEffect(() => {
		if (
			gameUser.permissions?.hasOwnProperty(
				'view_designer_administration'
			) &&
			((typeContextSpace === 'World' &&
				gameUser.permissions?.hasOwnProperty('view_world_scene')) ||
				(typeContextSpace === 'Subworld' &&
					gameUser.permissions?.hasOwnProperty(
						'view_sub_world_scene'
					)))
		) {
			getDetailWorldSubworld()
		}
	}, [])

	const getDetailWorldSubworld = async () => {
		const result_data =
			typeContextSpace == 'World'
				? await axiosSupreme(
						'get',
						API_MANAGE_SCENE_WORLDS + idScene + '/',
						undefined
				  )
				: await axiosSupreme(
						'get',
						API_MANAGE_SCENE_SUB_WORLDS + idScene + '/',
						undefined
				  )
		setDataScene(result_data)
	}

	const clickItem = (setFuntion) => {
		setStatusNpc(false)
		setStatusDecorations(false)
		setStatusDoors(false)
		setStatusStoryTeller(false)
		setFuntion(true)
		if (setFuntion === setStatusNpc) {
			localStorage.setItem('ITEM_DESIGNER', 'Npcs')
		} else if (setFuntion === setStatusDecorations) {
			localStorage.setItem('ITEM_DESIGNER', 'Decorations')
		} else if (setFuntion === setStatusDoors) {
			localStorage.setItem('ITEM_DESIGNER', 'Doors')
		} else if (setFuntion === setStatusStoryTeller) {
			localStorage.setItem('ITEM_DESIGNER', 'StoryTeller')
		}
	}

	return (
		<div className='objectsAdmin'>
			<Alert></Alert>
			<Modal></Modal>
			<ModalDelete></ModalDelete>
			<BackButton url={'/editor'}></BackButton>
			<div className='objectAdmin__containerGen'>
				<div className='objectsAdmin__containerGen__title'>
					<TitleAdministratorAndMenu
						name={
							typeContextSpace === 'World'
								? 'Escenas de mundo'
								: 'Escenas de submundo'
						}></TitleAdministratorAndMenu>
					{typeContextSpace === 'World' ? (
						<h2>
							{' '}
							{dataScene !== undefined
								? dataScene.world.order +
								  '. ' +
								  dataScene.world.name +
								  ' - ' +
								  dataScene.name
								: null}{' '}
						</h2>
					) : (
						<h1>
							{' '}
							{dataScene !== undefined
								? dataScene.sub_world.order +
								  '. ' +
								  dataScene.sub_world.name +
								  ' - ' +
								  dataScene.name
								: null}{' '}
						</h1>
					)}
				</div>

				{gameUser.permissions?.hasOwnProperty(
					'view_designer_administration'
				) &&
				((typeContextSpace === 'World' &&
					(gameUser.permissions?.hasOwnProperty(
						'view_world_scene_decoration'
					) ||
						gameUser.permissions?.hasOwnProperty(
							'view_world_door'
						))) ||
					(typeContextSpace === 'Subworld' &&
						(gameUser.permissions?.hasOwnProperty(
							'view_sub_world_scene_decoration'
						) ||
							gameUser.permissions?.hasOwnProperty(
								'view_sub_world_door'
							) ||
							gameUser.permissions?.hasOwnProperty(
								'view_storyteller'
							) ||
							gameUser.permissions?.hasOwnProperty(
								'view_sub_world_scene_npc_appearance'
							)))) ? (
					<>
						<div className='objectsAdmin__containerGen__btns'>
							{(typeContextSpace === 'World' &&
								gameUser.permissions?.hasOwnProperty(
									'view_world_scene_decoration'
								)) ||
							(typeContextSpace === 'Subworld' &&
								gameUser.permissions?.hasOwnProperty(
									'view_sub_world_scene_decoration'
								)) ? (
								<button
									className={
										statusDecorations
											? 'editorBtnActionAddActive'
											: 'editorBtnActionAdd'
									}
									onClick={() =>
										clickItem(setStatusDecorations)
									}>
									<p>Decoraciones</p>
								</button>
							) : null}
							{(typeContextSpace === 'World' &&
								gameUser.permissions?.hasOwnProperty(
									'view_world_door'
								)) ||
							(typeContextSpace === 'Subworld' &&
								gameUser.permissions?.hasOwnProperty(
									'view_sub_world_door'
								)) ? (
								<button
									className={
										statusDoors
											? 'editorBtnActionAddActive'
											: 'editorBtnActionAdd'
									}
									onClick={() => clickItem(setStatusDoors)}>
									<p>Puertas</p>
								</button>
							) : null}
							{typeContextSpace === 'Subworld' ? (
								<>
									{gameUser.permissions?.hasOwnProperty(
										'view_storyteller'
									) ? (
										<button
											className={
												statusStoryTeller
													? 'editorBtnActionAddActive'
													: 'editorBtnActionAdd'
											}
											onClick={() =>
												clickItem(setStatusStoryTeller)
											}>
											<p>Narrador</p>
										</button>
									) : null}
									{gameUser.permissions?.hasOwnProperty(
										'view_sub_world_scene_npc_appearance'
									) ? (
										<button
											className={
												statusNpc
													? 'editorBtnActionAddActive'
													: 'editorBtnActionAdd'
											}
											onClick={() =>
												clickItem(setStatusNpc)
											}>
											<p>Npcs</p>
										</button>
									) : null}
								</>
							) : null}
						</div>

						{statusDecorations ? (
							(typeContextSpace === 'World' &&
								gameUser.permissions?.hasOwnProperty(
									'view_world_scene_decoration'
								)) ||
							(typeContextSpace === 'Subworld' &&
								gameUser.permissions?.hasOwnProperty(
									'view_sub_world_scene_decoration'
								)) ? (
								<ListDecorationsScene idScene={idScene} />
							) : null
						) : null}

						{statusDoors ? (
							(typeContextSpace === 'World' &&
								gameUser.permissions?.hasOwnProperty(
									'view_world_door'
								)) ||
							(typeContextSpace === 'Subworld' &&
								gameUser.permissions?.hasOwnProperty(
									'view_sub_world_door'
								)) ? (
								<ListDoorScene
									idScene={idScene}></ListDoorScene>
							) : null
						) : null}

						{statusStoryTeller ? (
							typeContextSpace === 'Subworld' &&
							gameUser.permissions?.hasOwnProperty(
								'view_storyteller'
							) ? (
								<ListStoryTeller
									idScene={idScene}></ListStoryTeller>
							) : null
						) : null}

						{statusNpc ? (
							typeContextSpace === 'Subworld' &&
							gameUser.permissions?.hasOwnProperty(
								'view_sub_world_scene_npc_appearance'
							) ? (
								<NpcAppearanceScene
									idScene={idScene}></NpcAppearanceScene>
							) : null
						) : null}
					</>
				) : (
					<ErrorPerms />
				)}
			</div>
		</div>
	)
}
