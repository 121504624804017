import { createContext, useContext, useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'

const SceneConfigContext = createContext(null)

const useSceneConfig = () => {
	return useContext(SceneConfigContext)
}

const SceneConfigProvider = ({ children, loadingIsVisible }) => {
	const { t } = useTranslation()
	const [soundData, setSoundData] = useState([
		{
			name: t("pages.game.components.configuration_panel.components_pages.sound.music"),
			volume: isNaN(Number(localStorage.getItem('MUSIC_IS_ACTIVE')))
				? '0'
				: localStorage.getItem('MUSIC_IS_ACTIVE'),
		},
		{
			name: t("pages.game.components.configuration_panel.components_pages.sound.sound_effects"),
			volume: isNaN(Number(localStorage.getItem('EFFECTS_IS_ACTIVE')))
				? '0'
				: localStorage.getItem('EFFECTS_IS_ACTIVE'),
		},
		{
			name: t("pages.game.components.configuration_panel.components_pages.sound.voices"),
			volume: isNaN(Number(localStorage.getItem('VOICES_IS_ACTIVE')))
				? '0'
				: localStorage.getItem('VOICES_IS_ACTIVE'),
		},
	])
	useEffect(() => {
		localStorage.setItem('MUSIC_IS_ACTIVE', soundData[0].volume)
		localStorage.setItem('EFFECTS_IS_ACTIVE', soundData[1].volume)
		localStorage.setItem('VOICES_IS_ACTIVE', soundData[2].volume)
	}, [soundData])

	const soundPointers = {
		music: soundData[0],
		effects: soundData[1],
		voices: soundData[2],
	}

	return (
		<SceneConfigContext.Provider
			value={{
				soundData,
				setSoundData,
				soundPointers,
				loadingIsVisible,
			}}>
			{children}
		</SceneConfigContext.Provider>
	)
}

export default SceneConfigProvider
export { useSceneConfig }
