import { useContext, useEffect, useState } from 'react'
import '../../../../styles/AdminDesign.scss'
import ModalContext from '../../../../context/editor/ModalContext'
import { FormLevelLanguage } from './FormLevelLanguage'
import { ContentLevelLanguage } from './ContentLevelLanguage'
import { SearchCamp } from '../../searchAndPagination/SearchCamp'
import { Pagination } from '../../searchAndPagination/Pagination'
import { ItemButtonLevelLanguage } from './ItemButtonLevelLanguage'
import { ObjectLevelLanguageContext } from './ObjectLevelLanguage'
import { StoreContent } from '../Store/StoreContent'
import { LoadingIcon } from '../../LoadingIcon'
import {
	FaBahai,
	FaGamepad,
	FaRegStar,
	FaStore,
	FaUserFriends,
	FaVolumeUp,
} from 'react-icons/fa'
import { ListStars } from '../Stars/ListStars'
import { ListSounds } from '../Sounds/ListSounds'
import { ManageStorageLevelLanguage } from '../../../../utils/manage_local_storage'
import { AnimationsContent } from '../Animations/AnimationsContent'
import { NpcsContent } from '../NPC/NpcsContent'
import { ChallengeContent } from '../ChallengeAndGameTabs/ChallengeContent'
import { useAuthProvider } from '../../../../context/AuthProvider/AuthProvider'
import { API_MANAGE_LEVELS_LANGUAGES } from '../../../../utils/constantsAdmin'
import { useTranslation } from 'react-i18next'

export const ListLevelsLanguage = () => {
	// AUTH PERMISSION
	const { gameUser, axiosSupreme } = useAuthProvider()

	// LOCAL STORAGE
	const { getLevelLanguage, removeLevelLanguage } =
		ManageStorageLevelLanguage()

	// CONTEXT OBJECT LANGUAGE
	const [openAnimations, setOpenAnimations] = useState(false)
	const [openLevels, setOpenLevels] = useState(false)
	const [openStore, setOpenStore] = useState(false)
	const [openStar, setOpenStar] = useState(false)
	const [openSound, setOpenSound] = useState(false)
	const [openNpcs, setOpenNpcs] = useState(false)
	const [openChallenges, setOpenChallenges] = useState(false)
	const { objectLevelLanguage, setObjectLevelLanguage } = useContext(
		ObjectLevelLanguageContext
	)

	const openContentAdmin = (setFunction) => {
		setObjectLevelLanguage(undefined)
		setOpenAnimations(false)
		setOpenLevels(false)
		setOpenStore(false)
		setOpenStar(false)
		setOpenSound(false)
		setOpenChallenges(false)
		setOpenNpcs(false)
		setFunction(true)
		if (
			setFunction === setOpenAnimations ||
			setFunction === setOpenStar ||
			setFunction === setOpenSound ||
			setFunction === setOpenNpcs ||
			setFunction === setOpenChallenges ||
			setFunction === setOpenStore
		) {
			removeLevelLanguage()
		}
	}

	const { t } = useTranslation()

	// REFRESH LEVELS LANGUAGES
	const [refreshLevels, setRefreshLevels] = useState(false)

	// VALUES SEARCH
	const [search, setSearch] = useState('')

	// MODAL
	const { openModal, setTitleModal, setContentModal } =
		useContext(ModalContext)
	const OpenAddLevelModal = () => {
		setTitleModal(t("pages.game.components.list_levels.add_level"))
		setContentModal(
			<FormLevelLanguage setRefreshLevels={setRefreshLevels} />
		)
		openModal()
	}

	// DATA
	const [data, setData] = useState(undefined)

	// GET ALL LEVELS LANGUAGES
	useEffect(() => {
		getData()
	}, [refreshLevels])

	const getData = async () => {
		try {
			const result_data = await axiosSupreme(
				'get',
				API_MANAGE_LEVELS_LANGUAGES
			)
			setData(result_data)
			setHistory()
		} catch (e) {
			console.log('Error get Data: ', e)
		}
	}

	// SET LEVEL HISTORY
	const setHistory = async () => {
		if (getLevelLanguage()) {
			const result_data = await axiosSupreme(
				'get',
				API_MANAGE_LEVELS_LANGUAGES + getLevelLanguage() + '/',
				undefined
			)
			openContentAdmin(setOpenLevels)
			setObjectLevelLanguage(result_data)
		}
	}

	return (
		<>
			<div className='adminDesign'>
				{/* MENU */}
				<div className='adminDesign__menu'>
					<div className='adminDesign__menu__title'>
						<h2>{t("pages.game.components.list_levels.levels")}</h2>
					</div>
					<div className='adminDesign__menu__links '>
						{gameUser.permissions?.hasOwnProperty(
							'add_level_language'
						) ? (
							<button
								className='editorBtnActionAdd'
								onClick={OpenAddLevelModal}>
								{t("common.add")}
							</button>
						) : null}
						{data !== undefined ? (
							<>
								{data === 'search' ||
									data.results.length > 0 ? (
									<>
										<SearchCamp
											setData={setData}
											setRefreshDataList={
												setRefreshLevels
											}
											url={API_MANAGE_LEVELS_LANGUAGES}
											search={search}
											setSearch={setSearch}
										/>
										{data === 'search' ? (
											<p className='noSelect'>
												{t("pages.game.components.list_levels.not_found_level")}
											</p>
										) : null}
									</>
								) : (
									<p className='noSelect'>
										{t("pages.game.components.list_levels.not_found_level")}
									</p>
								)}
								{data !== 'search' ? (
									<>
										<div className='adminDesign__menu__links__containerButtons'>
											{data.results.map((data_item) => (
												<ItemButtonLevelLanguage
													key={
														data_item.id_level_language
													}
													idLevelLanguage={
														data_item.id_level_language
													}
													nameLevelLanguage={
														data_item.name
													}
													setOpenLevels={
														setOpenLevels
													}
													openContentAdmin={
														openContentAdmin
													}
												/>
											))}
										</div>
										<Pagination
											data={data}
											setData={setData}></Pagination>
									</>
								) : null}
							</>
						) : (
							<LoadingIcon />
						)}
					</div>
					<hr />
					<div className='adminDesign__menu__btnStorebox'>
						{gameUser.permissions?.hasOwnProperty(
							'view_animation'
						) ? (
							<button
								className={
									openAnimations === true
										? 'adminDesign__menu__btnStorebox__btnItemBankActive'
										: 'adminDesign__menu__btnStorebox__btnItemBank'
								}
								onClick={() =>
									openContentAdmin(setOpenAnimations)
								}>
								{' '}
								<p className='pIcons'>
									<FaBahai />{' '}
								</p>{' '}
								{t("pages.game.components.list_levels.animations")}
							</button>
						) : null}
						{gameUser.permissions?.hasOwnProperty('view_star') ? (
							<button
								className={
									openStar === true
										? 'adminDesign__menu__btnStorebox__btnItemBankActive'
										: 'adminDesign__menu__btnStorebox__btnItemBank'
								}
								onClick={() => openContentAdmin(setOpenStar)}>
								{' '}
								<p className='pIcons'>
									<FaRegStar />{' '}
								</p>{' '}
								{t("pages.game.components.list_levels.stars")}
							</button>
						) : null}
						{gameUser.permissions?.hasOwnProperty('view_sound') ? (
							<button
								className={
									openSound === true
										? 'adminDesign__menu__btnStorebox__btnItemBankActive'
										: 'adminDesign__menu__btnStorebox__btnItemBank'
								}
								onClick={() => openContentAdmin(setOpenSound)}>
								{' '}
								<p className='pIcons'>
									<FaVolumeUp />{' '}
								</p>{' '}
								{t("pages.game.components.list_levels.sounds")}
							</button>
						) : null}
						{gameUser.permissions?.hasOwnProperty(
							'view_npc_outfit'
						) ||
							gameUser.permissions?.hasOwnProperty('view_npc_pose') ||
							gameUser.permissions?.hasOwnProperty(
								'view_npc_state'
							) ||
							gameUser.permissions?.hasOwnProperty('view_npc') ? (
							<button
								className={
									openNpcs === true
										? 'adminDesign__menu__btnStorebox__btnItemBankActive'
										: 'adminDesign__menu__btnStorebox__btnItemBank'
								}
								onClick={() => openContentAdmin(setOpenNpcs)}>
								{' '}
								<p className='pIcons'>
									<FaUserFriends />{' '}
								</p>{' '}
								{t("pages.game.components.list_levels.npcs")}
							</button>
						) : null}
						{gameUser.permissions?.hasOwnProperty(
							'view_challenge'
						) ||
							gameUser.permissions?.hasOwnProperty('view_game') ? (
							<button
								className={
									openChallenges === true
										? 'adminDesign__menu__btnStorebox__btnItemBankActive'
										: 'adminDesign__menu__btnStorebox__btnItemBank'
								}
								onClick={() =>
									openContentAdmin(setOpenChallenges)
								}>
								{' '}
								<p className='pIcons'>
									<FaGamepad />{' '}
								</p>{' '}
								{t("pages.game.components.list_levels.challenge_and_games")}
							</button>
						) : null}
						{gameUser.permissions?.hasOwnProperty(
							'view_item_category'
						) ||
							gameUser.permissions?.hasOwnProperty('view_item') ? (
							<button
								className={
									openStore === true
										? 'adminDesign__menu__btnStorebox__btnItemBankActive'
										: 'adminDesign__menu__btnStorebox__btnItemBank'
								}
								onClick={() => openContentAdmin(setOpenStore)}>
								{' '}
								<p className='pIcons'>
									<FaStore />{' '}
								</p>{' '}
								{t("pages.game.components.list_levels.store")}
							</button>
						) : null}
					</div>
				</div>

				{/* CENTRAL CONTENT */}
				{openAnimations ||
					openStore ||
					openStar ||
					openSound ||
					openNpcs ||
					openChallenges ? (
					<div className='adminDesign__container'>
						{openAnimations ? <AnimationsContent /> : null}
						{openStore ? <StoreContent /> : null}
						{openStar ? <ListStars /> : null}
						{openSound ? <ListSounds /> : null}
						{openNpcs ? <NpcsContent /> : null}
						{openChallenges ? <ChallengeContent /> : null}
					</div>
				) : null}

				{openLevels ? (
					objectLevelLanguage !== undefined ? (
						<div className='adminDesign__container'>
							<ContentLevelLanguage
								key={objectLevelLanguage.id_level_language}
								setRefreshLevels={
									setRefreshLevels
								}></ContentLevelLanguage>
						</div>
					) : null
				) : null}
				{openAnimations === false &&
					openLevels === false &&
					openStore === false &&
					openStar === false &&
					openSound === false &&
					openNpcs === false &&
					openChallenges === false ? (
					<h1 className='adminDesign__initialDescription'>
						{t("pages.game.components.list_levels.select_level_or_item")}
					</h1>
				) : null}
			</div>
		</>
	)
}
