export const compareString = (first_value, second_value) => {
    if (first_value === second_value) {
        return true;
    }else{
        return false;
    }
}

export const validatePassword = (password) => {
    let validate = [];

    if ( (password.length >= 8) === false){
        validate.push('Debe contener al menos 8 caracteres');
    }

    if ( (password.match(/[A-Z]/)) === null){
        validate.push('Debe incluir al menos una mayuscula.');
    } 

    if( (password.match(/[a-z]/)) === null){
        validate.push('Debe incluir al menos una minuscula.');
    }

    if( (password.match(/[0-9]/)) === null){
        validate.push('Debe incluir al menos un número.');
    }

    if( (password.match(/[!@#$%^&*\\(\\)\\_\-+=?><.\\,]/)) === null){
        validate.push('Debe incluir al menos un caracter especial.');
    }

    return validate;
}

export const toggleCollapse = (contextValue, setContextValue, newValue) => {
    contextValue === newValue ? setContextValue(undefined) : setContextValue(newValue)
}

export const toggleCollapseHistory = (contextValue, setContextValue, newValue, setHistory) => {
    if(contextValue === newValue){
        setContextValue(undefined)
        setHistory(null)
    }else{
        setContextValue(newValue)
        setHistory(newValue)
    }
}

