import React from 'react'
import { ListContentMission } from '../ContentMission/ListContentMission'
import { useAuthProvider } from '../../../../context/AuthProvider/AuthProvider'
import { useTranslation } from 'react-i18next'

export const MissionBodyCollapse = ({
	stateObjectMission,
	idSubWorldSceneNpcAppearance,
	idScene,
}) => {
	const { t } = useTranslation()
	// AUTH PERMISSION
	const { gameUser, axiosSupreme } = useAuthProvider()

	return (
		<div className='mission__BodyCollapse collapseStylesBody'>
			<div className='mission__BodyCollapse__Info'>
				<h1>{t("pages.editor.components.mission_body_collapse.description")}</h1>
				<p>{stateObjectMission.description}</p>
			</div>

			<div className='mission__BodyCollapse__Info'>
				<h1>{t("pages.editor.components.mission_body_collapse.challenge")}</h1>
				<p>{stateObjectMission.challenge.name}</p>
			</div>

			{stateObjectMission.challenge.category === 'g' ? (
				<div className='mission__BodyCollapse__Info'>
					<h1>{t("pages.editor.components.mission_body_collapse.game")}</h1>
					<p>{stateObjectMission.game.name}</p>
				</div>
			) : null}

			{stateObjectMission.item !== null ? (
				<div className='mission__BodyCollapse__Info'>
					<h1>{t("pages.editor.components.mission_body_collapse.item")}</h1>
					<p>{stateObjectMission.item.name}</p>
				</div>
			) : null}

			<hr />
			{gameUser.permissions?.hasOwnProperty('view_mission_content') &&
				gameUser.permissions?.hasOwnProperty(
					'view_mission_content_add_ons'
				) ? (
				<ListContentMission
					idObjectMission={stateObjectMission.id_mission}
					idScene={idScene}
					typeMission={stateObjectMission.challenge.category}
					stateObjectMission={stateObjectMission}
				/>
			) : null}
		</div>
	)
}
