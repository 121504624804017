import { useContext, useEffect, useState } from 'react'
import { GET_WORLDS_BY_LEVEL } from '../../../../utils/constantsAdmin'
import ModalContext from '../../../../context/editor/ModalContext'
import { FormWorlds } from './FormWorlds'
import { SearchCamp } from '../../searchAndPagination/SearchCamp'
import WorldComponent from './WorldComponent'
import { LoadingIcon } from '../../LoadingIcon'
import { ManageStorageWorld } from '../../../../utils/manage_local_storage'
import { useAuthProvider } from '../../../../context/AuthProvider/AuthProvider'
import { useTranslation } from 'react-i18next'

export const ListWorlds = ({ objectLevelLanguage }) => {
	const { t } = useTranslation()
	// AUTH PERMISSION
	const { gameUser, axiosSupreme } = useAuthProvider()

	// LOCAL STORAGE
	const { getWorld } = ManageStorageWorld()

	// REFRESH WORLDS
	const [refreshWordls, setRefreshWorlds] = useState(false)

	// DATA
	const [dataWorlds, setDataWorlds] = useState(undefined)

	// GET WORLDS FOR LEVEL LANGUAGE
	useEffect(() => {
		getData()
	}, [refreshWordls])

	const getData = async () => {
		try {
			const result_data = await axiosSupreme(
				'get',
				GET_WORLDS_BY_LEVEL + objectLevelLanguage.id_level_language + '/'
			)
			setDataWorlds(result_data)
			setHistory()
		} catch (e) {
			console.log('Error get Data: ', e)
		}
	}

	// HISTORY WORLD
	const setHistory = () => {
		if (getWorld()) {
			setActiveCollapseWorld(getWorld())
		}
	}

	// VALUES SEARCH
	const [search, setSearch] = useState('')

	// MODAL
	const { openModal, setTitleModal, setContentModal } =
		useContext(ModalContext)
	const OpenAddWorldModal = () => {
		setTitleModal(t("pages.editor.components.list_worlds.add_world"))
		setContentModal(
			<FormWorlds
				idLevelLanguage={objectLevelLanguage.id_level_language}
				setRefreshWorlds={setRefreshWorlds}
			/>
		)
		openModal()
	}

	// ACTIVE COLLAPSE WORLD
	const [activeCollapseWorld, setActiveCollapseWorld] = useState(undefined)

	return (
		<>
			<div className='bodyCollapseComponent__content__btnAdd'>
				<h1>{t("pages.editor.components.list_worlds.worlds")}</h1>
				<div className='boxBtnAdd'>
					{gameUser.permissions?.hasOwnProperty('add_world') ? (
						<button
							className='editorBtnActionAdd'
							onClick={OpenAddWorldModal}>
							{t("common.add")}
						</button>
					) : null}
				</div>
			</div>

			{dataWorlds !== undefined ? (
				<>
					{dataWorlds === 'search' || dataWorlds.length > 0 ? (
						<>
							<SearchCamp
								setData={setDataWorlds}
								setRefreshDataList={setRefreshWorlds}
								url={
									GET_WORLDS_BY_LEVEL +
									objectLevelLanguage.id_level_language +
									'/'
								}
								search={search}
								setSearch={setSearch}
							/>
							{dataWorlds === 'search' ? (
								<p className='noSelect'>
									{t("pages.editor.components.list_worlds.none_world_found")}
								</p>
							) : null}
						</>
					) : (
						<p className='noSelect'>
							{t("pages.editor.components.list_worlds.none_world_assigned")}
						</p>
					)}

					<div className='bodyCollapseComponent__content__containerWorlds'>
						{dataWorlds !== 'search'
							? dataWorlds.map((data_item, index) => (
									<WorldComponent
										key={data_item.id_world}
										idLevelLanguage={
											objectLevelLanguage.id_level_language
										}
										objectWorld={data_item}
										setRefreshWorlds={setRefreshWorlds}
										activeCollapseWorld={
											activeCollapseWorld
										}
										setActiveCollapseWorld={
											setActiveCollapseWorld
										}></WorldComponent>
							  ))
							: null}
					</div>
				</>
			) : (
				<LoadingIcon />
			)}
		</>
	)
}
