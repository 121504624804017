export const initialStateUpdateDataUser = {
	errors: [],
	loading: false,
	editPassword: false,
}

export const UPDATE_DATA_USER_ACTION_TYPE = {
	UPDATE_EDIT_PASSWORD: 'UPDATE_EDIT_PASSWORD',
	CLEAN_AND_SET_ERRORS: 'CLEAN_AND_SET_ERRORS',
	UPDATE_LOADING: 'UPDATE_LOADING',
}

export function updateDataUserReducer(state, action) {
	if (action.type === UPDATE_DATA_USER_ACTION_TYPE.UPDATE_LOADING) {
		const { loading } = action.payload

		return {
			...state,
			loading,
		}
	}

	if (action.type === UPDATE_DATA_USER_ACTION_TYPE.UPDATE_EDIT_PASSWORD) {
		const { editPassword } = action.payload

		return {
			...state,
			editPassword,
		}
	}

	if (action.type === UPDATE_DATA_USER_ACTION_TYPE.CLEAN_AND_SET_ERRORS) {
		const { errorCode, errors } = action.payload

		const newErrors = []

		if (errorCode === 'ERR_BAD_REQUEST') {
			Object.keys(errors).forEach((dataError) => {
				newErrors.push(errors[dataError][0])
			})
		} else {
			newErrors.push(...errors)
		}

		return {
			...state,
			errors: newErrors,
		}
	}

	return state
}
