import { API_LOST_ITEM_CATCHED } from "../../utils/constants"
import { SpriteWithZoneAndCollisions } from "./SpriteWithZoneAndCollisions"
import { StringTag } from "./StringTag"

export class LostItem extends SpriteWithZoneAndCollisions {
	#letter
	get letter() {
		return this.#letter
	}
	#lockMessage
	set lockMessage(msg) {
		this.#lockMessage = msg
	}
	#mission
	get mission() {
		return this.#mission
	}

	constructor(id, name, pos, spriteSheets, mission) {
		super(id, name, pos, spriteSheets, 'lost item')
		this.#mission = mission
	}
	create(scene, bodyType) {
		super.create(
			scene,
			bodyType,
			{
				x: 70,
				y: 70,
			},
			'LOST ITEM ZONE',
			true
		)

		// ----- LOCK LETTER
		this.#letter = new StringTag(
			{ x: this.pos.x, y: this.pos.y - this.sprite.height / 2 - 10 },
			{ x: 0.5, y: 1 },
			this.#mission.message,
			'map__lost__item__letter',
			true
		)
		this.#letter.create(scene)
		this.#letter.enableVisibility(false)
	}
	async onCatched(
		setReloadScene,
		useUpdateProfileContext,
		axiosSupreme,
		setLostItemsThereIsAnUnseenInteraction
	) {
		try {
			const itemCatchedRequest = await axiosSupreme(
				'patch',
				API_LOST_ITEM_CATCHED + this.#mission.id + '/',
				{
					object_catched: true,
				}
			)
			setLostItemsThereIsAnUnseenInteraction(true)
			setReloadScene((rs) => !rs)
			this.#mission.completed = true
		} catch (error) {
			console.error(error)
			this.onCatched(
				setReloadScene,
				useUpdateProfileContext,
				axiosSupreme,
				setLostItemsThereIsAnUnseenInteraction
			)
		}
	}
	onStartCollision(
		setReloadScene,
		useUpdateProfileContext,
		axiosSupreme,
		setLostItemsThereIsAnUnseenInteraction,
		soundAction
	) {
		soundAction('effect', 'lostObjectDiscovered', 'pause')
		this.#letter.enableVisibility(true)
		this.onCatched(
			setReloadScene,
			useUpdateProfileContext,
			axiosSupreme,
			setLostItemsThereIsAnUnseenInteraction
		)
	}
	onEndCollision(scene) {
		// ----- ANIMATE TO TRANSPARENT AND THEN DESTROY THIS SHIT BRO!!!
		scene.tweens.add({
			targets: this.sprite,
			alpha: 0,
			duration: 1200,
			ease: 'power4',
			repeat: 0,
			onComplete: () => {
				this.#letter.container.destroy()
				this.sprite.destroy()
				this.zone.sprite.position.x = 100000
			},
		})
		scene.tweens.add({
			targets: this.letter.container,
			alpha: 0,
			duration: 1200,
			ease: 'power4',
			repeat: 0,
		})
	}
	destroyStart() {
		this.#letter.container.setPosition(10000, 10000)
	}
	destroyEnd() {
		this.sprite.position.x = 10000
	}
}
