import React, { useEffect, useState } from 'react'
import Playground from './Playground'
import '../../styles/Activity.scss'

import { useActivity, useGameStatus } from '../../provider/ActivityProvider'

import Lesson from './Lesson'

/**
 * Depending on type Game or Class renders a Playground or the Board.
 * @param {Object} props
 * @returns {JSX}
 */
function Activity({
	visual,
	playgroundData,
	onComplete,
	onClose,
	randomiseWords,
}) {
	// Direct comunication with parent ActivityProvider
	const { isGame } = useActivity()
	const [, setGameStatus] = useGameStatus()

	// restartGameKey a random number to destroy and create a new Component (playground)
	const [restartGameKey, setRestartGameKey] = useState(0)
	const [isNewTry, setIsNewTry] = useState(false)
	/**
	 * destroys the playground component, randomises the NPC secuence words and changes
	 * the game status of the game.
	 */
	const onTryAgain = () => {
		setGameStatus('inProgress')
		const uuid = Math.random() + Math.random()
		randomiseWords()
		setRestartGameKey(uuid)
		setIsNewTry(true)
	}

	const actualComponent = isGame ? (
		<Playground
			key={restartGameKey}
			data={playgroundData}
			onClose={onClose}
			onTryAgain={onTryAgain}
			onComplete={onComplete}
			isNewTry={isNewTry}
		/>
	) : (
		<Lesson onClose={onClose} onComplete={onComplete} />
	)

	/**
	 * @type {String} currentVisual the className of this dialogue component
	 */
	const currentVisual =
		visual === 'show'
			? 'activity activity__animation__show'
			: 'activity activity__animation__hide'

	useEffect(() => {
		if (currentVisual === 'activity activity__animation__show') {
			window.scene.scene.pause()
		}

		return () => {
			window.scene.scene.resume()
		}
	}, [currentVisual])

	return (
		<div className={currentVisual} id='activity'>
			{/* <BlackBackgroundCenter></BlackBackgroundCenter> */}
			{actualComponent}
		</div>
	)
}

export default Activity
