import '../../../styles/shared/Icon.scss'

const Icon = ({ className = '', src, alt = '', size = 32, style = {} }) => {
	return (
		<div
			className={'icon ' + className}
			style={{
				width: size,
				height: size,
				maxHeight: '100%',
				maxWidth: '100%',
				...style,
			}}>
			<img
				src={src}
				alt={alt}
				style={{
					display: 'block',
					height: '100%',
					width: '100%',
					aspectRatio: '1/1',
				}} />
		</div>
	)
}

export default Icon
