
export function ManageStorageAdminDesigner () {
    const settypeContextSpace = (value) => {
        localStorage.setItem( 'TYPE_CONTEXT_SPACE', value )
    }

    const gettypeContextSpace = (value) => {
        return localStorage.getItem('TYPE_CONTEXT_SPACE')
    }

    const removetypeContextSpace = (value) => {
        localStorage.removeItem('TYPE_CONTEXT_SPACE')
    }

    return {
        settypeContextSpace,
        gettypeContextSpace,
        removetypeContextSpace,
    }
}